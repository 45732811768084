import { createTheme, ThemeOptions } from '@mui/material'
import { ExtendedTypographyOptions } from 'types/typographyClasses'

export const theme = createTheme({
  breakpoints: {
    values: {
      /**
       * 0 - 599: mobile
       * 600 - 959: tablet portrait
       * 960 - 1279: tablet landscape and laptop
       * 1280 - up: desktop
       */
      xl: 1440,
      lg: 1280,
      md: 960,
      xsm: 770,
      sm: 600,
      xs: 0
    }
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiInputBase: {
      defaultProps: {
        color: 'info'
      }
    },
    MuiTextField: {
      defaultProps: {
        color: 'info'
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        row: {
          '&.Mui-selected': {
            backgroundColor: 'rgb(229, 247 ,228)',
            '&:hover': {
              backgroundColor: 'rgb(211, 244 ,210)'
            }
          },
          '&.error-row.Mui-selected': {
            backgroundColor: 'rgba(200, 0, 0, 0.2)',
            '&:hover': {
              backgroundColor: 'rgba(200, 0, 0, 0.3)'
            }
          },
          '&.error-row': {
            '&:hover': {
              backgroundColor: 'rgba(200, 0, 0, 0.1)'
            }
          }
        }
      }
    }
  },
  palette: {
    alert: {
      100: '#66471C',
      200: '#B27C30',
      300: '#F1A841',
      400: '#FFCC99',
      500: '#FFFCF7',
      main: '#FFCC99'
    },
    error: {
      100: '#4C000C',
      200: '#990017',
      300: '#CC2942',
      400: '#FF8080',
      500: '#FFFAFB',
      main: '#4C000C'
    },
    info: {
      100: '#294266',
      200: '#366AB2',
      300: '#4C98FF',
      400: '#B2D3FF',
      500: '#F7FBFF',
      main: '#3F403F'
    },
    neutral: {
      50: '#1E1E1E',
      100: '#231F20',
      200: '#3F403F',
      300: '#646664',
      400: '#777877',
      450: '#828282',
      500: '#A3A6A2',
      600: '#BEC0BE',
      700: '#E3E6E3',
      800: '#F2F5F2',
      900: '#F7FAF7',
      A100: '#FCFCFC',
      A200: '#FFFFFF',
      main: '#231F20'
    },
    primary: {
      100: '#3D892D',
      200: '#56BB4D',
      300: '#68E066',
      400: '#74FAA4',
      500: '#C3F9CC',
      600: '#E6FDEA',
      700: '#01F7C2',
      800: '#23ebc0',
      main: '#01F7C2'
    },
    secondary: {
      100: '#459CA0',
      200: '#59C6D3',
      300: '#65DFE9',
      400: '#6EF1F3',
      500: '#BCF4F5',
      550: '#00EC89',
      600: '#E4FBFC',
      main: '#459CA0'
    },
    success: {
      100: '#0F4C23',
      200: '#1A803C',
      300: '#30BF60',
      400: '#A9F2C1',
      500: '#F2FFF6',
      main: '#0F4C23'
    }
  },

  typography: {
    display: {
      // ['@media (min-width: 1280px)']: {
      //   fontSize: '54px'
      // },
      color: '#231F20',
      fontSize: '32px',
      fontWeight: 600,
      lineHeight: '120%',
      transition: 'font-size 200ms ease-in'
    },
    fontFamily: ['Inter', 'Roboto', 'sans-serif'].join(','),
    hero: {
      color: '#231F20',
      fontSize: '80px',
      fontWeight: 600,
      lineHeight: '120%'
    },
    lgBold: {
      color: '#231F20',
      fontSize: '24px',
      fontWeight: 600,
      lineHeight: '150%'
    },
    lgLight: {
      ['@media (min-width: 1280px)']: {
        fontSize: '24px'
      },
      color: '#231F20',
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: '150%',
      transition: 'font-size 200ms ease-in'
    },
    mdBold: {
      color: '#231F20',
      fontSize: '15px',
      fontWeight: 600,
      lineHeight: '140%'
    },
    mdLight: {
      color: '#231F20',
      fontSize: '15px',
      fontWeight: 400,
      lineHeight: '140%'
    },
    menu: {
      color: '#777877',
      fontSize: '15px',
      fontWeight: 400,
      lineHeight: '140%'
    },
    smBold: {
      color: '#231F20',
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '120%'
    },
    smLight: {
      color: '#231F20',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '120%',
      textTransform: 'none'
    },
    xl: {
      color: '#231F20',
      fontSize: '54px',
      fontWeight: 600,
      lineHeight: '120%'
    },
    xsBold: {
      color: '#231F20',
      fontSize: '10px',
      fontWeight: 600,
      lineHeight: '120%'
    },

    xsLight: {
      color: '#231F20',
      fontSize: '10px',
      fontWeight: 400,
      lineHeight: '120%'
    }
  } as ExtendedTypographyOptions
} as ThemeOptions)
