import React, { forwardRef, useEffect, useState } from 'react'
import { FieldValues, Path } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { NumericFormat } from 'react-number-format'

import { Button, Icon, Textfield } from '@clientbase/clientbase-library'
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'

import { DatePicker } from 'components/DatePicker'
import NumberFormatCustom from 'components/PorcentageInput/PorcentageInput'

import { formatMoney } from 'utils'
import { stringToDate } from 'utils/FormatDate'

import { theme } from 'styles/theme'

import { DiscountField, DiscountsParams } from './Discounts.interface'
import * as S from './Discounts.styles'
import {
  extractUuid,
  filterDiscountsToDate,
  formatDate,
  handleAddedError,
  handleRemoveError
} from './Discounts.utils'

const Discounts = <T extends FieldValues>({
  control,
  watch,
  setAddFieldDiscount,
  addFieldDiscount
}: DiscountsParams<T>) => {
  const items = watch(`items` as Path<T>)
  const discountPolicy = watch('discountPolicy' as Path<T>) as boolean
  const amount = items
    ?.reduce((acc: number, value: any) => acc + value.total, 0)
    .toString()
  const dueDate = watch('dueDate' as Path<T>) as string

  const isValidateAmount = (value: string, isPercent?: boolean) => {
    if (isPercent) {
      if (!value) {
        return 'Campo obrigatório.'
      }
      if (Number(value) === 0) {
        return 'A porcentagem do desconto deve ser maior que 0%'
      }
      if (Number(value) > 90) {
        return 'A porcentagem do desconto deve ser menor ou igual a 90% do valor.'
      }
    } else {
      if (Number(value) > Number(amount)) {
        return 'O valor do desconto deve ser menor que o da fatura.'
      } else if (Math.sign(Number(value)) !== 1) {
        return 'Campo obrigatório.'
      } else if (Number(value) > Number(amount) * 0.9) {
        return 'O valor do desconto deve ser menor ou igual a 90% do valor.'
      }
    }
    return undefined
  }

  const isValidateDate = (value: Date) => {
    let dueDateLocal = dueDate as string | Date | undefined
    if (typeof dueDateLocal === 'string') {
      dueDateLocal = stringToDate(dueDate as string)
    }
    const billingDueDate = moment(dueDateLocal as Date, 'DD/MM/YYYY')
    const currentDate = moment(value, 'DD/MM/YYYY')
    const todayDate = moment(new Date(), 'DD/MM/YYYY')

    if (
      currentDate.format('DD/MM/YYYY').toString() !==
      billingDueDate.format('DD/MM/YYYY').toString()
    ) {
      if (currentDate.isAfter(billingDueDate))
        return 'A data deve ser igual ou anterior a data de vencimento.'
    } else if (
      currentDate.format('DD/MM/YYYY').toString() !==
      todayDate.format('DD/MM/YYYY').toString()
    ) {
      if (currentDate.isBefore(todayDate))
        return 'A data deve ser maior ou igual a data atual.'
    } else if (!currentDate.isValid()) {
      return 'Deve ser uma data válida.'
    }
    return undefined
  }

  const deleteDiscount = (uuid: string, addFieldDiscount: DiscountField[]) => {
    setAddFieldDiscount((current) => current.filter((obj) => obj.uuid !== uuid))

    handleUpdateErrors(
      addFieldDiscount
        .filter((obj) => obj.uuid !== uuid)
        .filter((obj) => obj.date !== undefined)
    )
  }
  useEffect(() => {
    setAddFieldDiscount((current) =>
      current.map((obj) => {
        const response = isValidateDate(obj.date as Date)
        if (response && !!obj.date) {
          return {
            ...obj,
            errors: {
              ...obj.errors,
              date: { message: response, isError: true }
            }
          }
        }
        return {
          ...obj,
          errors: {
            ...obj.errors,
            date: obj.errors?.date?.isError ? { ...obj.errors.date } : undefined
          }
        }
      })
    )
  }, [dueDate])

  useEffect(() => {
    setAddFieldDiscount((current) =>
      current.map((obj) => {
        const response = isValidateAmount(obj.amount as string)
        if (response && !!obj.amount) {
          return {
            ...obj,
            errors: {
              ...obj.errors,
              amount: { message: response, isError: true }
            }
          }
        }
        return { ...obj, errors: undefined }
      })
    )
  }, [amount])

  const handleUpdateErrors = (discounts: DiscountField[]) => {
    const uuidsToRemoveErrors: string[] = []

    if (discounts.length > 1) {
      discounts.forEach(({ date, uuid }) => {
        const dateFind = date as Date
        const uuidFind = uuid as string

        discounts.forEach((localDiscount) => {
          const uuidLocal = localDiscount.uuid as string
          if (
            formatDate(dateFind) !== formatDate(localDiscount.date as Date) &&
            uuid !== localDiscount.uuid
          ) {
            if (!uuidsToRemoveErrors.includes(uuid as string)) {
              uuidsToRemoveErrors.push(uuidFind)
            }
            if (!uuidsToRemoveErrors.includes(localDiscount.uuid as string)) {
              uuidsToRemoveErrors.push(uuidLocal)
            }
          }
        })
      })
    } else if (discounts.length === 1) {
      uuidsToRemoveErrors.push(discounts[0].uuid as string)
    }

    uuidsToRemoveErrors.length > 0 &&
      setAddFieldDiscount((current) => {
        return current.map((obj) => {
          const uuid = obj.uuid as string
          const response = isValidateDate(obj.date as Date)
          if (uuidsToRemoveErrors.includes(uuid) && !response) {
            return {
              ...obj,
              errors: undefined
            }
          }
          return { ...obj }
        })
      })
  }

  const handleChangeInputDate = (
    valueOld: Date,
    valueNew: Date,
    uuid: string
  ) => {
    const filterUndefinedDate = addFieldDiscount.filter(
      ({ date }) => date !== undefined
    )
    const uuidsToRemoveErrors: string[] = []
    const uuidsToAddErrors: string[] = []

    const fieldsToRemoveErrors = filterDiscountsToDate(
      filterUndefinedDate,
      valueOld
    )

    !!fieldsToRemoveErrors &&
      extractUuid(fieldsToRemoveErrors, uuidsToRemoveErrors)

    const fieldsToAddErrors = filterDiscountsToDate(
      filterUndefinedDate,
      valueNew
    )
    const addErrorOnChange =
      fieldsToAddErrors.filter(
        (obj) =>
          moment(obj.date).format('DD/MM/YYYY') ===
          moment(valueNew).format('DD/MM/YYYY')
      ).length > 0
        ? true
        : false

    addErrorOnChange && uuidsToAddErrors.push(uuid)
    !!fieldsToAddErrors && extractUuid(fieldsToAddErrors, uuidsToAddErrors)

    const findCurrentUuid =
      uuidsToRemoveErrors.find((uuidLocal) => uuidLocal === uuid) || []
    if (uuidsToRemoveErrors.length === 2 && findCurrentUuid?.length > 1) {
      setAddFieldDiscount((current) => {
        return current.map((obj) => {
          if (isValidateDate(obj.date as Date)) {
            return { ...obj }
          }
          return { ...handleRemoveError(obj, 'date') }
        })
      })
    }
    if (uuidsToAddErrors.length > 0) {
      setAddFieldDiscount((current) => {
        return current.map((obj) => {
          if (uuidsToAddErrors.find((uuid) => uuid === obj.uuid)) {
            const response = isValidateDate(obj.date as Date)
            return {
              ...handleAddedError(
                obj,
                'date',
                response
                  ? response
                  : 'O dia do desconto deve ser diferente do dia já selecionado.'
              )
            }
          }
          return { ...obj }
        })
      })
    }
    setAddFieldDiscount((current) => {
      return current.map((obj) => {
        if (obj.uuid === uuid) {
          const messageError = isValidateDate(valueNew)
          return {
            ...obj,
            date: valueNew,
            errors: messageError
              ? {
                  ...obj.errors,
                  date: { isError: true, message: messageError }
                }
              : addErrorOnChange
              ? { ...obj.errors }
              : { ...obj.errors, date: undefined }
          }
        }
        return { ...obj }
      })
    })
  }
  const localAmount = items
    ?.reduce((acc: number, value: any) => acc + value.total, 0)
    .toString()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [discountObj, _setDiscountObj] = useState<
    { index: number; value: string }[]
  >([])
  const handleSetDiscountObj = (newValue: { index: number; value: string }) => {
    discountObj[newValue.index] = newValue
  }
  const calculateDiscount = (
    amount: string,
    discount: string,
    isPercent: boolean | undefined
  ) => {
    const amountInCents = Number(amount)
    if (!isPercent) {
      const discountInCents = Number(discount?.toString())
      const finalAmount = amountInCents - discountInCents
      return 'Valor com desconto: ' + formatMoney(finalAmount)
    } else {
      const discountPercent = Number(discount?.toString())
      const discountValue = amountInCents * (discountPercent / 100)
      const finalAmount = amountInCents - discountValue
      return 'Valor com desconto: ' + formatMoney(finalAmount)
    }
  }

  return (
    <S.Grid container={true} columnSpacing={2}>
      <S.Col item={true} xs={12}>
        {addFieldDiscount.map(
          ({ amount, date, errors, uuid, type = '1' }, index) => {
            return (
              <React.Fragment key={index}>
                <S.Col item={true} xs={12}>
                  <S.Grid container={true} spacing={1}>
                    <S.Col spacing={0} item xs={12} sm={12} lg={2.2}>
                      <Controller
                        name={`isPercent-${index}` as Path<T>}
                        control={control}
                        render={() => (
                          <>
                            <Typography variant="mdLight" id="discount">
                              {type === '1' ? 'Valor ' : 'Porcentagem '}
                              do desconto?
                            </Typography>
                            <RadioGroup
                              sx={{
                                justifyContent: 'start',
                                alignContent: 'end'
                              }}
                              row
                              value={Number(type)}
                              onChange={(event) => {
                                setAddFieldDiscount((current) => {
                                  return current.map((obj) => {
                                    if (!localAmount || !obj.amount) {
                                      if (obj.uuid === uuid) {
                                        return {
                                          ...obj,
                                          type: event.target.value
                                        }
                                      }
                                      return { ...obj }
                                    }
                                    if (
                                      obj.uuid === uuid &&
                                      event.target.value
                                    ) {
                                      const amount =
                                        event.target.value === '0'
                                          ? (Number(obj?.amount as string) /
                                              Number(localAmount)) *
                                            100
                                          : (
                                              (Number(obj.amount) / 100) *
                                              Number(localAmount)
                                            ).toFixed(2)

                                      return {
                                        ...obj,
                                        amount: amount.toString(),
                                        type: event.target.value
                                      }
                                    }
                                    return { ...obj }
                                  })
                                })
                              }}
                            >
                              <FormControlLabel
                                value={1}
                                control={<Radio />}
                                label="Valor"
                              />
                              <FormControlLabel
                                value={0}
                                control={<Radio />}
                                label="Porcentagem"
                              />
                            </RadioGroup>
                          </>
                        )}
                      />
                    </S.Col>
                    <S.Col item={true} xs={12} sm={12} lg={4.5}>
                      <Controller
                        control={control}
                        name={'discountValueField' as Path<T>}
                        render={({ field: { ref } }) => (
                          <Textfield
                            sx={{
                              [theme.breakpoints.up(1300)]: {
                                marginTop: '25px'
                              }
                            }}
                            inputRef={ref}
                            placeholder={type === '1' ? 'R$' : '%'}
                            disabled={!discountPolicy}
                            helperText={
                              discountPolicy ? errors?.amount?.message : false
                            }
                            InputProps={{
                              inputComponent:
                                type === '0'
                                  ? (NumberFormatCustom as any)
                                  : forwardRef(function CurrencyFormatCustom(
                                      props,
                                      ref
                                    ) {
                                      const { onChange, ...other } = props

                                      return (
                                        <NumericFormat
                                          {...other}
                                          getInputRef={ref}
                                          onValueChange={(values) => {
                                            onChange({
                                              target: {
                                                name: props.name,
                                                value: values.value
                                              }
                                            })
                                          }}
                                          thousandSeparator="."
                                          decimalSeparator=","
                                          decimalScale={2}
                                          fixedDecimalScale
                                          prefix="R$ "
                                        />
                                      )
                                    })
                            }}
                            error={
                              discountPolicy ? !!errors?.amount?.message : false
                            }
                            name={`discounts-amount-${index}`}
                            hiddenLabel
                            mask={'string'}
                            value={amount}
                            onChange={(event) => {
                              handleSetDiscountObj({
                                index,
                                value: event.target.value
                              })
                              setAddFieldDiscount((current) => {
                                return current.map((obj) => {
                                  if (obj.uuid === uuid) {
                                    const response = isValidateAmount(
                                      event.target.value,
                                      obj.type === '0'
                                    )
                                    return {
                                      ...obj,
                                      amount: event.target.value,
                                      errors: {
                                        ...obj.errors,
                                        amount: {
                                          message: response,
                                          isError: response ? true : undefined
                                        }
                                      }
                                    }
                                  }
                                  return { ...obj }
                                })
                              })
                            }}
                          />
                        )}
                      />
                      <Box
                        display={'flex'}
                        width={'100%'}
                        justifyContent={'end'}
                      >
                        <Typography
                          variant="mdLight"
                          color={theme.palette.neutral[400]}
                          id="discounts"
                        >
                          {localAmount && addFieldDiscount[index].amount
                            ? calculateDiscount(
                                localAmount ?? '',
                                addFieldDiscount[index].amount ?? '',
                                type === '0'
                              )
                            : ''}
                        </Typography>
                      </Box>
                    </S.Col>
                    <S.Col item={true} xs={12} sm={12} lg={4.8}>
                      <Controller
                        control={control}
                        name={'discountLimitDate' as Path<T>}
                        render={({ field: { ref } }) => (
                          <DatePicker
                            inputRef={ref}
                            disabled={!discountPolicy}
                            helperText={
                              discountPolicy ? errors?.date?.message : undefined
                            }
                            error={
                              discountPolicy ? !!errors?.date?.message : false
                            }
                            label="Data limite do desconto:"
                            maxDate={
                              new Date(moment(dueDate || new Date()).format())
                            }
                            minDate={new Date()}
                            onChange={(event) => {
                              const discount = addFieldDiscount.find(
                                (obj) => obj.uuid === uuid
                              )

                              !!discount &&
                                handleChangeInputDate(
                                  discount.date as Date,
                                  event,
                                  uuid as string
                                )
                            }}
                            renderInput={(props: any) => (
                              <Textfield
                                {...props}
                                name={`discounts-date-${index}`}
                              />
                            )}
                            value={date ? date : null}
                          />
                        )}
                      />
                    </S.Col>
                    <S.Col
                      item={true}
                      xs={12}
                      lg={0.5}
                      alignSelf="top"
                      mt={3.5}
                      sx={{ cursor: 'pointer', width: '100%' }}
                    >
                      <Button
                        icon="delete"
                        fullWidth
                        variantButton="gray"
                        onClick={() =>
                          deleteDiscount(uuid || '', addFieldDiscount)
                        }
                      ></Button>
                    </S.Col>
                  </S.Grid>
                </S.Col>
              </React.Fragment>
            )
          }
        )}
      </S.Col>

      {addFieldDiscount.length !== 2 && (
        <Grid item={true} xs={12} mt="12px">
          <Box
            display="flex"
            alignItems="center"
            padding="6px 0"
            justifyContent="center"
            width="100%"
            border="1px dashed #E3E6E3"
            sx={{
              '&:hover': {
                cursor: 'pointer',
                backgroundColor: '#fbfbfb'
              }
            }}
            onClick={() => {
              setAddFieldDiscount((prevState) => [
                ...prevState,
                {
                  uuid: uuidv4(),
                  amount: undefined,
                  type: undefined,
                  date: undefined
                }
              ])
            }}
          >
            <Icon icon="add" /> Adicionar novo desconto
          </Box>
        </Grid>
      )}
    </S.Grid>
  )
}

export default Discounts
