import styled from '@mui/system/styled'

import { theme } from 'styles/theme'

import { ButtonProps } from './'

export type WrapperProps = Omit<ButtonProps, 'children'>

const wrapperModifiers = {
  outlined: {
    background: '#FFFFFF !important',
    border: '1px solid #A3A6A2 !important',
    borderRadius: 8,
    font: 'normal 400 16px Inter, "sans-serif" !important',

    padding: '10px 14px',
    outline: `0px solid ${theme.palette.secondary[200]}30`,
    transition: 'outline 70ms linear',

    '&:hover': {
      outlineWidth: 3
    }
  },
  contained: {
    background: '#231F20 !important',
    color: '#FFFFFF !important',
    borderRadius: 8,
    font: 'normal 700 16px Inter, "sans-serif" !important',

    padding: '10px 14px',
    outline: `0px solid ${theme.palette.secondary[200]}30`,
    transition: 'outline 50ms linear',

    '&:hover': {
      outlineWidth: 3
    }
  },
  text: {
    background: 'transparent !important',
    color: '#231F20 !important',
    width: '100%',
    transition: 'color 100ms linear',

    '&:hover': {
      color: '#000000'
    }
  },
  gray: {
    font: 'normal 400 16px Inter, "sans-serif" !important',
    background: '#F5F7F5 !important',

    outline: `0px solid ${theme.palette.neutral[200]}20`,
    transition: 'outline 70ms linear',
    borderRadius: 4,
    cursor: 'pointer',
    lineHeight: 1.63,
    padding: '10px 14px',

    '& span': {
      color: '#2D2D2D'
    },

    ['&:focus, &:hover']: {
      outlineWidth: 2
    }
  },
  green: {
    background: `${theme.palette.primary.main} !important`,
    color: '#FFFFFF !important',
    borderRadius: 8,
    font: 'normal 700 14px Inter, "sans-serif" !important',

    padding: '11px 14px',
    outline: `0px solid ${theme.palette.secondary[200]}30`,
    transition: 'outline 50ms linear',

    '&:hover': {
      outlineWidth: 3
    }
  }
}

export const Wrapper = styled('button')<WrapperProps>(
  ({ variant, fullWidth }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 0,
    border: 0,
    cursor: 'pointer',
    ...(variant && wrapperModifiers[variant]),
    ...(fullWidth && { width: '100%' })
  })
)
