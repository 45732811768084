import React, { useContext } from 'react'
import { toast } from 'react-toastify'

import { Button, Icon } from '@clientbase/clientbase-library'
import { Box } from '@mui/material'
import copy from 'copy-to-clipboard'
import InvoiceIcon from 'templates/Invoice/components/InvoiceIcon'

import Can from 'components/Can'
import { getStatus } from 'components/Modal/ModalChargeDetail/components/PaymentDetails/PaymentDetails.utils'
import { ModalChargeDetailContext } from 'components/Modal/ModalChargeDetail/context'
import {
  getStatus as getStatusEnum,
  openInvoice,
  sendToWhatsApp
} from 'components/Modal/ModalChargeDetail/ModalChargeDetail.utils'

import { theme } from 'styles/theme'

import { S } from './'

const Actions: React.FC = () => {
  const { invoice, anchorEl, updateAnchorEl, updateModalNfDetails } =
    useContext(ModalChargeDetailContext)

  const handleOpenInvoice = () => {
    openInvoice(invoice?.uuid)
  }

  const handleOpenNfDetails = () => {
    updateModalNfDetails(true)
  }

  function copyLinkInvoice(uuid: string) {
    copy(`https://fatura.clientbase.com.br/${uuid}`)
    toast.success('Link copiado com sucesso!')
    updateAnchorEl(null)
  }

  const handleToggleAnchor = (event?: React.MouseEvent<HTMLElement> | null) => {
    updateAnchorEl(event ? event.currentTarget : null)
  }

  const titleViewed =
    getStatusEnum(invoice?.status)?.label === 'Paga'
      ? 'Visualizar comprovante'
      : 'Visualizar fatura'
  const titleTooltip =
    getStatus(invoice?.status)?.label === 'Paga'
      ? 'copiar/enviar comprovante'
      : 'copiar/enviar fatura'

  return (
    <S.Wrapper>
      {invoice?.nfse_uuid && (
        <Button
          sx={{
            gap: '8px',
            [theme.breakpoints.down(450)]: {
              width: '100%'
            }
          }}
          hasFocus
          onClick={() => handleOpenNfDetails()}
        >
          <S.NfButtonContent>
            <S.NfIconWrapper>
              <InvoiceIcon width={'20px'} color={'white'} />
            </S.NfIconWrapper>
            <Box mb={'8px'}>
              <p>Visualizar NF</p>
            </Box>
          </S.NfButtonContent>
        </Button>
      )}

      {getStatus(invoice?.status)?.label !== 'Cancelada' ? (
        <Button
          sx={{
            gap: '8px',
            [theme.breakpoints.down(450)]: {
              width: '100%'
            }
          }}
          icon="receipt"
          iconColor="white"
          hasFocus
          onClick={handleOpenInvoice}
        >
          {titleViewed}
        </Button>
      ) : null}
      {getStatus(invoice?.status)?.label !== 'Cancelada' && (
        <Can
          roles={['admin', 'user', 'operator', 'financial_operator']}
          status={['trial', 'checking']}
        >
          <S.Tooltip title={titleTooltip}>
            <S.IconButton>
              <S.Icon icon="moreHoriz" onClick={handleToggleAnchor} />
            </S.IconButton>
          </S.Tooltip>
        </Can>
      )}
      <S.Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        keepMounted={false}
        onClose={() => handleToggleAnchor(null)}
        open={Boolean(anchorEl)}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top'
        }}
      >
        <S.MenuItemsWrapper>
          <S.MenuItem onClick={() => copyLinkInvoice(invoice?.uuid)}>
            <S.ContentCopyIcon />
            {getStatus(invoice?.status)?.label !== 'Paga'
              ? 'Copiar link fatura'
              : 'Copiar link do comprovante'}
          </S.MenuItem>
          <S.MenuItem onClick={() => sendToWhatsApp(invoice)}>
            <Icon
              sx={{ fill: 'black', transform: ' scaleX(-1)' }}
              icon={'reply'}
            />
            Enviar via WhatsApp
          </S.MenuItem>
        </S.MenuItemsWrapper>
      </S.Menu>
    </S.Wrapper>
  )
}

export default Actions
