import { Grid, Skeleton, Box, Typography } from '@mui/material'

const SkeletonCalendar = () => {
  const weeks = Array.from({ length: 6 })
  const days = Array.from({ length: 7 })

  return (
    <Box sx={{ width: '97%', padding: 2 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Skeleton variant="text" width={300} height={50} />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
        >
          <Skeleton variant="text" width={200} height={50} />
          <Skeleton variant="text" width={200} height={50} />
        </Box>
      </Box>

      <Grid container spacing={'1px'}>
        {['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'].map((day, index) => (
          <Grid item xs key={index}>
            <Typography
              align="center"
              sx={{ fontWeight: 'bold', color: 'gray' }}
            >
              {day}
            </Typography>
          </Grid>
        ))}
      </Grid>

      {weeks.map((_, weekIndex) => (
        <Grid
          container
          spacing={'1px'}
          key={weekIndex}
          sx={{ marginBottom: '1px' }}
        >
          {days.map((_, dayIndex) => (
            <Grid item xs key={dayIndex}>
              <Skeleton variant="rectangular" width="100%" height={80} />
            </Grid>
          ))}
        </Grid>
      ))}
    </Box>
  )
}

export default SkeletonCalendar
