import { theme } from 'styles/theme'

export const getStatus = (status: string) => {
  switch (status) {
    case 'pending':
      return {
        label: 'Pendente',
        color: theme.palette.neutral[700],
        disabled: true
      }
    case 'paid':
      return {
        label: 'Paga',
        color: theme.palette.primary.main
      }
    case 'reversed':
      return {
        label: 'Cancelado',
        color: theme.palette.error[300]
      }
    case 'dusputed':
      return {
        label: 'Cancelado',
        color: theme.palette.error[300]
      }
    case 'chargeback':
      return {
        label: 'Cancelado',
        color: theme.palette.error[300]
      }
    case 'avaiable':
      return {
        label: 'Liberado',
        color: theme.palette.secondary[300]
      }
    case 'blocked':
      return {
        label: 'Liberado',
        color: theme.palette.secondary[300]
      }
    default:
      window.newrelic.noticeError(
        new Error(`Erro ao mapear status payment details: ${status}`)
      )
      return {
        label: 'Status desconhecido',
        color: theme.palette.neutral[700],
        disabled: true
      }
  }
}
