import React, { useEffect, useState } from 'react'

import { Icon, Icons, Popover } from '@clientbase/clientbase-library'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import {
  Collapse,
  List,
  Box,
  ListItemButton as ListItemButtonMui,
  ListItemText,
  Typography
} from '@mui/material'
import mixpanel from 'mixpanel-browser'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { ParsedUrlQueryInput } from 'querystring'

import { SubheaderProps } from 'components/Drawer/Drawer'

import { theme } from 'styles/theme'

import { MenuParams } from './Menu.interface'
import * as S from './Menu.styles'

export const Menu: React.FC<MenuParams> = ({
  icon = 'add',
  label,
  router,
  handleClick,
  isExpanded,
  subheader,
  openMenu,
  handleOpenMenu,
  option,
  expandDrawer
}) => {
  const { push, pathname, query: queryRouter } = useRouter()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  let closeTimeout: NodeJS.Timeout

  const handleOpen = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget)
    clearTimeout(closeTimeout)
  }

  const handleClose = () => {
    closeTimeout = setTimeout(() => {
      setAnchorEl(null)
    }, 50)
  }

  const open = Boolean(anchorEl)

  const onMenuClick = (
    event?: React.MouseEvent<HTMLDivElement>,
    query?: ParsedUrlQueryInput
  ) => {
    const mouseEvent = event as unknown as React.MouseEvent
    const isNewTab = mouseEvent.ctrlKey || mouseEvent.metaKey

    const newPath = `${router}`
    const queryString = new URLSearchParams(
      query as Record<string, string>
    ).toString()
    const newUrl = `${newPath}${queryString ? `?${queryString}` : ''}`

    if (isNewTab) {
      window.open(newUrl, '_blank')
    } else {
      push({ query, pathname: router })
    }

    handleClick()
  }

  const onMenuClickRouter = (
    event?: React.MouseEvent<HTMLDivElement>,
    subRouter?: string
  ) => {
    const mouseEvent = event as unknown as React.MouseEvent
    const isNewTab = mouseEvent.ctrlKey || mouseEvent.metaKey

    const newUrl = `${router}${subRouter || ''}`

    if (isNewTab) {
      window.open(newUrl, '_blank')
    } else {
      push({ pathname: `${router}${subRouter}` })
    }

    handleClick()
  }

  const checkUrlMatch = (router: string, subRouter?: string) => {
    if (subRouter) {
      return pathname.split('/').includes(subRouter.split('/')[1])
    }
    if (pathname.split('/').length > 2) {
      return pathname.split('/').includes(router.substring(1))
    }
    return pathname === router
  }

  const activeSubMenu = (
    router: string,
    tab?: number | string,
    subRouter?: string
  ) => {
    if (tab || tab === 0) {
      return (
        (tab === Number(queryRouter.tab) || tab === queryRouter.tab) &&
        checkUrlMatch(router)
      )
    } else {
      return (
        pathname.split('/').includes(subRouter?.split('/')[1] as string) &&
        checkUrlMatch(router, subRouter)
      )
    }
  }

  const handleOpenMenuAndClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (subheader && option) {
      handleOpenMenu(option)
      !!expandDrawer && expandDrawer()
    } else {
      onMenuClick(event)
    }
  }

  const handleClickSubHeader = (
    event: React.MouseEvent<HTMLDivElement>,
    item: SubheaderProps
  ) => {
    if (item?.query) {
      onMenuClick(event, item.query)
    } else {
      onMenuClickRouter(event, item.subRouter)
    }
  }

  const makeLinkHref = (item: SubheaderProps) => {
    if (item?.query) {
      const queryString = new URLSearchParams(
        item.query as Record<string, string>
      ).toString()

      return `${router}?${queryString || ''}`
    } else {
      return `${router}?${item?.subRouter || ''}`
    }
  }

  useEffect(() => {
    if (checkUrlMatch(router) && router !== '/visao-geral') {
      handleOpenMenu(router === '/cobrancas' ? 'receives' : 'clients')
    }
  }, [])

  useEffect(() => {
    if (isExpanded || openMenu[option as string]) {
      handleClose()
    }
  }, [isExpanded, openMenu])
  const mixpanelTyped = mixpanel as unknown as { __loaded: string }
  const withHighlight = subheader?.filter((item) => item?.highlight)

  const ListItemButton = (
    <S.ListItemButton
      isExpanded={isExpanded}
      active={checkUrlMatch(router)}
      onClick={handleOpenMenuAndClick}
      onMouseEnter={(e) => {
        if (
          (!openMenu[option as string] &&
            isExpanded &&
            router !== '/visao-geral') ||
          !isExpanded
        ) {
          handleOpen(e)
        }
      }}
      onMouseLeave={() => {
        handleClose()
      }}
    >
      <S.ListItemIcon>
        {typeof icon !== 'string' ? (
          React.cloneElement(icon as any, {
            style: { width: '20px', height: '20px' }
          })
        ) : (
          <Icon icon={icon as Icons} width="20px" height="20px" />
        )}
      </S.ListItemIcon>
      {isExpanded && (
        <>
          <S.ListItemText primary={label} active={checkUrlMatch(router)} />
          {subheader ? (
            openMenu[option as string] ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )
          ) : null}
        </>
      )}
    </S.ListItemButton>
  )
  return (
    <Box>
      {router == '/visao-geral' ? (
        <Link
          href={router}
          onClick={(e) => e.preventDefault()}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          {ListItemButton}
        </Link>
      ) : (
        <>{ListItemButton}</>
      )}
      {subheader && isExpanded && (
        <Collapse
          in={
            withHighlight && withHighlight.length > 0
              ? true
              : option
              ? openMenu[option]
              : false
          }
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            {subheader.map((item, index) => (
              <Link
                href={makeLinkHref(item)}
                key={index}
                onClick={(e) => e.preventDefault()}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <S.ListItemSubHeader
                  highlight={item?.highlight}
                  activeSubmenu={activeSubMenu(
                    router,
                    item?.tab,
                    item?.subRouter
                  )}
                  onClick={(e) => {
                    mixpanelTyped.__loaded &&
                      mixpanel.track('Clique no menu', {
                        origem: 'sidebar',
                        menu: label,
                        submenu: item.label
                      }),
                      handleClickSubHeader(e, item)
                  }}
                  sx={{ marginTop: item?.news ? '9px' : '0' }}
                >
                  <Box position="relative">
                    {item?.news && (
                      <Typography
                        sx={{
                          fontSize: '12px',
                          backgroundColor: theme.palette.primary.main,
                          position: 'absolute',
                          padding: '3px 7px',
                          top: '-18px',
                          borderRadius: '5px'
                        }}
                      >
                        BETA
                      </Typography>
                    )}
                    <S.ListItemText
                      primary={item?.label}
                      active={activeSubMenu(router, item?.tab, item?.subRouter)}
                      highlight={item?.highlight}
                    />
                  </Box>
                </S.ListItemSubHeader>
              </Link>
            ))}
          </List>
        </Collapse>
      )}

      <Popover
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ pointerEvents: 'none' }}
        PaperProps={{
          onMouseEnter: () => clearTimeout(closeTimeout),
          onMouseLeave: handleClose,
          sx: {
            pointerEvents: 'auto',
            ml: 1,
            p: '12px 8px',
            width: '200px',
            borderRadius: '12px',
            bgcolor: theme.palette.neutral[800]
          }
        }}
      >
        <List component="div" disablePadding>
          {subheader &&
            subheader.map((item, index) => (
              <Link
                href={makeLinkHref(item)}
                key={index}
                onClick={(e) => {
                  e.preventDefault(),
                    mixpanelTyped.__loaded &&
                      mixpanel.track('Clique no menu', {
                        origem: 'popover',
                        menu: label,
                        submenu: item.label
                      })
                }}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <ListItemButtonMui
                  onClick={(e) => {
                    handleClickSubHeader(e, item)
                  }}
                  sx={{
                    p: '4px 16px',
                    width: '100%',
                    background: item?.highlight
                      ? theme.palette.success[300]
                      : activeSubMenu(router, item?.tab, item?.subRouter)
                      ? '#fff'
                      : 'none',
                    borderRadius: '6px',

                    color: item?.highlight ? '#fff' : '#000',
                    ':hover': {
                      background: item?.highlight
                        ? theme.palette.success[300]
                        : ''
                    }
                  }}
                >
                  <ListItemText
                    primary={item?.label}
                    sx={{
                      span: {
                        color: item?.highlight
                          ? '#fff'
                          : activeSubMenu(router, item?.tab, item?.subRouter)
                          ? theme.palette.neutral[100]
                          : theme.palette.neutral[400],
                        fontSize: '14px',
                        fontWeight: activeSubMenu(
                          router,
                          item?.tab,
                          item?.subRouter
                        )
                          ? 600
                          : 400
                      }
                    }}
                  />
                </ListItemButtonMui>
              </Link>
            ))}

          {!subheader && (
            <Link
              href="/visao-geral"
              key={0}
              onClick={(e) => e.preventDefault()}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <ListItemButtonMui
                onClick={() => push('/visao-geral')}
                sx={{
                  p: '4px 16px',
                  width: '100%',
                  background: router == '/visao-geral' ? '#fff' : 'none',
                  borderRadius: '6px',

                  color: '#000'
                }}
              >
                <ListItemText
                  primary={'Assistente'}
                  sx={{
                    span: {
                      color:
                        router == '/visao-geral'
                          ? theme.palette.neutral[100]
                          : theme.palette.neutral[400],
                      fontSize: '14px',
                      fontWeight: router == '/visao-geral' ? 600 : 400
                    }
                  }}
                />
              </ListItemButtonMui>
            </Link>
          )}
        </List>
      </Popover>
    </Box>
  )
}
