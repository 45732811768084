import { Box as MUIBox, styled, Typography } from '@mui/material'

import { Container } from '../'

export const Content = styled(Container)(({ theme }) => ({
  borderRadius: '8px',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  height: 'auto',
  overflowY: 'hidden',
  width: '80%',

  [theme.breakpoints.down('md')]: {
    height: 'auto',
    width: '96%'
  },

  [theme.breakpoints.down('sm')]: {
    height: 'auto',
    maxHeight: '96%',
    overflowY: 'scroll',
    padding: 0
  }
}))

export const Box = styled(MUIBox)(() => ({
  display: 'flex',
  flexDirection: 'column'
}))

export const Overlay = styled('div')(() => ({
  background: '#00000020',
  display: 'grid',
  inset: '0 0 0 0',
  placeItems: 'center',
  position: 'fixed',
  zIndex: 1200
}))

export const YearlyDiscountTag = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: theme.palette.info[200],
  padding: '2px 5px',
  borderRadius: '5px',
  fontSize: 10,
  top: -18,
  right: -25,
  color: 'white'
}))

type PlanFrequencyLabelProps = {
  planFrequency: 'yearly' | 'monthly'
  value: 'yearly' | 'monthly'
}

export const PlanFrequencyLabel = styled(Typography)<PlanFrequencyLabelProps>(
  ({ planFrequency, value }) => ({
    fontWeight: planFrequency == value ? 700 : 500
  })
)
