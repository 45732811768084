import { DataService } from 'api/DataService'
import { AxiosRequestConfig } from 'axios'
import { GetServerSidePropsContext } from 'next'

type Response<T> = {
  detail_error: string
  error: boolean
  message: string
  data: T
  status: number
}

type UrlParams = {
  rowsPerPage?: number
  page?: number
  uuid?: string
  filter_by?: string
}

type RoutesParams = {
  options?: AxiosRequestConfig
  ctx?: GetServerSidePropsContext
} & UrlParams

interface Routes {
  name: RequestName
  type: 'POST' | 'GET' | 'DELETE' | 'PATCH'
  url: (params?: UrlParams) => string
}

type RequestName =
  | 'customerCreate'
  | 'customerList'
  | 'receivables'
  | 'customerArchive'
  | 'statisticsGet'
  | 'customerGroupsList'
  | 'customerChargesList'
  | 'getTransfers'
  | 'customerRecurrencesList'
  | 'getCreditCards'
  | 'getCreditCard'
  | 'deleteCreditCard'
  | 'getContracts'
  | 'getAnnouncementPortals'
  | 'getInvoices'
  | 'nfseCities'
  | 'createNfse'
  | 'getNfse'
  | 'editNfse'
  | 'deleteNfse'
  | 'getProviders'
  | 'getFieldsLinkRegistration'
  | 'balanceDetails'
  | 'getCustomerDocuments'
  | 'customerRecentChargesList'
  | 'getAccountsPayablesList'

type Requests = {
  [key in RequestName]: <T = any>(params?: RoutesParams) => Promise<Response<T>>
}

const routes: Routes[] = [
  {
    name: 'customerCreate',
    type: 'GET',
    url: () => '/v1/customers'
  },
  {
    name: 'customerList',
    type: 'GET',
    url: (params?: UrlParams) => `/v1/customers/${params?.uuid}`
  },
  {
    name: 'receivables',
    type: 'GET',
    url: (params?: UrlParams) =>
      `/v1/receivables?per=${params?.rowsPerPage || 25}&page=${
        (params?.page || 0) + 1
      }`
  },
  {
    name: 'customerArchive',
    type: 'POST',
    url: (params?: UrlParams) => `/v1/customers/${params?.uuid}`
  },
  {
    name: 'statisticsGet',
    type: 'GET',
    url: () => '/v1/statistics'
  },
  {
    name: 'customerChargesList',
    type: 'GET',
    url: (params?: UrlParams) =>
      `https://api.clientbase.com.br/v1/billings${params?.filter_by}`
  },
  {
    name: 'customerRecurrencesList',
    type: 'GET',
    url: (params?: UrlParams) =>
      `https://api.clientbase.com.br/v1/recurrences${params?.filter_by}`
  },
  {
    name: 'customerGroupsList',
    type: 'GET',
    url: () => '/v1/customer_groups'
  },
  {
    name: 'getCreditCards',
    type: 'GET',
    url: () => `/v1/credit_cards`
  },
  {
    name: 'getCreditCard',
    type: 'GET',
    url: (params) => `/v1/customer/${params?.uuid}/credit_cards`
  },
  {
    name: 'deleteCreditCard',
    type: 'DELETE',
    url: (params) => `/v1/credit_cards/${params?.uuid}`
  },
  {
    name: 'getContracts',
    type: 'GET',
    url: (params?: UrlParams) =>
      `/v1/contracts${params?.uuid ? `/${params?.uuid}` : ''}${
        params?.filter_by
      }`
  },
  {
    name: 'getTransfers',
    type: 'GET',
    url: (params) =>
      `https://api.clientbase.com.br/v1/transfers${params?.filter_by}`
  },
  {
    name: 'getAnnouncementPortals',
    type: 'GET',
    url: (params?: UrlParams) =>
      `https://api.clientbase.com.br/v1/billings${params?.filter_by}`
  },
  {
    name: 'getInvoices',
    type: 'GET',
    url: (params?: UrlParams) => `/v1/nfses${params?.filter_by}`
  },
  {
    name: 'getProviders',
    type: 'GET',
    url: (params?: UrlParams) => `/v1/nfse_issuers${params?.filter_by}`
  },
  {
    name: 'nfseCities',
    type: 'GET',
    url: () => `/v1/nfse_cities`
  },
  {
    name: 'createNfse',
    type: 'POST',
    url: () => `/v1/nfse_issuers`
  },
  {
    name: 'editNfse',
    type: 'PATCH',
    url: (params) => `/v1/nfse_issuers/${params?.uuid}`
  },
  {
    name: 'getNfse',
    type: 'GET',
    url: (params) => `/v1/nfse_issuers/${params?.uuid}`
  },
  {
    name: 'deleteNfse',
    type: 'DELETE',
    url: (params) => `/v1/nfse_issuers/${params?.uuid}`
  },
  {
    name: 'getFieldsLinkRegistration',
    type: 'GET',
    url: () => `/v1/extra_fields`
  },
  {
    name: 'balanceDetails',
    type: 'GET',
    url: () => 'v2/merchants/balance_details'
  },
  {
    name: 'getAccountsPayablesList',
    type: 'GET',
    url: (params?: UrlParams) => `/v1/accounts_payables${params?.filter_by}`
  },
  {
    name: 'getCustomerDocuments',
    type: 'GET',
    url: (params) =>
      `/v1/customers/${params?.uuid}/documents${params?.filter_by}`
  },
  {
    name: 'customerRecentChargesList',
    type: 'GET',
    url: (params) => `/v1/billings/customer/${params?.uuid}/recents`
  }
]

const requests = {} as Requests

routes.map(
  ({ name, type, url }) =>
    (requests[name] = async <T = any>(params?: RoutesParams) =>
      DataService<T>({
        url: url(params),
        data: params?.options,
        type,
        ctx: params?.ctx
      }))
)

export const {
  customerCreate,
  customerList,
  receivables,
  customerArchive,
  statisticsGet,
  customerGroupsList,
  customerChargesList,
  customerRecurrencesList,
  getCreditCards,
  getCreditCard,
  deleteCreditCard,
  getContracts,
  getTransfers,
  getInvoices,
  nfseCities,
  createNfse,
  getNfse,
  editNfse,
  deleteNfse,
  getProviders,
  getFieldsLinkRegistration,
  balanceDetails,
  getCustomerDocuments,
  getAccountsPayablesList,
  customerRecentChargesList
} = requests
