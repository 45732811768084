import { useEffect, useState } from 'react'
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
  UseFormRegister,
  UseFormWatch
} from 'react-hook-form'
import { toast } from 'react-toastify'

import {
  Autocomplete,
  Icon,
  Loader,
  Select,
  Textfield
} from '@clientbase/clientbase-library'
import {
  Box,
  Fade,
  IconButton,
  MenuItem,
  Popper,
  Skeleton
} from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { DataService } from 'api/DataService'
import { getCreditCard } from 'api/requests'
import { useAuthContext } from 'context/AuthContext'
import { AutoCompleteBillingsParams } from 'models/Billings/AutoComplete'
import { SelectCreditCards } from 'templates/Customers/Modals/BodyModalNewChargeOrRecurrence/BodyModalNewChargeOrRecurrence'
import { paymentMethods } from 'templates/v2/Products/Form/FormProducts.utils'

import { selectCreditCardsMapper } from 'components/DrawerCreditCard/PreviewCards/PreviewCards.mapper'
import CreditCardParceling from 'components/Icons/CreditCardParceling'

import { currency, formatMoney } from 'utils'

import * as S from './PaymentsType.styles'

const installmentsWithFee = Array.from({ length: 12 }, (_, index) => ({
  value: index + 1,
  label: index === 0 ? 'Repassar juros' : `${index + 1} parcelas`
}))

const installments = Array.from({ length: 11 }, (_, index) => ({
  value: index + 2,
  label: `${index + 2} parcelas`
}))

type Installment = {
  amount_final: number
  amount_installment: number
  amount_net: number
  fee: boolean
  fee_perc_credit_card: number
}

type Installments = {
  [key in number]: Installment
}

type PaymentsTypeProps<T extends FieldValues> = {
  payments: string[] | undefined
  register: UseFormRegister<T>
  control: Control<T, object>
  customerUuid?: string | null
  errors?: FieldErrors<T>
  selectCreditCard?: boolean
  watch: UseFormWatch<T>
  label?: string
}

const PaymentsType = <T extends FieldValues>({
  payments,
  register,
  customerUuid,
  errors,
  control,
  watch,
  selectCreditCard = true,
  label = 'Escolha os meios de pagamento aceitos nesta cobrança:'
}: PaymentsTypeProps<T>) => {
  const { user } = useAuthContext()
  const [creditCard, setCreditCards] = useState([] as SelectCreditCards[])
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const [canOpenPopper, setCanOpenPopper] = useState(true)
  const [installmentsList, setInstallmentList] = useState<Installments | null>(
    null
  )
  const [loading, setLoading] = useState(false)

  const { max_installments, max_installments_without_fee, amountBilled } =
    watch()

  const parceledExits = !!payments?.includes('credit_card_installments')

  const matches = useMediaQuery('(min-width: 1280px)')

  const getCards = async (customerUuid?: string) => {
    setCreditCards([])
    if (!customerUuid) return
    setLoading(true)
    try {
      const response = await getCreditCard({ uuid: customerUuid })
      const data = selectCreditCardsMapper(response.data.records ?? [])
      setCreditCards(data)
    } catch (e) {
      toast.error('Erro ao buscar cartões')
    }
    setLoading(false)
  }

  const getSimulation = async () => {
    if (!amountBilled || !payments?.includes('credit_card_installments')) return

    setLoading(true)
    setInstallmentList([])

    try {
      const value = currency((amountBilled ?? 0).toString())
        .replace('.', '')
        .replace(',', '.')

      const response = await DataService({
        type: 'GET',
        url: `v3/billings/installments_simulator?amount=${Number(
          value
        )}&max_installments=${max_installments}&max_installments_without_fee=${max_installments_without_fee}`
      })
      const installments: Installments = await response.data.record
      setInstallmentList(installments)
    } finally {
      setLoading(false)
    }
  }

  const handleClosePopper = () => setCanOpenPopper(false)

  const popperIsOpen =
    Boolean(anchorEl) && !payments?.includes('pix') && canOpenPopper

  const popoverId = !payments?.includes('pix')
    ? 'selecione o PIX para um pagamento mais rápido'
    : undefined

  useEffect(() => {
    if (!customerUuid || !payments?.includes('credit_card_recurring')) return
    getCards(customerUuid)
  }, [payments, customerUuid])

  useEffect(() => {
    if (
      !parceledExits &&
      !user?.installment_billings &&
      !payments?.includes('credit_card_installments')
    ) {
      return
    }
    getSimulation()
  }, [max_installments, max_installments_without_fee, amountBilled, payments])

  if (!max_installments && !max_installments_without_fee) {
    return null
  }

  return (
    <Box role="document">
      <S.Fieldset>
        <S.Legend>{label}</S.Legend>

        <S.CheckboxesWrapper>
          {paymentMethods.map(({ label, name, icon }) => (
            <S.CheckboxWrapper
              key={name}
              data-pixIsActivated={payments?.includes('pix')}
              {...(payments?.includes('pix') && {
                id: popoverId,
                onClick: (e) => setAnchorEl(e.currentTarget)
              })}
            >
              <input
                type="checkbox"
                id={name}
                value={name}
                data-actived={payments ? payments?.includes(name) : false}
                style={{ position: 'absolute' }}
                {...register('payments' as Path<T>)}
              />
              <S.Label
                htmlFor={name}
                inputIsChecked={payments ? payments?.includes(name) : false}
              >
                {icon} {label}{' '}
                {name === 'pix' && <S.Badge>melhor opção</S.Badge>}
              </S.Label>
            </S.CheckboxWrapper>
          ))}

          <S.CheckboxWrapper
            {...(payments?.includes('pix') && {
              id: popoverId,
              onClick: (e) => setAnchorEl(e.currentTarget)
            })}
          >
            <input
              type="checkbox"
              id="credit_card_installments"
              value="credit_card_installments"
              data-actived={payments?.includes('credit_card_installments')}
              placeholder={'credit_card_installments'}
              style={{ position: 'absolute' }}
              {...register('payments' as Path<T>)}
            />
            <S.Label
              htmlFor="credit_card_installments"
              inputIsChecked={payments?.includes('credit_card_installments')}
            >
              <CreditCardParceling /> Crédito parcelado
            </S.Label>
          </S.CheckboxWrapper>

          <Box
            height={parceledExits ? 'fit-content' : '0px'}
            sx={{ overflow: 'hidden', transition: 'all 200ms ease-in' }}
            display="flex"
            gap="16px"
            p={parceledExits ? 2 : '0px 16px'}
            flexWrap="wrap"
          >
            <Box display="flex" flexDirection="column" gap={2} flex="1">
              <Controller
                name={'max_installments' as Path<T>}
                control={control}
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error }
                }) => (
                  <Select
                    inputRef={ref}
                    helperText={error?.message}
                    error={!!error?.message}
                    fullWidth={true}
                    onChange={onChange}
                    sx={{ height: '42px', width: '100%' }}
                    value={value}
                    defaultValue={value || max_installments}
                    label="Autorizar parcelamento em até:"
                    disabled={loading}
                  >
                    {installments.map(({ label, value }) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />

              <Controller
                name={'max_installments_without_fee' as Path<T>}
                control={control}
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error }
                }) => {
                  return (
                    <Select
                      inputRef={ref}
                      helperText={error?.message}
                      error={!!error?.message}
                      fullWidth={true}
                      onChange={onChange}
                      sx={{ height: '42px', width: '100%' }}
                      value={value || max_installments_without_fee}
                      label="Em até quantas vezes sem juros?"
                      disabled={loading}
                    >
                      {installmentsWithFee.map(({ label, value }) => (
                        <MenuItem
                          key={value}
                          value={value}
                          disabled={value > max_installments}
                        >
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  )
                }}
              />
            </Box>

            {!!installmentsList && !loading && user?.installment_billings && (
              <Box display="flex" flexDirection="column" flex="1" ml={4}>
                <S.SimulationListHeading>Simulação:</S.SimulationListHeading>

                <S.SimulationListWrapper>
                  {Object.keys(installmentsList).map((key) => (
                    <li key={key}>
                      {key}x de{' '}
                      {formatMoney(installmentsList[key].amount_installment)} -
                      você irá receber{' '}
                      <b>{formatMoney(installmentsList[key].amount_net)}</b> -{' '}
                      <b>{installmentsList[key].fee_perc_credit_card}%</b>
                    </li>
                  ))}
                </S.SimulationListWrapper>
              </Box>
            )}

            {loading && user?.installment_billings && (
              <Box display="flex" flexDirection="column" flex="1" ml={4}>
                <Skeleton variant="text" width="80px" height={29} />

                <S.SimulationListWrapper>
                  {Array.from({ length: 12 }, (_a, i) => (
                    <li key={i}>
                      <Skeleton variant="text" width="300px" height={17} />
                    </li>
                  ))}
                </S.SimulationListWrapper>
              </Box>
            )}
          </Box>
        </S.CheckboxesWrapper>
      </S.Fieldset>

      <Popper
        open={popperIsOpen}
        anchorEl={anchorEl}
        placement="top-end"
        transition
        sx={{ zIndex: 999999 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <S.PopperWrapper data-hugscreen={matches}>
              <S.HeadingWrapper>
                <S.PopperTitle>Tem certeza? 🤔</S.PopperTitle>
                <IconButton>
                  <Icon icon="close" onClick={handleClosePopper} />
                </IconButton>
              </S.HeadingWrapper>
              <S.PopperDescription>
                <b>
                  O recebimento via PIX tem o mesmo custo do boleto, você recebe
                  na hora e não tem nenhum trabalho extra.
                </b>
                <br />
                <br />É só selecionar essa opção para ofertar mais essa
                facilidade para os seus clientes 🙂
              </S.PopperDescription>
            </S.PopperWrapper>
          </Fade>
        )}
      </Popper>

      {payments?.includes('credit_card_recurring') && selectCreditCard && (
        <Controller
          control={control}
          name={'credit_card_uuid' as Path<T>}
          render={({ field: { onChange, value } }) => {
            return (
              <Autocomplete
                sx={{
                  ...(payments
                    ? !payments?.includes('credit_card_recurring') && {
                        opacity: 0,
                        userSelect: 'none',
                        height: 0
                      }
                    : {})
                }}
                options={creditCard}
                value={value}
                onChange={(_, option = { value: '', label: '' }) => {
                  onChange(option)
                }}
                renderOption={(props, option) => {
                  const optionTyped = option as AutoCompleteBillingsParams
                  return (
                    <Box component="li" {...props} key={optionTyped.value}>
                      {optionTyped.label}
                    </Box>
                  )
                }}
                noOptionsText={
                  loading ? <Loader /> : 'Não há cartões disponíveis.'
                }
                renderInput={(params) => (
                  <Textfield
                    helperText={errors?.credit_card_uuid?.message as string}
                    error={!!errors?.credit_card_uuid?.message}
                    placeholder="Selecionar cartão"
                    {...params}
                  />
                )}
              />
            )
          }}
        />
      )}

      {errors?.payments?.message && (
        <S.ErrorLabel>Selecione um método de pagamento.</S.ErrorLabel>
      )}
    </Box>
  )
}

export default PaymentsType
