import { Controller, FieldValues, Path } from 'react-hook-form'

import { Select, Textfield } from '@clientbase/clientbase-library'
import { Box, Grid, MenuItem, Typography, useMediaQuery } from '@mui/material'

import { onlyNumber } from 'utils'

import { FrequencyControlsProps } from './FrequencyControls'

interface SelectOptions {
  value: number
  label: string
}

export const chargeTypeOptions: SelectOptions[] = [
  { label: 'Semanal', value: 0 },
  { label: 'Mensal', value: 1 },
  { label: 'Trimestral', value: 2 },
  { label: 'Semestral', value: 3 },
  { label: 'Anual', value: 4 }
]

export const chargeFrequencyOptions: SelectOptions[] = [
  { label: 'Tempo indeterminado', value: 0 },
  { label: '2x', value: 2 },
  { label: '3x', value: 3 },
  { label: '4x', value: 4 },
  { label: '5x', value: 5 },
  { label: '6x', value: 6 },
  { label: '7x', value: 7 },
  { label: '8x', value: 8 },
  { label: '9x', value: 9 },
  { label: '10x', value: 10 },
  { label: '11x', value: 11 },
  { label: '12x', value: 12 },
  { label: 'Outros', value: 13 }
]

const FrequencyControls = <T extends FieldValues>({
  control,
  setStoredCycleValue,
  isUnique,
  watch
}: FrequencyControlsProps<T>) => {
  const match = useMediaQuery('(max-width: 900px)', { noSsr: false })

  return (
    <Box>
      <Typography variant="mdLight">Cobrar:</Typography>
      <Grid
        container={true}
        rowSpacing={1}
        columnSpacing={match ? 1 : 2}
        alignItems="end"
      >
        <Grid item={true} xs={12} sm={6} lg={6}>
          <Controller
            control={control}
            name={'frequency' as Path<T>}
            render={({ fieldState: { error }, field }) => (
              <Select
                helperText={error?.message}
                error={!!error?.message}
                fullWidth
                value={field.value}
                displayEmpty
                disabled={isUnique}
                onChange={(event) => {
                  field.onChange(event)
                }}
              >
                {chargeTypeOptions.map((option) => (
                  <MenuItem key={option.label} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>
        <Grid
          item={true}
          xs={12}
          sm={0.5}
          lg={0.5}
          alignSelf="center"
          justifySelf="center"
          textAlign={match ? 'left' : 'center'}
        >
          <Typography variant="mdLight">por</Typography>
        </Grid>
        <Grid
          item={true}
          xs={12}
          sm={watch('totalCycles' as Path<T>) === 13 ? 2.8 : 5.5}
          lg={watch('totalCycles' as Path<T>) === 13 ? 2.8 : 5.5}
        >
          <Controller
            control={control}
            name={'totalCycles' as Path<T>}
            render={({ fieldState: { error }, field }) => (
              <Select
                helperText={error?.message}
                error={!!error?.message}
                fullWidth
                value={field.value}
                disabled={isUnique}
                displayEmpty
                onChange={(event) => {
                  field.onChange(event)
                  !!setStoredCycleValue &&
                    setStoredCycleValue(event.target.value as number)
                }}
                renderValue={(value) =>
                  `${
                    !value
                      ? 'Tempo indeterminado'
                      : value === 13
                      ? 'Outros'
                      : value + 'x'
                  }`
                }
              >
                {chargeFrequencyOptions.map((option) => (
                  <MenuItem key={option.label} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>
        {watch('totalCycles' as Path<T>) === 13 && (
          <Grid
            item={true}
            xs={12}
            sm={2.7}
            lg={2.7}
            textAlign={match ? 'left' : 'center'}
            display="flex"
            justifyContent="end"
          >
            <Controller
              control={control}
              name={'totalCyclesCustom' as Path<T>}
              render={({ fieldState: { error }, field }) => (
                <Textfield
                  helperText={error?.message}
                  name="totalCyclesCustom"
                  onChange={(event) => {
                    const res = onlyNumber(event.target.value)

                    if (Number(res) <= 120) {
                      field.onChange(res)
                    }
                  }}
                  value={field.value}
                  placeholder="Digite a quantidade de cobranças"
                  error={!!error?.message}
                />
              )}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default FrequencyControls
