const activeRequests: Record<string, any> = {}

export const requestBlocker = {
  isBlocked: (key: string) => {
    return activeRequests[key] && activeRequests[key] > Date.now()
  },

  block: (key: string, durationMs = 1000) => {
    activeRequests[key] = Date.now() + durationMs

    setTimeout(() => {
      delete activeRequests[key]
    }, durationMs + 100)
  }
}
