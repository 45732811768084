import React, { useContext } from 'react'

import { Chip, Icon } from '@clientbase/clientbase-library'
import { Box, Tooltip } from '@mui/material'

import { theme } from 'styles/theme'

import { ModalChargeDetailContext } from '../../../../context'
import { getHoverText, getStatus } from '../../../../ModalChargeDetail.utils'

const Status: React.FC = () => {
  const { invoice } = useContext(ModalChargeDetailContext)

  const title = invoice?.viewed
    ? 'Cliente abriu a fatura'
    : 'Cliente não abriu a fatura'

  const colorIcon = invoice?.viewed
    ? theme.palette.primary.main
    : theme.palette.neutral[700]

  return (
    <Box gridRow="1" display="flex" gap="8px">
      <Tooltip arrow title={getHoverText(invoice.status, invoice.dueDate).text}>
        <div>
          <Chip
            key={invoice?.status}
            label={getStatus(invoice?.status, invoice.dueDate)?.label}
            sx={{
              background: getStatus(invoice?.status, invoice.dueDate)?.color,
              color: getStatus(invoice?.status, invoice.dueDate)?.fontColor,
              height: '25px',
              width: '160px',
              [theme.breakpoints.down(508)]: {
                width: '120px'
              }
            }}
          />
        </div>
      </Tooltip>
      <Tooltip title={title} arrow={true}>
        <Box>
          <Icon icon="doneAll" colorSX={colorIcon} />
        </Box>
      </Tooltip>
    </Box>
  )
}

export default Status
