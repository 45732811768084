import React from 'react'
import { UseFormSetError, UseFormSetValue } from 'react-hook-form'

import { DataService } from 'api/DataService'
import { constructorProducts, Products } from 'models/Plans'
import moment from 'moment'
import {
  ChargeRecurrenceBodyModalForm,
  EditRecurrenceBodyModalForm
} from 'templates/Charge/interface'
import { v4 as uuidv4 } from 'uuid'

import { EditChargeSchema } from 'components/Modal/ModalEditCharge/schema'
import { DiscountField } from 'components/Modal/ModalEditCharge/template/Discounts.interface'
import { enumNature } from 'components/Modal/ModalInvoice/ModalInvoice.utils'

import { formatCents, formatMoney, onlyNumber } from 'utils'
import { priceConverter } from 'utils/FormatMoney'
import { setValues } from 'utils/SetValues'

export interface ConfigurePlanProps {
  plan: string | undefined
  planDefault?: Products
  setAddFieldDiscount: React.Dispatch<React.SetStateAction<DiscountField[]>>
  setValue: UseFormSetValue<ChargeRecurrenceBodyModalForm>
  isCharge: boolean
  setIsUnique: React.Dispatch<React.SetStateAction<boolean>>
  item?: boolean
}

export const getProductByUuid = async (uuid: string): Promise<Products> => {
  if (uuid == 'initial') return {} as Products

  const response = await DataService({
    type: 'GET',
    url: `/v2/products/${uuid}`
  })

  return constructorProducts([response.data.record])[0]
}

export const configurePlan = async ({
  plan,
  planDefault,
  setAddFieldDiscount,
  isCharge,
  setValue,
  item,
  setIsUnique
}: ConfigurePlanProps) => {
  if (plan) {
    if (plan === 'initial') return
    let planSelected = await getProductByUuid(plan)

    if (planDefault) {
      planSelected = planDefault
    }

    setValue('payments', planSelected.paymentType)
    setValue('description', planSelected.billingDescription)

    const discounts: DiscountField[] = (planSelected?.discounts || [])?.map(
      (discount) => {
        const dateDiscounts = moment()

        return {
          amount: discount?.amount,
          date: new Date(dateDiscounts.toLocaleString()),
          uuid: uuidv4()
        }
      }
    )

    let dateRecurrence = moment()
    if (planSelected?.dueDay) {
      dateRecurrence = moment().date(planSelected?.dueDay)
      if (moment() > dateRecurrence) {
        dateRecurrence = moment()
          .date(planSelected?.dueDay)
          .month(moment().get('month') + 1)
      }
    }

    setAddFieldDiscount(discounts)

    const parseAmount = onlyNumber(formatCents(planSelected?.amount))

    setValues(
      {
        amountBilled: parseAmount,
        dueDate: new Date(dateRecurrence.toLocaleString()),
        discountPolicy:
          planSelected?.discountPolicy !== 'no_discount' ? true : false,
        interestPolicy:
          planSelected?.interestPolicy !== 'no_interest' ? true : false,
        totalCycles: planSelected?.totalCycles || !isCharge ? 0 : 1,
        payments: planSelected?.paymentType,
        description: planSelected?.billingDescription || '',
        interestFee: planSelected?.interestFee || '2.0',
        interestFine: planSelected?.interestFine || '1.0',
        hasItemsOnDescription: false
      } as any,
      setValue
    )

    setIsUnique(!!planSelected?.totalCycles || !isCharge ? false : true)

    if (planSelected.nfsePolicy != 'no_nfse') {
      setValue('interestInvoice', true)
      setValue('amount_type', planSelected.nfseScheduled?.amount_type)
      setValue('copy_description', planSelected.nfseScheduled?.copy_description)
      setValue('descriptionInvoice', planSelected.nfseScheduled?.description)
      setValue(
        'iss_retention',
        planSelected.nfseScheduled?.iss_retention ? 'yes' : 'no'
      )
      setValue(
        'issue_when',
        planSelected.nfseScheduled?.issue_when || planSelected.nfsePolicy
      )
      setValue(
        'nature_operation',
        enumNature[
          planSelected.nfseScheduled
            ?.nature_operation as keyof typeof enumNature
        ]
      ),
        setValue('nfse_issuer_uuid', planSelected.nfseIssuerUuid)
      setValue(
        'service_list_code',
        planSelected.nfseScheduled?.nfse_issuer_service.uuid
      )
    }

    if (!item) {
      setValue('items', [
        {
          uuid: '',
          price: parseAmount as unknown as number,
          quantity: 1,
          total: parseAmount as unknown as number,
          typedValue: '',
          product: {
            label: planSelected.name,
            value: planSelected.uuid
          }
        }
      ])
    }
  }
}

export const checkDescriptionVariant = (
  description: string | undefined,
  setError:
    | UseFormSetError<ChargeRecurrenceBodyModalForm>
    | UseFormSetError<EditRecurrenceBodyModalForm>
    | UseFormSetError<EditChargeSchema>
) => {
  const regex = /\{\{(\w+)/gm
  const regexWithCorrectParams =
    /\{\{(mes_vencimento|mes_anterior_vencimento)\}\}.*\/.*\{\{(ano_vencimento|ano_anterior_vencimento)\}\}/g

  if (description?.match(regex)) {
    const arrayWithCorrectVariants = description?.match(regexWithCorrectParams)

    if (!arrayWithCorrectVariants) {
      setError('description', {
        message:
          'Este modelo contém parâmetros de variável com formatação incorreta. Os parâmetros de variável devem ser mes_vencimento, ano_vencimento, mes_anterior_vencimento ou ano_anterior_vencimento com conjunto de chaves. ex: {{mes_vencimento}}',
        type: 'pattern'
      })

      return false
    }
  }

  return true
}

export const formatedMessageError = (errorMessage: string): string => {
  if (errorMessage.includes('[')) {
    const formatedMessage = errorMessage
      .split(' - ')[1]
      .replace(/[^a-zA-Z 0-9 . ç]+/g, '')
      .split(' ')

    formatedMessage[formatedMessage.length - 1] = formatMoney(
      formatedMessage[formatedMessage.length - 1]
    )

    return formatedMessage.join(' ')
  }
  return errorMessage
}

export const createTextTable = (
  items: {
    price: number
    quantity: number
    total: number
    description?: string
    product?: { label: string; value: string }
    typedValue: ''
    uuid: string
  }[],
  totalValue: number,
  isRecurrence?: boolean,
  onInvalidItems?: () => void
) => {
  if (!items || items.length === 0) return ''

  let result = `Itens da ${isRecurrence ? 'recorrência' : 'cobrança'}\n\n`
  let hasValidItems = false

  items.forEach((item, index) => {
    if (item.price === 0 && item.total === 0) return

    hasValidItems = true
    const productName = item.product
      ? item.product.label
      : item.description || ''
    result += `Item ${index + 1}: ${productName} / `
    result += `Preço unitário: ${formatMoney(priceConverter(item.price))} / `
    result += `Quantidade: ${item.quantity} / `
    result += `Subtotal: ${formatMoney(priceConverter(item.total))}`
    result += '\n'
  })

  if (!hasValidItems || totalValue === 0) {
    if (onInvalidItems) {
      onInvalidItems()
    }
    return ''
  }

  result += `\nTotal: R$ ${totalValue.toFixed(2)} \n`

  return result
}
