import { useEffect, useState } from 'react'
import {
  Control,
  Controller,
  FieldArrayWithId,
  FieldErrors,
  SubmitHandler,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetError,
  UseFormSetFocus,
  UseFormSetValue,
  UseFormWatch
} from 'react-hook-form'
import { toast } from 'react-toastify'

import {
  Button,
  Dialog,
  Icon,
  Loader,
  Textarea
} from '@clientbase/clientbase-library'
import {
  Box,
  FormControlLabel,
  Stack,
  Switch,
  Tooltip,
  Typography
} from '@mui/material'
import { DataService } from 'api/DataService'
import { DiscountParams } from 'models/Discounts'
import { RecurrencesParams } from 'models/Recurrences'
import { EditRecurrenceBodyModalForm } from 'templates/Charge/interface'
import { FormRecurrenceType } from 'templates/Charge/schemaRecurrence'
import { checkDescriptionVariant } from 'templates/Customers/Modals/BodyModalNewChargeOrRecurrence/BodyModalNewChargeOrRecurrence.utils'
import BillingMethod from 'templates/Customers/Modals/BodyModalNewChargeOrRecurrence/Components/BillingMethod'
import Infos from 'templates/Customers/Modals/BodyModalNewChargeOrRecurrence/Components/Infos'
import OptionsAdditionals from 'templates/Customers/Modals/BodyModalNewChargeOrRecurrence/Components/OptionsAdditionals'
import PopoverNewVariant from 'templates/Customers/Modals/components/NewVariant'
import { v4 as uuidv4 } from 'uuid'

import IdentifyInternal from 'components/IdentifyInternal/IdentifyInternal'
import { IdentifyInternal as IIdentifyInternal } from 'components/IdentifyInternal/IdentifyInternal.interface'
import { ErrorLabel } from 'components/PaymentsType'

import { currency, formatCents } from 'utils'
import { priceConverter } from 'utils/FormatMoney'
import { toastProps } from 'utils/types/toast-props'

import { theme } from 'styles/theme'

import { DiscountField } from './templates/Discounts.interface'

interface Props {
  handleSubmit: UseFormHandleSubmit<FormRecurrenceType>
  control: Control<FormRecurrenceType, any>
  watch: UseFormWatch<FormRecurrenceType>
  onClose: (get: boolean) => void
  uuid: string
  currentCycle: number
  paymentType: string[]
  discounts: DiscountParams[]
  setValue: UseFormSetValue<FormRecurrenceType>
  register: UseFormRegister<FormRecurrenceType>
  errors: FieldErrors<FormRecurrenceType>
  recurrence: RecurrencesParams
  setFocus: UseFormSetFocus<EditRecurrenceBodyModalForm>
  setError: UseFormSetError<EditRecurrenceBodyModalForm>
  firstDueDate?: string
  refetch?: () => void

  itemsFields?: FieldArrayWithId<FormRecurrenceType, 'items', 'id'>[]
  appendItem?: UseFieldArrayAppend<FormRecurrenceType, 'items'>
  removeItem?: UseFieldArrayRemove
  totalValue?: number

  nameCustomer?: string
}

function RenderEditChargeModal({
  handleSubmit,
  control,
  watch,
  setFocus,
  uuid,
  onClose,
  currentCycle,
  setValue,
  register,
  discounts,
  errors,
  setError,
  recurrence,
  refetch,
  itemsFields,
  appendItem,
  removeItem,
  totalValue,
  nameCustomer
}: Props) {
  const { payments, description } = watch()
  const [loading, setLoading] = useState(false)
  const [addFieldDiscount, setAddFieldDiscount] = useState<DiscountField[]>([])
  const [identifyInternal, setIdentifyInternal] = useState<IIdentifyInternal[]>(
    []
  )

  const handleCloseDialog = () => {
    setDialog((s) => ({ ...s, isOpen: false }))
  }
  const initialDialogSettings = {
    isOpen: false,
    description: '',
    title: '',
    formData: {} as FormRecurrenceType
  }
  const [dialog, setDialog] = useState(initialDialogSettings)
  const handleOpenDialog = ({
    title,
    description,
    formData
  }: {
    title: string
    description: string
    formData: FormRecurrenceType
  }) => {
    setDialog({ title, description, isOpen: true, formData })
  }
  const [storedCycleValue, setStoredCycleValue] = useState(
    recurrence.totalCycles
  )
  const [isUnique, setIsUnique] = useState(false)

  useEffect(() => {
    if (discounts.length > 0) {
      const array: DiscountField[] = []
      discounts.forEach(({ amount, days, policy }) => {
        let amountToSave

        if (policy == 'percentage') {
          amountToSave = amount
        } else {
          amountToSave = formatCents(amount) || 0
        }

        array.push({
          uuid: uuidv4(),
          amount: amountToSave.toString(),
          days: days.toString(),
          errors: undefined,
          type: policy == 'percentage' ? '0' : '1'
        })
      })
      setAddFieldDiscount(array)
    } else {
      setAddFieldDiscount([
        {
          days: undefined,
          amount: undefined,
          errors: undefined,
          uuid: uuidv4()
        }
      ])
    }

    if (recurrence?.metadata) {
      const metadata = [] as IIdentifyInternal[]

      Object.keys(recurrence?.metadata).map((key) => {
        metadata.push({
          name: key,
          value: recurrence?.metadata[key],
          uuid: uuidv4()
        })
      })
      setIdentifyInternal(metadata)
    }
  }, [])
  const handleClickToSubmit: SubmitHandler<FormRecurrenceType> = async (
    dataForm
  ) => {
    if (!dataForm.payments?.length) return
    const isCorrect = checkDescriptionVariant(description, setError)
    if (!isCorrect) return
    let isError = false

    if (dataForm.discountPolicy && addFieldDiscount.length > 0) {
      addFieldDiscount.forEach((current) => {
        if (
          current.errors?.days?.isError ||
          current?.days === undefined ||
          current?.days === null
        ) {
          setFocus('discountLimitDate')
          isError = true
        }
        if (
          current.errors?.amount?.isError ||
          current?.amount === undefined ||
          current?.amount === ''
        ) {
          setFocus('discountValueField')
          isError = true
        }
      })
      setAddFieldDiscount((current) => {
        return current.map((obj) => {
          let messageDate = undefined
          let messageAmount = undefined
          if (!obj.errors?.days?.isError)
            if (obj.days === undefined) {
              isError = true
              messageDate = 'Campo obrigatório.'
            }
          if (!obj.errors?.amount?.isError)
            if (obj.amount === undefined || obj.amount === '') {
              isError = true
              messageAmount = 'Campo obrigatório.'
            }

          if (messageAmount || messageDate) {
            return {
              ...obj,
              errors: {
                amount: messageAmount
                  ? { message: messageAmount, isError: true }
                  : { ...obj?.errors?.amount },
                days: messageDate
                  ? { message: messageDate, isError: true }
                  : { ...obj?.errors?.days }
              }
            }
          }
          return { ...obj }
        })
      })
    }

    if (identifyInternal.length > 0) {
      identifyInternal.forEach((current) => {
        if (
          current.errors?.name?.isError ||
          current?.name === undefined ||
          current?.name === ''
        ) {
          isError = false
        }
        if (
          current.errors?.value?.isError ||
          current?.value === undefined ||
          current?.value === null
        ) {
          isError = false
        }
      })

      setIdentifyInternal((current) => {
        return current.map((obj, i) => {
          let messageName = undefined
          let messageValue = undefined

          if (obj.value === undefined || obj.value === '') {
            isError = true
            setFocus(`identify-value-${i}`)
            messageValue = 'Campo obrigatório.'
          }

          if (obj.name === undefined || obj.name === '') {
            isError = true
            setFocus(`identify-name-${i}`)
            messageName = 'Campo obrigatório.'
          }

          if (messageName || messageValue) {
            return {
              ...obj,
              errors: {
                name: messageName
                  ? { message: messageName, isError: true }
                  : { ...obj?.errors?.name },
                value: messageValue
                  ? { message: messageValue, isError: true }
                  : { ...obj?.errors?.value }
              }
            }
          }
          return { ...obj }
        })
      })
    }

    if (!isError) {
      handleOpenDialog({
        title: 'Editar Recorrência',
        description: 'Confirma editar a recorrência deste cliente?',
        formData: dataForm
      })
    }
  }

  const handleSubmitForm: SubmitHandler<FormRecurrenceType> = async (
    dataForm
  ) => {
    const idToast = toast.loading('Editando recorrência...')

    const payments = dataForm?.payments?.join(';')

    const data = {
      ...(dataForm.credit_card_uuid && {
        credit_card_uuid: dataForm?.credit_card_uuid?.value
      }),

      description: dataForm?.description,
      discount_policy:
        dataForm.discountPolicy && addFieldDiscount.length > 0 ? 4 : 1,
      discounts_attributes: [] as {
        amount: number
        days: number
        policy: number
      }[],
      ...(dataForm?.interestNegativation
        ? {
            negativation: {
              days_to_report: Number(dataForm?.daysToReport)
            }
          }
        : { negativation: null }),
      due_day: dataForm.dueDay,
      interest_policy: dataForm.interestPolicy ? 3 : 1,
      ...(dataForm.interestPolicy && {
        interest_fee: dataForm.interestFee,
        interest_fine: dataForm.interestFine
      }),
      payment_type: payments,
      total_cycles:
        dataForm.totalCycles === 13
          ? Number(dataForm?.totalCyclesCustom)
          : dataForm?.totalCycles,
      nfse_policy: !dataForm?.interestInvoice
        ? 'no_nfse'
        : dataForm?.issue_when,
      ...(dataForm?.interestInvoice && {
        nfse_scheduled_attributes: {
          amount_type: dataForm?.amount_type,
          ...(dataForm?.amount_type === 'amount_custom' &&
            dataForm?.amountCustom && {
              amount: currency(dataForm?.amountCustom)
                .replace('.', '')
                .replace(',', '.')
            }),
          description: dataForm?.descriptionInvoice,
          iss_retention: dataForm?.iss_retention === 'yes' ? true : false,
          nfse_issuer_uuid: dataForm?.nfse_issuer_uuid,
          nfse_issuer_service_uuid: dataForm?.service_list_code,
          nature_operation: Number(dataForm?.nature_operation),
          copy_description: dataForm?.copy_description
        }
      }),
      frequency: dataForm.frequency,
      limit_days_for_payment: dataForm.expirationField,
      metadata: {},
      billing_items_attributes: dataForm.items?.map((item) => {
        return {
          amount_billed: priceConverter(item.total),
          quantity: item.quantity,
          amount_unit: priceConverter(item.total / item.quantity),
          ...(item.description
            ? { description: item.description }
            : item.product
            ? { product_uuid: item.product.value }
            : {})
        }
      }),
      amount: priceConverter(totalValue || 0)
    }

    if (dataForm.discountPolicy) {
      addFieldDiscount.forEach(({ amount, days }, i) => {
        const attributes = {
          amount:
            addFieldDiscount[i]?.type === '0'
              ? Number(amount)
              : Number(
                  currency((!!amount && amount) || '0')
                    .replace('.', '')
                    .replace(',', '.')
                ),
          days: Number(days),
          policy: addFieldDiscount[i]?.type === '0' ? 3 : 2
        }
        data.discounts_attributes.push(attributes)
      })
    }

    if (identifyInternal.length > 0) {
      identifyInternal.map(({ name, value }) => {
        data.metadata = { ...data.metadata, [name as string]: value }
      })
    }
    const response = await DataService({
      data: {
        ...data
      },
      type: 'PATCH',
      url: `/v3/recurrences/${uuid}`
    })

    const errorMessage =
      response.error && (response.detail_error || response.message)

    toast.update(idToast, {
      render: response.error ? errorMessage : response.data.message,
      type: response.error ? 'error' : 'success',
      ...toastProps
    })

    !!refetch && refetch()
    setLoading(false)
    onClose(true)
  }

  const marks = [
    {
      label: '0',
      value: 0
    }
  ]

  let index = 1
  let currentCycles = currentCycle === 0 ? 1 : currentCycle
  for (index; index <= 24; index++) {
    marks.push({ label: `${currentCycles}`, value: index })
    currentCycles++
  }

  if (loading) {
    return <Loader hasOverlay />
  }

  return (
    <Box display="flex" flexDirection="column" position="relative">
      <Infos
        isCharge={false}
        control={control}
        edit
        nameCustomer={nameCustomer}
        watch={watch}
        itemsFields={itemsFields as any}
        appendItem={appendItem as any}
        removeItem={removeItem}
        totalValue={totalValue}
        setValue={setValue}
        errors={errors}
      />

      <BillingMethod
        control={control}
        payments={payments}
        register={register}
        watch={watch}
        errors={errors}
        customerUuid={recurrence?.customerUuid}
        storedCycleValue={storedCycleValue}
        setStoredCycleValue={setStoredCycleValue}
        isUnique={isUnique}
        setIsUnique={setIsUnique}
      />

      <Box mt="18px">
        <Controller
          control={control}
          name="description"
          render={({ field: { onChange, value }, fieldState }) => (
            <div>
              <Textarea
                fullWidth={true}
                onChange={onChange}
                placeholder="Descrição"
                value={value}
                label={
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box display="flex" gap="5px">
                      Descreva sua cobrança:
                      <Tooltip
                        arrow
                        enterTouchDelay={0}
                        title="Este campo é destinado para adicionar informações complementares, exemplo: nome de outro responsável, nome do dependente, código de identificação, etc."
                      >
                        <Box position="relative">
                          <Icon icon="info" />
                        </Box>
                      </Tooltip>
                    </Box>

                    <Typography
                      variant="smLight"
                      color={theme.palette.neutral[400]}
                    >
                      Caracteres: {description?.length}/2000
                    </Typography>
                  </Stack>
                }
                maxLength={2000}
              />
              {fieldState.error?.message && (
                <ErrorLabel>{fieldState.error?.message}</ErrorLabel>
              )}
            </div>
          )}
        />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Tooltip
            title={
              !itemsFields?.length || totalValue === 0
                ? 'Adicione pelo menos um item com valor para incluir na descrição'
                : ''
            }
            arrow
          >
            <Box>
              <FormControlLabel
                control={
                  <Switch
                    checked={watch('hasItemsOnDescription')}
                    disabled={!itemsFields?.length || totalValue === 0}
                    onChange={(e) => {
                      const newValue = e.target.checked
                      setValue('hasItemsOnDescription', newValue)
                    }}
                  />
                }
                label={
                  <Typography
                    fontSize="15px"
                    color={theme.palette.neutral[400]}
                  >
                    Incluir os itens na descrição da fatura
                  </Typography>
                }
              />
            </Box>
          </Tooltip>

          <PopoverNewVariant
            setDescription={(message) => setValue('description', message)}
            description={description}
            noChars
          />
        </Stack>
      </Box>

      <OptionsAdditionals
        addFieldDiscount={addFieldDiscount as any}
        control={control}
        setAddFieldDiscount={setAddFieldDiscount as any}
        watch={watch}
        setValue={setValue}
        recurrence={true}
      />

      <Box mt="18px" width="100%">
        <IdentifyInternal
          control={control}
          indetifyInternal={identifyInternal}
          setIdentifyInternal={setIdentifyInternal}
        />
      </Box>
      <Box mt="18px" width="100%">
        <Button
          onClick={handleSubmit(handleClickToSubmit)}
          type="submit"
          variantButton="primaryGreen"
          width="100%"
        >
          Salvar
        </Button>
      </Box>
      <Dialog
        icon="description"
        title={dialog.title}
        description={dialog.description}
        setIsOpenDialog={handleCloseDialog}
        isOpenDialog={dialog.isOpen}
        cancelButton
        cancelButtonLabel="NÃO"
      >
        <Button
          autoFocus
          disabled={loading}
          hasFocus
          onClick={() => handleSubmitForm(dialog.formData)}
          width="100%"
        >
          SIM
        </Button>
      </Dialog>
    </Box>
  )
}

export default RenderEditChargeModal
