import React from 'react'

import { Box, Grid, Stack, Typography, useMediaQuery } from '@mui/material'
import moment from 'moment'
import { parseCookies } from 'nookies'

import { theme } from 'styles/theme'

import ActivateButton from './components/ActivateButton'
import ProgressBar from './components/Progress'
import * as S from './styles'

export type QuotaParams =
  | {
      stage: string
      free_tier: boolean
      expiresIn: string
      billing: {
        max: number
        actual: number
      }
      nfse: {
        max: number
        actual: number
      }
    }
  | undefined
interface TopBarGlobalInfoParams {
  quota: QuotaParams
}
const TopBarGlobalInfo = ({ quota }: TopBarGlobalInfoParams) => {
  const { ['nextauth.status']: status } = parseCookies()
  const isMobile = useMediaQuery('(max-width: 770px)', { noSsr: false })

  return (
    <S.TopBarGlobalInfo container>
      {/* rounded corners */}
      <Box
        sx={{
          backgroundColor: '#294266',
          width: '20px',
          height: '20px',
          bottom: '-20px',
          position: 'absolute'
        }}
      ></Box>
      <Box
        sx={{
          content: '""',
          position: 'absolute',

          width: '20px',
          height: '20px',
          backgroundColor: '#f2f5f2',
          clipPath: 'circle(90.4% at 90% 90%)',
          bottom: '-20px'
        }}
      ></Box>
      <Box
        sx={{
          backgroundColor: '#294266',
          width: '20px',
          height: '20px',
          right: 0,
          bottom: '-20px',
          position: 'absolute'
        }}
      ></Box>
      <Box
        sx={{
          content: '""',
          position: 'absolute',

          width: '20px',
          height: '20px',
          right: 0,
          backgroundColor: isMobile ? '#f2f5f2' : '#fcfcfc',
          clipPath: 'circle(90.4% at 10% 90%)',
          bottom: '-20px'
        }}
      ></Box>
      {/* rounded corners */}

      <Grid
        display="flex"
        justifyContent="start"
        md={3.6}
        sm={3}
        xs={4.5}
        item
        padding="0px 35px"
        sx={{
          [theme.breakpoints.down(960)]: {
            justifyContent: 'center',
            paddingRight: '8px',
            padding: '0px 0px',
            paddingLeft: '12px'
          }
        }}
      >
        {quota?.free_tier && (
          <Typography
            sx={{
              [theme.breakpoints.down(1250)]: {
                fontSize: '12px'
              }
            }}
            color="#ffff"
            variant="mdLight"
          >
            10 cobranças e 10 NFS-e grátis - até{' '}
            {moment(quota?.expiresIn).format('DD/MM/YYYY')}
          </Typography>
        )}
      </Grid>

      <Grid
        display="flex"
        justifyContent="center"
        md={5.7}
        sm={6}
        xs={7.5}
        item
        gap="30px"
        flexWrap={'wrap'}
        padding="0px 35px"
        sx={{
          [theme.breakpoints.down(960)]: {
            padding: '0px 30px'
          }
        }}
      >
        {quota?.free_tier && (
          <Stack
            direction="row"
            gap="20px"
            sx={{
              [theme.breakpoints.down(960)]: {
                padding: '0px 0px',
                paddingRight: '12px',
                gap: '10px'
              }
            }}
          >
            {quota?.billing && (
              <ProgressBar label="Cobranças" value={quota?.billing} />
            )}
            {quota?.nfse && <ProgressBar label="NFs-e" value={quota?.nfse} />}
          </Stack>
        )}
      </Grid>
      <Grid
        display="flex"
        justifyContent="end"
        item
        md={2.7}
        sm={3}
        xs={12}
        padding="0px 35px"
        sx={{
          [theme.breakpoints.down(960)]: {
            justifyContent: 'center',
            padding: '0px 0px'
          }
        }}
        flexWrap={'wrap'}
        gap={'8px'}
        alignItems="center"
      >
        {status === 'trial' && <ActivateButton />}
      </Grid>
    </S.TopBarGlobalInfo>
  )
}

export default TopBarGlobalInfo
