import { Box, Paper } from '@mui/material'
import { styled } from '@mui/system'

import { theme } from 'styles/theme'

export const Fieldset = styled('fieldset')(() => ({
  padding: 0,
  margin: 0,
  border: 0
}))

export const Legend = styled('legend')(() => ({
  marginBottom: 9
}))

export const CheckboxesWrapper = styled(Box)(() => ({
  margin: 0,
  background: '#FFFFFF',
  border: '1px solid #D7D7D7',
  borderRadius: 8,
  overflow: 'hidden',
  position: 'relative'
}))

type LabelProps = {
  inputIsChecked?: boolean
}

const labelModifiers = {
  actived: {
    color: '#1e1e1e',
    background: theme.palette.primary.main,

    '& path': {
      fill: '#1e1e1e'
    },

    '&:hover': {
      background: theme.palette.primary[800]
    }
  },
  disabled: {
    color: '#AAA',
    background: '#FAFAFA',

    '& path': {
      fill: '#AAA'
    }
  }
}

export const Label = styled('label')<LabelProps>(({ inputIsChecked }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 21,
  transition: 'background 200ms',

  padding: '12px 15px',

  font: 'normal 600 14px Inter, "sans-serif"',

  '&:hover': {
    background: '#EAEAEA'
  },

  '&': {
    ...(inputIsChecked && labelModifiers.actived)
  }
}))

export const CheckboxWrapper = styled('div')(() => ({
  position: 'relative',
  '&[data-pixIsActivated="true"]:first-of-type': {
    boxShadow: `0px 0px 19px -2px ${theme.palette.primary.main}`
  },

  '&:first-of-type label': {
    borderRadius: '8px 8px 0 0'
  },

  '&:last-of-type label': {
    borderRadius: '0 0 8px 8px'
  },

  '&:not(:last-of-type)': {
    borderBottom: '1px solid #D7D7D7'
  },

  '& input:not(:disabled) ~ label': {
    cursor: 'pointer'
  },

  '& input': {
    all: 'unset'
  },

  '& input[data-actived="false"]:focus ~ label': {
    background: '#56BB4D20'
  },

  '& input[data-actived="true"]:focus ~ label': {
    background: theme.palette.primary.main
  },

  '& input:disabled ~ label': {
    ...labelModifiers.disabled
  }
}))

export const ErrorLabel = styled('div')(() => ({
  background: '#ffebeb',
  padding: 14,
  borderRadius: 8,
  marginTop: 4,
  font: 'normal 400 14px Inter, sans-serif'
}))

export const Badge = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '2px 9px',
  background: theme.palette.secondary.main,
  borderRadius: 100,

  color: '#fff',
  font: 'normal 600 12px Inter, sans-serif'
}))

export const PopperWrapper = styled(Paper)(() => ({
  padding: '14px 10px',
  background: '#525252',
  color: '#FFFFFF',
  maxWidth: 286,
  position: 'relative',
  marginBottom: 18,

  '&::after': {
    content: '""',
    position: 'absolute',
    left: '50%',
    bottom: 0,
    transform: 'translate(-80%, 13%) rotate(-135deg)',
    borderRight: '1.5rem solid transparent',
    borderLeft: '1.5rem solid #525252',
    borderBottom: '1.5rem solid transparent'
  }
}))

export const PopperTitle = styled('h3')(() => ({
  font: 'normal 700 16px Inter, sans-serif',
  margin: 0,
  paddingBottom: 10
}))

export const PopperDescription = styled('p')(() => ({
  font: 'normal 400 14px Inter, sans-serif',
  margin: 0,
  maxWidth: '31ch'
}))

export const HeadingWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%'
}))
export const PopperCloseButtonWrapper = styled('div')(() => ({
  position: 'absolute',
  top: 9,
  right: 9
}))

export const SimulationListHeading = styled('h3')(() => ({
  font: 'normal 600 15px Inter, sans-serif',
  margin: 0,
  paddingBottom: 10
}))

export const SimulationListWrapper = styled('ul')(() => ({
  listStyle: 'none',
  margin: 0,
  padding: 0,
  maxHeight: 400,

  columnCount: 2,

  '& li': {
    font: 'normal 500 14px Inter, sans-serif',
    color: '#414241',
    width: '100%'
  },

  '& * + *': {
    marginTop: 4
  },

  '@media (max-width: 736px)': {
    columnCount: 1
  }
}))
