import React, { useEffect, useState } from 'react'

import {
  Button,
  Dialog,
  Icon,
  Loader,
  Modal
} from '@clientbase/clientbase-library'
import { Skeleton, Tooltip, Typography } from '@mui/material'
import { balanceDetails } from 'api/requests'
import { BalanceDetailItem } from 'models/BalanceDetails'
import { ReceivablesParams } from 'models/Receivables'

import Can from 'components/Can'
import DialogReport from 'components/Dialog/DialogReport'
import { Table } from 'components/Table'
import Tabs from 'components/Tabs'

import { formatMoney } from 'utils'
import { useExportReport } from 'utils/filters/export-report'
import { ReceivablesTabs } from 'utils/tabs'

import ModalChargeDetail from '../ModalChargeDetail'
import ModalReport from '../ModalReport'
import CalendarReceivables from './components/CalendarReceivables/CalendarReceivables'
import * as S from './ModalBalance.styles'
import { convertToRows } from './ModalBalance.utils'
export type Record = {
  receivable?: ReceivablesParams
}

export type ModalBalanceProps = {
  isOpen: boolean
  handleClose: () => void
  balance: number | false
  balanceScheduled: number | false
}

const tablePropsInitialValues = {
  page: 0,
  rowsPerPage: 25,
  totalCount: 0
}

type BalanceDetails = Promise<{
  records: BalanceDetailItem[]
  automatic_transfer: boolean
}>

const TABS = [
  { label: 'Saldo', value: ReceivablesTabs.RECEIVABLES },
  {
    label: 'Saldo a liberar',
    value: ReceivablesTabs.CALENDAR_RECEIVABLES
  }
]

export const ModalBalance = ({
  isOpen,
  handleClose,
  balance,
  balanceScheduled
}: ModalBalanceProps) => {
  const [tab, setTab] = useState<string>(ReceivablesTabs.RECEIVABLES)

  const [openDialogReport, setOpenDialogReport] = useState<boolean>(false)
  const [dialogRedirectReport, setDialogRedirectReport] = useState(false)

  const [tableFetchLoading, setTableFetchLoading] = useState(false)
  const [records, setRecords] = useState<BalanceDetailItem[] | null>(null)
  const [tableProps, setTableProps] = useState(tablePropsInitialValues)

  const [modal, setModal] = useState({
    isOpenDetailInvoice: false,
    uuid: ''
  })

  const handleChangeTab = (_: React.SyntheticEvent, tab: any) => {
    setTab(tab as string)
  }

  const setIsOpenDialog = (isOpenDialog: boolean) => {
    setDialog((s) => ({ ...s, isOpenDialog }))
  }

  const handleCloseDialog = () => {
    setDialog((s) => ({
      ...s,
      isOpenDialog: false
    }))
  }

  const { handleExportReport, loading, handleRequestWithdrawal } =
    useExportReport({
      functionToCallAfterSubmitReport: () => {
        setOpenDialogReport(true)
      },
      functionToCallAfterSubmitTransfer: (message: string) => {
        setDialog((prevState) => ({
          ...prevState,
          description: '',
          isOpenDialog: false,
          title: ''
        }))
        if (!message.includes('Não foi possível solicitar a transferência')) {
          handleClose()
        }
      },
      model: 'Receivable',
      setIsOpenDialog
    })

  const [dialog, setDialog] = useState<{
    action: () => Promise<void> | null
    description: string
    handleClose: () => void | null
    isOpenDialog: boolean
    title: string
  }>({
    action: handleExportReport,
    description: '',
    handleClose: handleCloseDialog,
    isOpenDialog: false,
    title: ''
  })

  const handleCloseModalBalance = () => {
    handleClose()
    setTableProps((s) => ({ ...s, page: 0, rowsPerPage: 25 }))
  }

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    setTableProps((s) => ({ ...s, page }))
  }

  const handleChangeRowsPerPage = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const rowsPerPage = Number(e.target.value || 25)
    setTableProps((s) => ({ ...s, rowsPerPage }))
  }

  const handleOpenDetailInvoice = (uuid: string) => {
    setModal((prevState) => ({
      ...prevState,
      isOpenDetailInvoice: true,
      uuid
    }))
  }

  const handleCloseDetailInvoice = () => {
    setModal((prevState) => ({
      ...prevState,
      isOpenDetailInvoice: false,
      uuid: ''
    }))
  }

  const handleOpenDialog = ({
    action,
    title,
    description
  }: {
    action: () => Promise<void>
    title: string
    description: string
  }) => {
    setDialog((s) => ({
      ...s,
      action,
      description,
      isOpenDialog: true,
      title
    }))
  }

  useEffect(() => {
    if (!isOpen) return

    const getData = async () => {
      setTableFetchLoading(true)
      const response = await balanceDetails<BalanceDetails>()

      const data = await response?.data

      setRecords(data.records)
      setTableProps((prevState) => ({
        ...prevState,
        totalCount: Number(data.records.length)
      }))
      setTableFetchLoading(false)
    }

    getData()
  }, [isOpen])

  if (
    records === null &&
    isOpen === true &&
    tab === ReceivablesTabs.RECEIVABLES
  ) {
    return (
      <S.Overlay>
        <Loader />
      </S.Overlay>
    )
  }

  return (
    <>
      <Modal
        aria-hidden={!isOpen}
        handleClose={handleCloseModalBalance}
        open={isOpen}
        title={<Tabs onChange={handleChangeTab} tabs={TABS} value={tab} />}
        size="large"
        width="100%"
        maxWidth="80vw"
        height="auto"
      >
        {tab === ReceivablesTabs.CALENDAR_RECEIVABLES && (
          <CalendarReceivables balanceScheduled={balanceScheduled} />
        )}
        {tab === ReceivablesTabs.RECEIVABLES && (
          <>
            <S.Header>
              <S.ContainerDetailsBalance>
                <S.Heading variant="lgLight">
                  <Icon icon="accountBalance" />
                  Detalhes do saldo
                </S.Heading>
                <Typography variant="mdBold">
                  Saldo: {formatMoney(Number(balance))}
                </Typography>
              </S.ContainerDetailsBalance>

              {records !== null && !!records.length && (
                <Can
                  roles={['admin', 'financial_operator', 'operator', 'user']}
                >
                  <Tooltip
                    arrow
                    placement="left"
                    title="Seu relatório será exportado integralmente a não ser que você selecione um período ou filtro ao lado."
                  >
                    <S.ReportButton
                      aria-label="Exportar relatório"
                      disabled={loading}
                      onClick={() =>
                        handleOpenDialog({
                          action: handleExportReport,
                          description: 'Confirma exportar o relatório?',
                          title: 'Relatório'
                        })
                      }
                    >
                      Exportar Relatório
                    </S.ReportButton>
                  </Tooltip>
                </Can>
              )}

              {records !== null && !!records.length && (
                <Can
                  roles={['admin', 'financial_operator', 'operator', 'user']}
                  status={['trial', 'checking']}
                >
                  <S.PlunderButton
                    aria-label="Transferir"
                    disabled={loading}
                    onClick={() =>
                      handleOpenDialog({
                        action: handleRequestWithdrawal,
                        description: 'Confirmar solicitar transferência?',
                        title: 'Saque'
                      })
                    }
                  >
                    Transferir
                  </S.PlunderButton>
                </Can>
              )}
            </S.Header>

            {records !== null && !!records.length && tableFetchLoading ? (
              <Skeleton height="500px" variant="rectangular" width="100%" />
            ) : (
              <S.TableWrapper>
                <Table
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  header={[
                    { id: 'type', label: 'Tipo' },
                    { id: 'description', label: 'Descrição' },
                    { id: 'dueDate', label: 'Data de Vencimento' },
                    { id: 'paidDate', label: 'Data Pagamento' },
                    { id: 'amountPaid', label: 'Valor Pago' },
                    { id: 'fee', label: 'Tarifa bancária' },
                    { id: 'amountLiquid', label: 'Valor Líquido' },
                    { id: 'paymentType', label: 'Método de Pagamento' },
                    { id: 'actions', label: '' }
                  ]}
                  rows={convertToRows({ records, handleOpenDetailInvoice })}
                  rowAction={handleOpenDetailInvoice}
                  select={{ useSelect: false }}
                  hiddePagination
                  {...tableProps}
                />
              </S.TableWrapper>
            )}
          </>
        )}
      </Modal>

      <Dialog
        icon="description"
        title={dialog.title}
        description={dialog.description}
        setIsOpenDialog={dialog.handleClose}
        isOpenDialog={dialog.isOpenDialog}
        cancelButton
        cancelButtonLabel="NÃO"
      >
        <Button
          disabled={loading}
          onClick={() => dialog.action()}
          fullWidth
          loading={loading}
        >
          SIM
        </Button>
      </Dialog>

      <DialogReport
        setDialogRedirectReport={setDialogRedirectReport}
        isOpenDialog={openDialogReport}
        loading={loading}
        handleOpenReportCloseDialog={() => {
          setDialogRedirectReport(true)
          setOpenDialogReport(false)
        }}
      />
      <ModalChargeDetail
        handleCloseChargeDetail={handleCloseDetailInvoice}
        isOpenChargeDetail={modal.isOpenDetailInvoice}
        uuid={modal.uuid}
        disablePortal={false}
      />
      {dialogRedirectReport && (
        <ModalReport
          handleCloseReportModal={() => setDialogRedirectReport(false)}
          isOpenModalReport={dialogRedirectReport}
        />
      )}
    </>
  )
}
