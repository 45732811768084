import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import {
  Autocomplete,
  Button,
  Icon,
  Select,
  Switch,
  Textarea,
  Textfield
} from '@clientbase/clientbase-library'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material'
import { Box, useMediaQuery } from '@mui/system'
import { DataService } from 'api/DataService'
import { useFetch } from 'hooks/use-fetch'
import { useSelectRowTable } from 'hooks/use-select-row-table'
import { NFeParams } from 'models/NFe'
import { Bank } from 'models/Overview/Transfer'
import {
  constructorAutoCompleteProvider,
  constructorAutoCompleteServices
} from 'models/ServiceProvider'
import DialogBlockNewInvoice from 'templates/Invoice/components/Dialog/DialogBlockNewInvoice'

import AutocompleteCustomers from 'components/AutocompleteCustomers'
import Dialog from 'components/v2/Dialog'

import { currency } from 'utils'
import { toastProps } from 'utils/types/toast-props'

import { theme } from 'styles/theme'

import ChargeTable from './components/Table/Table'
import {
  ModalInvoiceFieldsSchema,
  SchemaModalInvoice
} from './ModalInvoice.Schema'
import { enumNature, FilterOptions, NatureOption } from './ModalInvoice.utils'
import * as S from './styles'

interface InvoiceSettingsContentProps {
  customer?: {
    uuid: string
    label: string
  }
  isEdit?: boolean
  invoice?: NFeParams
  handleToggle: () => void
  handleReloadTable?: () => void
  openInvoiceSettings?: () => void
}

const defaultValues = {
  nature_operation: '1',
  iss_retention: 'no',
  nfse_issuer_uuid: ''
}

const InvoiceSettingsContent = ({
  isEdit = false,
  invoice,
  handleToggle,
  handleReloadTable,
  openInvoiceSettings,
  customer
}: InvoiceSettingsContentProps) => {
  const [filter, setFilter] = useState('open_payment')
  const amount = invoice?.nfse_item?.amount || '0'
  const [isOpenNoIssuersDialog, setIsOpenNoIssuersDialog] = useState(false)
  const match = useMediaQuery('(max-width: 958px)', { noSsr: false })

  const [modals, setModals] = useState<{
    open: boolean
    dataForm: ModalInvoiceFieldsSchema | null
    loading: boolean
  }>({ open: false, dataForm: null, loading: false })

  const { control, watch, setValue, handleSubmit } =
    useForm<ModalInvoiceFieldsSchema>({
      defaultValues: !invoice
        ? {
            ...defaultValues,
            customer_uuid: {
              label: customer?.label,
              value: customer?.uuid
            }
          }
        : {
            iss_retention: invoice?.nfse_item?.iss_retation ? 'yes' : 'no',
            service_list_code: {
              name: invoice?.nfse_item?.nfse_issuer_service?.description,
              value: invoice?.nfse_item?.nfse_issuer_service?.uuid
            },
            amount: parseFloat(amount).toFixed(2),
            description: invoice?.nfse_item?.description,
            nfse_issuer_uuid: invoice?.nfse_issuer_uuid,
            customer_uuid: {
              label: invoice?.customer?.name,
              value: invoice?.customer?.uuid
            },
            nature_operation: enumNature[invoice?.nfse_item?.nature_operation]
          },
      resolver: yupResolver(SchemaModalInvoice)
    })

  const { select, setSelect } = useSelectRowTable({
    useSelected: true
  })

  const [, resultNfse] = useFetch({
    method: 'GET',
    shouldRun: true,
    url: '/v1/nfse_issuers/autocomplete'
  })

  const [fetchServices, resultServices] = useFetch({
    method: 'GET',
    shouldRun: false,
    url: `/v1/nfse_issuers`
  })

  const handleOpen = (dataForm: ModalInvoiceFieldsSchema) => {
    setModals(() => ({ dataForm: dataForm, open: true, loading: false }))
  }

  const handleCancel = () => {
    setModals(() => ({ dataForm: null, open: false, loading: false }))
  }

  const handleCloseBlockNewInvoice = () => {
    setIsOpenNoIssuersDialog(false)
    handleToggle()
    !!openInvoiceSettings && openInvoiceSettings()
  }

  const onSubmit = async (
    dataForm: ModalInvoiceFieldsSchema
  ): Promise<void> => {
    const id = toast.loading(
      isEdit ? 'Reemitindo nota fiscal...' : 'Emitindo nota fiscal...'
    )
    const {
      customer_uuid,
      nfse_issuer_uuid,
      description,
      amount,
      iss_retention,
      nature_operation,
      service_list_code
    } = dataForm

    const value = currency(amount.toString()).replace('.', '').replace(',', '.')

    const data = {
      customer_uuid: customer_uuid.value,
      nfse_issuer_uuid,
      description,
      amount: Number(value),
      iss_retention: iss_retention === 'yes' ? true : false,
      nfse_issuer_service_uuid: service_list_code.value,
      nature_operation: Number(nature_operation),
      uuids_billing: ''
    }

    if (select.selected.length > 0 && dataForm?.link_billing) {
      const uuids = select.selected
      let uuids_billing = ''
      uuids.map((value) => (uuids_billing += `${value};`))

      data.uuids_billing = uuids_billing
    }

    const response = await DataService({
      type: isEdit ? 'PATCH' : 'POST',
      data,
      url: isEdit ? `/v1/nfses/${invoice?.uuid}/reissue` : '/v1/nfses/issue'
    })

    const errorMessage =
      response.error && (response.detail_error || response.message)

    toast.update(id, {
      render: response.error ? errorMessage : response.data.message,
      type: response.error ? 'error' : 'success',
      ...toastProps
    })

    if (response.status === 200) {
      handleToggle()
      !!handleReloadTable && handleReloadTable()
    }

    handleCancel()
  }

  useEffect(() => {
    if (
      typeof watch('nfse_issuer_uuid') === 'string' &&
      watch('nfse_issuer_uuid') !== ''
    ) {
      fetchServices({ paramUrl: `${watch('nfse_issuer_uuid')}/services` })
    }
  }, [watch('nfse_issuer_uuid')])

  useEffect(() => {
    if (resultNfse?.data) {
      setIsOpenNoIssuersDialog(resultNfse.data.length <= 0)
    }
  }, [resultNfse])

  if (isOpenNoIssuersDialog) {
    return (
      <DialogBlockNewInvoice
        open={isOpenNoIssuersDialog}
        closeDialog={handleCloseBlockNewInvoice}
      />
    )
  }

  return (
    <S.Wrapper>
      <S.Header>
        <Box bgcolor="#F8F8F8" p={1.3} borderRadius="12px">
          <Icon
            icon="attachMoney"
            colorSX={theme.palette.primary.main}
            width="48px"
            height="48px"
          />
        </Box>
        <Typography fontWeight={'bold'} fontSize={20}>
          {!isEdit ? 'Nova Nota' : 'Confirme os dados da NF antes de emitir:'}
        </Typography>
      </S.Header>

      <S.ItemsWrapper sx={{ maxWidth: '100%', width: '100%' }}>
        <S.FieldWrapper sx={{ maxWidth: '100%', width: '100%' }}>
          <S.Legend>Prestador de serviços</S.Legend>
          <S.Heading>Prestador de serviços</S.Heading>

          <S.Content>
            <Controller
              name="nfse_issuer_uuid"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error }
              }) => (
                <Select
                  helperText={error?.message}
                  error={!!error?.message}
                  displayEmpty
                  fullWidth
                  onChange={onChange}
                  value={value}
                  sx={{ width: match ? `${window.innerWidth - 90}px` : '100%' }}
                >
                  <MenuItem disabled value="" color="#C5C5C5">
                    <Typography
                      color="#C5C5C5"
                      fontWeight={300}
                      textOverflow="ellipsis"
                    >
                      Selecione o prestador
                    </Typography>
                  </MenuItem>
                  {resultNfse &&
                    constructorAutoCompleteProvider(resultNfse.data).map(
                      ({ legal_name, uuid }) => (
                        <MenuItem
                          key={uuid}
                          value={uuid}
                          sx={{ width: '100%', whiteSpace: 'break-spaces' }}
                        >
                          {legal_name}
                        </MenuItem>
                      )
                    )}
                </Select>
              )}
            />
          </S.Content>
        </S.FieldWrapper>
      </S.ItemsWrapper>

      <S.ItemsWrapper>
        <S.FieldWrapper>
          <S.Legend>Cliente</S.Legend>
          <S.Heading>Cliente</S.Heading>

          <S.Content>
            <S.ItemsWrapper>
              <Box>
                <Controller
                  control={control}
                  name="customer_uuid"
                  render={({
                    field: { onChange, value },
                    fieldState: { error }
                  }) => {
                    const errorNotTyped = error as any

                    return (
                      <AutocompleteCustomers
                        disabled={!!customer?.uuid}
                        onChange={(_: React.SyntheticEvent, value: unknown) => {
                          setValue('link_billing', false)
                          setSelect((prevState) => ({
                            ...prevState,
                            selected: []
                          }))
                          onChange(value ?? { value: '', label: '' })
                        }}
                        value={customer?.uuid ? customer : value}
                        errorMessage={errorNotTyped?.label?.message}
                        placeholder="Digite aqui o cliente"
                      />
                    )
                  }}
                />
              </Box>
            </S.ItemsWrapper>
          </S.Content>
        </S.FieldWrapper>

        <S.FieldWrapper>
          <S.Legend>Valor da nota</S.Legend>
          <S.Heading>Valor da nota</S.Heading>

          <S.Content>
            <Controller
              control={control}
              name="amount"
              render={({
                field: { onChange, value },
                fieldState: { error }
              }) => (
                <Textfield
                  customStartAdornment={
                    <Typography mr="6px" variant="mdLight">
                      R$
                    </Typography>
                  }
                  placeholder="Digite aqui o valor da nota"
                  helperText={error?.message}
                  error={!!error?.message}
                  mask="money"
                  onChange={onChange}
                  startAdornment={true}
                  value={value}
                />
              )}
            />
          </S.Content>
        </S.FieldWrapper>
      </S.ItemsWrapper>

      <S.ItemsWrapper minWidth={400}>
        <S.FieldWrapper>
          <S.Legend>Natureza da operação</S.Legend>
          <S.Heading>Natureza da operação</S.Heading>
          <S.Content>
            <Controller
              name="nature_operation"
              control={control}
              render={({ field: { onChange, value } }) => (
                <RadioGroup
                  value={value}
                  onChange={onChange}
                  sx={{
                    flexWrap: 'nowrap',
                    display: 'flex'
                  }}
                >
                  <S.ItemsWrapper minWidth={170}>
                    {NatureOption.map(({ label, value }, index) => (
                      <FormControlLabel
                        key={index}
                        value={value}
                        control={<Radio />}
                        label={label}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                      />
                    ))}
                  </S.ItemsWrapper>
                </RadioGroup>
              )}
            />
          </S.Content>
        </S.FieldWrapper>
      </S.ItemsWrapper>

      <S.Grid>
        <S.FieldWrapper>
          <S.Legend>Item de serviço</S.Legend>
          <S.Heading>Item de serviço</S.Heading>
          <S.Content>
            <Controller
              name="service_list_code"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error }
              }) => {
                const errorNotTyped = error as any
                return (
                  <Autocomplete
                    onChange={(_, value) => onChange(value)}
                    value={value}
                    options={
                      resultServices
                        ? constructorAutoCompleteServices(
                            resultServices.data
                          ).map(({ description, uuid, code }) => ({
                            name: `${code} - ${description}`,
                            value: uuid
                          }))
                        : []
                    }
                    autoHighlight
                    getOptionLabel={(option) => (option as Bank).name}
                    isOptionEqualToValue={(option, value) =>
                      (option as Bank).uuid === (value as Bank).uuid
                    }
                    renderOption={(props, option) => {
                      const optionTyped = option as {
                        name: string
                        value: string
                      }
                      return (
                        <Box component="li" {...props} key={optionTyped.value}>
                          {optionTyped.name}
                        </Box>
                      )
                    }}
                    disabled={!watch('nfse_issuer_uuid')}
                    renderInput={(params) => (
                      <Textfield
                        helperText={errorNotTyped?.name?.message}
                        error={!!errorNotTyped?.name?.message}
                        placeholder="Digite aqui o item de serviço"
                        {...params}
                      />
                    )}
                  />
                )
              }}
            />
          </S.Content>
        </S.FieldWrapper>

        <S.FieldWrapper>
          <S.Legend>ISS retido na fonte</S.Legend>
          <S.Heading>ISS retido na fonte</S.Heading>

          <S.Content>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Controller
                name="iss_retention"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <RadioGroup value={value} onChange={onChange} row>
                    <Box display="flex" gap={4.5}>
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Sim"
                        sx={{
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="Não"
                        sx={{
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                      />
                    </Box>
                  </RadioGroup>
                )}
              />
            </Box>
          </S.Content>
        </S.FieldWrapper>
      </S.Grid>

      <S.FieldWrapper>
        <S.Legend>Descrição dos serviços</S.Legend>
        <S.Heading>Descrição dos serviços</S.Heading>
        <S.Content>
          <Controller
            name="description"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <>
                  <Textarea
                    style={{
                      height: '180px',
                      width: '97%',
                      overflow: 'auto'
                    }}
                    value={value}
                    onChange={onChange}
                    error={!!error?.message}
                    placeholder="Digite aqui a descrição"
                  />
                  <Typography
                    mt={'-30px'}
                    ml="2px"
                    fontSize="0.80rem"
                    fontWeight="600"
                    color="#CC2942"
                  >
                    {error?.message}
                  </Typography>
                </>
              )
            }}
          />
        </S.Content>
      </S.FieldWrapper>

      <S.FieldWrapper>
        <S.Legend>Vincular cobranças</S.Legend>
        <S.Heading>
          <Box display="flex" alignItems="center" justifyContent="center">
            Vincular cobranças
            <Controller
              name="link_billing"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Switch
                  value={value}
                  checked={value}
                  onChange={onChange}
                  disabled={!watch('customer_uuid')}
                />
              )}
            />
          </Box>
        </S.Heading>
        {watch('link_billing') && (
          <S.Content>
            <Box display="flex" justifyContent="center" gap={4.5}>
              <RadioGroup
                value={filter}
                onChange={(_, value) => {
                  setSelect((prevState) => ({ ...prevState, selected: [] }))
                  setFilter(value)
                }}
                row
              >
                <Box display="flex" gap={4.5}>
                  {FilterOptions.map(({ label, value }, index) => (
                    <FormControlLabel
                      value={value}
                      control={<Radio />}
                      label={label}
                      key={index}
                      checked={value === filter}
                    />
                  ))}
                </Box>
              </RadioGroup>
            </Box>

            <Box>
              <ChargeTable
                baseFilters={{
                  customer_uuid: watch('customer_uuid').value,
                  status: filter
                }}
                select={{
                  useSelect: select.useSelect,
                  selected: select.selected,
                  setSelected: setSelect
                }}
              />
            </Box>
          </S.Content>
        )}
      </S.FieldWrapper>

      <Button
        fullWidth
        variantButton="primaryGreen"
        onClick={handleSubmit(handleOpen)}
      >
        {isEdit ? 'Reemitir' : 'Emitir'} nota
      </Button>

      <Dialog
        icon="info"
        iconColor={theme.palette.primary.main}
        title={`${isEdit ? 'Reemitir' : 'Emitir'} nota fiscal`}
        description={`Confirmar ${
          isEdit ? 'reemissão' : 'emissão'
        } da nota fiscal?`}
        setIsOpenDialog={handleCancel}
        isOpenDialog={modals.open}
      >
        <Box display="flex" alignItems="center" gap={1} width="100%">
          <Button variantButton="contained" onClick={handleCancel} fullWidth>
            Não
          </Button>
          <Button
            disabled={modals.loading}
            onClick={() =>
              onSubmit(modals.dataForm as ModalInvoiceFieldsSchema)
            }
            variantButton="contained"
            fullWidth
          >
            Sim
          </Button>
        </Box>
      </Dialog>
    </S.Wrapper>
  )
}

export default InvoiceSettingsContent
