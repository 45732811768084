import React, { useContext, useEffect, useState } from 'react'

import { Icon } from '@clientbase/clientbase-library'
import { Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import { customerChargesList, customerRecentChargesList } from 'api/requests'
import { AuthContext } from 'context/AuthContext'
import { roles } from 'models/User'
import { ParsedUrlQuery } from 'querystring'
import { ToggleModalsParams } from 'templates/v2/Charge/Charge'

import { DynamicTableSkeleton } from 'components/DynamicTable/skeleton'
import EmptyCharge from 'components/Empty/EmptyCharge'
import { Info } from 'components/InfoList/InfoList'
import { Table } from 'components/Table'
import { HeadCell } from 'components/Table/Table.interface'

import browserQueryToQueryString from 'utils/query-string'

import {
  CustomerCharges,
  customerChargesTableMapper,
  statisticsMapper
} from './'

export interface DataCustomerCharges {
  error: boolean
  records: Record[]
  meta: Meta
  statistics: StatisticsMapperParams
}

export type StatisticsMapperParams = {
  total_billed: number
  total_count: number
  total_paid: number
  total_received: number
  total_to_receive: number
}

export type NfseStatus = 'scheduled' | 'issued' | 'not_scheduled'

export interface Record {
  customer: Customer
  due_date: string
  amount_paid: string
  date_paid: string | null
  amount_billed: string
  status: string
  uuid: string
  viewed: boolean
  recurrence_uuid: string
  nfse_status: NfseStatus
  nfse: Nfse
  nfse_policy: string
}

export interface Nfse {
  status: NfseStatus
  uuid: string
}

export interface Customer {
  name: string
  nickname: string
  uuid: string
}

interface Meta {
  current_page: number
  next_page: number
  prev_page: any
  total_pages: number
  total_count: number
}

const tableHeader = [
  { id: 'name', label: 'Cliente' },
  { id: 'dueDate', label: 'Vencimento' },
  { id: 'datePaid', label: 'Pagamento' },
  { id: 'amountBilled', label: 'Valor' },
  { id: 'amountPaid', label: 'Valor Pago' },
  {
    id: 'status',
    label: (
      <Box>
        <Tooltip
          arrow
          title={
            <Box>
              <p>
                <b>Pendente: </b>A cobrança está agendada para emissão.
              </p>
              <p>
                <b>Aberta: </b>A cobrança está em aberto e aguarda pagamento.
              </p>
              <p>
                <b>Em atraso: </b>A cobrança está em aberto e a data de
                vencimento já passou.
              </p>
              <p>
                <b>Pago: </b>A cobrança foi paga.
              </p>
              <p>
                <b>Expirada: </b>A cobrança não foi paga dentro do prazo máximo.
              </p>
              <p>
                <b>Cancelado: </b>A cobrança foi cancelada.
              </p>
            </Box>
          }
        >
          <Box display="flex" gap="4px" maxWidth="80px">
            Status <Icon icon="error" colorSX="#D7D7D7" />
          </Box>
        </Tooltip>
      </Box>
    )
  },
  { id: 'actions', label: '' }
] as HeadCell[]

type ChargeTableProps = {
  toggleModals: (params: ToggleModalsParams) => void
  baseFilters: { [key in string]: string } | ParsedUrlQuery
  setInfoList?: React.Dispatch<React.SetStateAction<Info[]>>
  setBaseFilters: (params: {
    [key in string]: string | number | object
  }) => void
  updateTable?: boolean
  recentCharges?: boolean
  setHasRecentCharges?: React.Dispatch<React.SetStateAction<boolean>>
}

const ChargeTable = ({
  baseFilters,
  setBaseFilters,
  toggleModals,
  setInfoList,
  updateTable,
  recentCharges,
  setHasRecentCharges
}: ChargeTableProps) => {
  const { user } = useContext(AuthContext)
  const AUTHORIZATION = roles[user?.role || 'guest']
  const [loading, setLoading] = useState(true)

  const [customerCharges, setCustomerCharges] = useState({} as CustomerCharges)

  const handleGetCustomerCharges = async () => {
    try {
      setLoading(true)
      setCustomerCharges({} as CustomerCharges)

      let response

      if (recentCharges) {
        response = await customerRecentChargesList<DataCustomerCharges>({
          uuid: baseFilters.id as string
        })

        !!setHasRecentCharges &&
          setHasRecentCharges(response?.data?.records?.length > 0)
      } else {
        response = await customerChargesList<DataCustomerCharges>({
          filter_by: browserQueryToQueryString(
            baseFilters as { [key in string]: string }
          )
        })
      }

      const data: DataCustomerCharges = response.data as DataCustomerCharges

      !!setInfoList && setInfoList(statisticsMapper(data.statistics))
      const actions = { AUTHORIZATION, toggleModals }
      const customerChargeTable = customerChargesTableMapper({ data, actions })

      setCustomerCharges(customerChargeTable)
    } finally {
      setLoading(false)
    }
  }

  const handleChangePage = (page: number) => {
    setBaseFilters({ query: { ...baseFilters, page } })
  }

  const handleChangeRowsPerPage = (per: string | number) => {
    setBaseFilters({ query: { ...baseFilters, per, page: 0 } })
  }

  useEffect(
    () => {
      handleGetCustomerCharges()
    },
    recentCharges ? [updateTable] : [baseFilters, updateTable]
  )

  if (loading) {
    return <DynamicTableSkeleton />
  }

  return !customerCharges?.rows?.length ? (
    <Box pt={5}>
      <EmptyCharge
        handleOpenModal={() => toggleModals({ modal: 'isOpenModalNewCharge' })}
      />
    </Box>
  ) : (
    <Table
      header={tableHeader}
      handleChangePage={(_, page) => handleChangePage(page)}
      handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e.target.value)}
      page={Number(baseFilters?.page) || 0}
      rowsPerPage={Number(baseFilters?.per) || 25}
      rowAction={(uuid) =>
        toggleModals({ modal: 'isOpenModalChargeDetail', uuid })
      }
      hiddePagination={recentCharges}
      hoverInRow
      disableFilter
      select={{ useSelect: false }}
      {...customerCharges}
    />
  )
}

export default ChargeTable
