import { memo, useEffect, useState } from 'react'

import { Icon } from '@clientbase/clientbase-library'
import { Drawer, IconButton } from '@mui/material'
import { DataService } from 'api/DataService'

import Empty from 'components/Empty'

import * as S from './styles'
import UpdateItem, { UpdateRecords } from './UpdateItem'
import UpdateItemsSkeleton from './UpdateItemSkeleton'

type Status = 'idle' | 'pending' | 'sucess' | 'error'

type UpdateList = {
  records: UpdateRecords[]
  show_notification: boolean
}
export type IsExpandedProps = {
  isExpandedText: boolean
  uuid: string
}

const UpdatesRoot = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [updateList, setUpdateList] = useState<UpdateList>()
  const [status, setStatus] = useState<Status>('idle')
  const [isExpandedTextState, setIsExpandedTextState] = useState<{
    [key: string]: IsExpandedProps
  }>({})
  const isLoading = status === 'idle' || status === 'pending'

  const expandText = (itemUuid: string) => {
    setIsExpandedTextState((prevState) => ({
      ...prevState,
      [itemUuid]: {
        isExpandedText: !isExpandedTextState[itemUuid]?.isExpandedText,
        uuid: itemUuid
      }
    }))
  }
  const setRead = async () => {
    await DataService({
      type: 'POST',
      url: `v2/product_updates/set_read`
    })
  }

  const getUpdatesList = async () => {
    setStatus('pending')

    const url = '/v2/product_updates'
    const response = await DataService({ url, type: 'GET' })
    if (response.status < 400) {
      setStatus('sucess')
      setUpdateList(response.data)
    }
    if (response.status >= 400) {
      setStatus('error')
    }
  }

  const handleToggleDrawer = () => setIsOpen(!isOpen)

  useEffect(() => {
    getUpdatesList()
  }, [])

  useEffect(() => {
    if (updateList?.show_notification && isOpen) {
      setRead()
    }
  }, [isOpen])

  return (
    <S.Wrapper>
      <IconButton onMouseDown={handleToggleDrawer}>
        <Icon height="21px" width="21px" icon="faBell" />
        {updateList?.show_notification && <S.Status />}
      </IconButton>

      <Drawer
        open={isOpen}
        onClose={() => handleToggleDrawer()}
        anchor="right"
        onBackdropClick={handleToggleDrawer}
      >
        <S.Header>
          <div>
            <S.Heading>Novidades</S.Heading>
            <S.Subtitle>Acompanhe as últimas novidades do Base</S.Subtitle>
          </div>
          <IconButton>
            <Icon icon="close" onMouseDown={handleToggleDrawer} />
          </IconButton>
        </S.Header>
        <S.Content>
          {isLoading && <UpdateItemsSkeleton />}
          {status === 'sucess' &&
            updateList?.records.map((item) => (
              <UpdateItem
                isExpanded={isExpandedTextState}
                expandText={expandText}
                notViewed={
                  updateList.show_notification &&
                  item.created_at === updateList.records[0].created_at
                }
                key={item.uuid}
                item={item}
              />
            ))}
          {status === 'error' && (
            <Empty description="Não foi encontrada nenhuma novidade nova no nosso sistema, notificaremos assim que alguma feature for lançada" />
          )}
        </S.Content>
      </Drawer>
    </S.Wrapper>
  )
}
export default memo(UpdatesRoot)
