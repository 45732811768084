import React, { useEffect, useState } from 'react'

import {
  Autocomplete,
  Dialog,
  Icon,
  Icons,
  Loader,
  Textfield
} from '@clientbase/clientbase-library'
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { DataService } from 'api/DataService'
import { constructorProducts, Products } from 'models/Plans'
import Image from 'next/image'
import FormProducts from 'templates/v2/Products/Form/FormProducts'

import Can from 'components/Can'
import { GradientOpenWithIcon } from 'components/GradientIcon/GradientIcon'

import { currency } from 'utils'

type Record = {
  label: string
  value: string
  product: ProductAutocomplete
}

export type ProductAutocomplete = {
  amount: string
  due_day?: string
  document_file?: {
    thumbnail_url: string
  }
  icon?: string
  name: string
  total_cycles?: number
  uuid: string
}

type AutocompleteProductsProps = {
  onChange: any
  value: any
  outputType?: 'default' | 'card'
  product?: Products
  handleRemoveProduct?: any
  refetchAt?: any
  errorMessage?: any
  multiple?: boolean
  placeholder?: string
  disabled?: boolean
  label?: string
  filterByAmount?: number
}

const AutocompleteProducts = ({
  onChange,
  value,
  errorMessage,
  placeholder,
  label,
  product,
  refetchAt,
  handleRemoveProduct,
  outputType = 'default',
  disabled = false,
  multiple = false,
  filterByAmount
}: AutocompleteProductsProps) => {
  const [products, setProducts] = useState<Record[]>([])
  const [currentProduct, setCurrentProduct] = useState<ProductAutocomplete>()
  const [productToBeEdited, setProductToBeEdited] = useState<Products>()
  const [refetchProducts, setRefetchProducts] = useState<boolean>(true)
  const [hasFetched, setHasFetched] = useState<boolean>(false)

  const [productMenuAnchorEl, setProductMenuAnchorEl] =
    useState<null | HTMLElement>(null)
  const productMenuOpen = Boolean(productMenuAnchorEl)
  const [editPlan, setEditPlan] = useState({
    open: false,
    product: {} as Products
  })

  //const match = useMediaQuery('(max-width: 600px)')

  const handleEditProduct = async () => {
    await getProductToEdit(currentProduct?.uuid || '')

    setEditPlan({
      open: true,
      product: productToBeEdited || ({} as Products)
    })
  }

  const handleOpenProductMenu = (e: any) =>
    setProductMenuAnchorEl(e.currentTarget)

  const handleCloseProductMenu = () => setProductMenuAnchorEl(null)

  const getProductToEdit = async (uuid: string) => {
    const response = await DataService({
      type: 'GET',
      url: `/v2/products/${uuid}`
    })

    if (response.status === 200) {
      setProductToBeEdited(constructorProducts([response.data.record])[0])
    }
  }

  const getAutocompleteProducts = async () => {
    setHasFetched(false)
    const responseProductsAutocomplete = await DataService({
      type: 'GET',
      url: '/v1/products_autocomplete'
    })
    if (responseProductsAutocomplete.status === 200) {
      const productsList = responseProductsAutocomplete.data.records
        .filter((record: ProductAutocomplete) => {
          if (filterByAmount) {
            return (
              filterByAmount % Number(record.amount) === 0 &&
              Number(record.amount) <= filterByAmount
            )
          }
          return true
        })
        .map((record: ProductAutocomplete) => ({
          label: `${record.name}|${record.amount}`,
          value: record.uuid,
          product: record
        }))

      setProducts(productsList)
    }
    setHasFetched(true)
  }

  useEffect(() => {
    if (!product) {
      getAutocompleteProducts()
    }
  }, [refetchProducts, refetchAt])

  useEffect(() => {
    setCurrentProduct(products.find((p) => p.value == value)?.product)
  })

  useEffect(() => {
    if (value == 'initial') return

    setCurrentProduct(products.find((p) => p.value == value)?.product)
  }, [value])

  return (
    <>
      {value !== 'initial' && outputType == 'card' ? (
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            padding: '16px',
            boxShadow: '0px 2px 10px 0px rgba(0,0,0,0.1)',
            alignItems: 'center',
            position: 'relative',
            borderRadius: '8px'
          }}
        >
          {!hasFetched ? (
            <Box bgcolor="#F8F8F8" p={1} borderRadius={1}>
              <Loader />
            </Box>
          ) : currentProduct?.document_file ? (
            <Box borderRadius="5px">
              <Image
                style={{ borderRadius: '5px' }}
                height={52}
                width={52}
                src={currentProduct?.document_file?.thumbnail_url}
                alt="imagem do plano"
              />
            </Box>
          ) : (
            <Box bgcolor="#F8F8F8" p={1} borderRadius={1}>
              <GradientOpenWithIcon
                icon={currentProduct?.icon as Icons}
                width="32px"
                height="32px"
                colors={['#00EC89', '#00F4F5', '#00FEFF']}
              />
            </Box>
          )}

          <Box display="flex" flexDirection="column">
            <Typography variant="mdBold" fontSize={17}>
              {currentProduct?.name}
            </Typography>
            {currentProduct?.amount && (
              <Typography variant="mdLight">
                R$ {currency(currentProduct?.amount)}
              </Typography>
            )}
          </Box>
          <IconButton
            sx={{ position: 'absolute', top: 0, right: 0 }}
            onClick={(e) => handleOpenProductMenu(e)}
          >
            <Icon icon="moreHoriz" />
          </IconButton>
          <Can
            roles={['admin', 'user', 'financial_operator', 'operator']}
            status={['trial', 'checking']}
          >
            <Menu
              open={productMenuOpen}
              anchorEl={productMenuAnchorEl}
              onClose={handleCloseProductMenu}
              sx={{ color: 'black' }}
            >
              <MenuItem
                sx={{ display: 'flex', gap: 1 }}
                onClick={() => {
                  handleCloseProductMenu()
                  handleEditProduct()
                }}
              >
                <Icon icon="edit" /> Editar plano
              </MenuItem>
              <MenuItem
                sx={{ display: 'flex', gap: 1 }}
                onClick={() => {
                  handleCloseProductMenu()
                  handleRemoveProduct()
                }}
              >
                <Icon icon="close" /> Remover plano
              </MenuItem>
            </Menu>
          </Can>
        </Box>
      ) : (
        <Autocomplete
          id="product"
          onChange={onChange}
          value={value}
          options={products || []}
          disabled={disabled}
          autoHighlight
          multiple={multiple}
          filterOptions={(options, { inputValue }) =>
            options.filter((option) =>
              (option as Record).label
                .toLowerCase()
                .includes(inputValue.toLowerCase())
            )
          }
          getOptionLabel={(option) => {
            if (!option) return ''
            if (product) return product.name
            return (
              products.find(
                (p) => p.value == ((option as Record).value || option)
              )?.product.name || ''
            )
          }}
          isOptionEqualToValue={(option, value) =>
            (option as Record).value === (value as Record).value
          }
          noOptionsText={
            hasFetched ? 'Não há produtos disponíveis.' : <Loader />
          }
          renderOption={(props, option) => {
            const optionTyped = option as Record
            const product = optionTyped?.product

            return (
              <MenuItem
                key={product?.uuid}
                value={product?.uuid}
                {...props}
                sx={{ display: 'flex', gap: 2 }}
              >
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                  p="12px"
                  alignItems="center"
                >
                  <Box display="flex" alignItems="center" gap="12px">
                    {product?.document_file?.thumbnail_url ? (
                      <Box
                        borderRadius="5px"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <Image
                          style={{ borderRadius: '5px' }}
                          height={52}
                          width={52}
                          src={product?.document_file?.thumbnail_url}
                          alt="imagem do plano"
                        />
                      </Box>
                    ) : (
                      <Box
                        bgcolor="#F8F8F8"
                        p={1}
                        borderRadius={1}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <GradientOpenWithIcon
                          icon={product?.icon as Icons}
                          width="32px"
                          height="32px"
                          colors={['#00EC89', '#00F4F5', '#00FEFF']}
                        />
                      </Box>
                    )}

                    <Box>
                      <Typography variant="smBold">{product?.name}</Typography>
                      <Box display="flex">
                        <Typography variant="smLight" color="#828282">
                          Valor: <strong>R$ {currency(product?.amount)}</strong>
                        </Typography>
                        <Typography variant="smLight" color="#828282">
                          , Número de parcelas:{' '}
                          <strong>
                            {product?.total_cycles
                              ? `${product?.total_cycles}x`
                              : 'infinito'}
                          </strong>
                        </Typography>
                        {!!product?.due_day && (
                          <Typography variant="smLight" color="#828282">
                            , Dia do vencimento:{' '}
                            <strong>{product?.due_day}</strong>
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </MenuItem>
            )
          }}
          renderInput={(params) => (
            <Textfield
              error={!!errorMessage}
              helperText={errorMessage || ''}
              {...(label ? { label } : {})}
              {...(placeholder ? { placeholder } : {})}
              sx={
                multiple
                  ? {
                      '& .MuiInputBase-root': {
                        height: '100%',
                        minHeight: '42px'
                      }
                    }
                  : undefined
              }
              {...params}
            />
          )}
        />
      )}

      {outputType == 'card' && (
        <Dialog
          title=""
          setIsOpenDialog={() =>
            setEditPlan({ open: false, product: {} as Products })
          }
          isOpenDialog={editPlan.open}
          maxWidth="lg"
        >
          <FormProducts
            onSuccessFunction={() => setRefetchProducts((prev) => !prev)}
            product={productToBeEdited}
            setIsOpenDialog={() =>
              setEditPlan({
                open: false,
                product: productToBeEdited || ({} as Products)
              })
            }
          />
        </Dialog>
      )}
    </>
  )
}
export default AutocompleteProducts
