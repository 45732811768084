import {
  AppBar,
  Button,
  MenuItem as MenuItemMUI,
  styled,
  Toolbar as ToolbarMUI
} from '@mui/material'

type AppBarMUIProps = {
  isExpandedDrawer: boolean
  isExpandedMobileMenu: boolean
  showTopGlobalInfo?: boolean
  showActivateAccountButton?: boolean
}

type ToolbarProps = {
  isExpandedMobileMenu: boolean
  showActivateAccountButton?: boolean
}

export const AppBarMUI = styled(AppBar)<AppBarMUIProps>(
  ({
    theme,
    isExpandedDrawer,
    isExpandedMobileMenu,
    showTopGlobalInfo,
    showActivateAccountButton
  }) => ({
    '@media (min-width: 770px)': {
      width: isExpandedDrawer ? 'calc(100% - 260px)' : 'calc(100% - 100px)'
    },
    alignItems: 'center',
    borderLeft: `1px solid ${theme.palette.neutral['700']}`,
    backgroundColor: theme.palette.neutral['800'],
    boxShadow: `0px 1px 0px ${theme.palette.neutral[700]}`,
    flexShrink: 'initial',
    height: isExpandedMobileMenu ? '100vh' : 80,
    ...(showTopGlobalInfo && { top: '50px', marginBottom: '50px' }),
    transition: 'height 0.5s ease-in-out',
    [theme.breakpoints.down(770)]: {
      ...(showTopGlobalInfo && { top: '120px' }),
      backgroundColor: theme.palette.neutral[800],
      boxShadow: 'none',
      height: isExpandedMobileMenu
        ? '100vh'
        : showActivateAccountButton
        ? 120
        : 80,
      zIndex: 1000
    },
    zIndex: 999
  })
)

export const Toolbar = styled(ToolbarMUI)<ToolbarProps>(
  ({ isExpandedMobileMenu, theme, showActivateAccountButton }) => ({
    alignItems: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    height: isExpandedMobileMenu ? '100vh' : 80,
    transition: 'height 0.5s ease-in-out',
    justifyContent: 'space-between',
    marginLeft: 'auto',
    width: '100%',
    [theme.breakpoints.down(770)]: {
      height: isExpandedMobileMenu
        ? '100vh'
        : showActivateAccountButton
        ? 120
        : 80
    }
  })
)

export const ToolbarContentWrapper = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: 15
}))
export const EndIconsWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 8
}))

export const Hamburger = styled('button')(
  () => `
  all: inherit;
  width: 28px;
  height: 15px;

  padding: 5px;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & span {
    width: 100%;
    height: 2px;
    background: #000;
  }
`
)

export const BalanceButton = styled(Button)(() => ({
  ['&:focus']: {
    borderColor: 'black'
  },
  ['&:hover, &:focus']: {
    background: 'none'
  },
  alignItems: 'flex-start',
  border: '1px solid transparent',
  cursor: 'pointer',
  flexDirection: 'column'
}))

export const MenuItem = styled(MenuItemMUI)(() => ({
  ['&:last-child']: {
    marginTop: 4
  },
  alignItems: 'center',
  display: 'flex',
  gap: 8,
  minWidth: 300
}))

export const Actions = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 10
}))

export const DropdownButton = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.neutral[800],
  borderRadius: '50%',
  position: 'absolute',
  left: '50%',
  top: '80%'
}))
