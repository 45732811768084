import React from 'react'

import { Box, Typography, LinearProgress } from '@mui/material'

import { theme } from 'styles/theme'

interface ProgressBarProps {
  label: string
  value: {
    actual: number
    max: number
  }
}

const ProgressBar = ({ label, value }: ProgressBarProps) => {
  const percentage = (value.actual / value.max) * 100

  return (
    <Box display="flex" flexDirection="column" gap="5px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          sx={{
            [theme.breakpoints.down(960)]: {
              fontSize: '12px'
            }
          }}
          color="#fff"
          fontWeight="300"
          variant="smLight"
        >
          {label}
        </Typography>
        <Typography
          sx={{
            [theme.breakpoints.down(960)]: {
              fontSize: '12px'
            }
          }}
          color="#fff"
          fontWeight="500"
          variant="xsLight"
        >
          {value.actual} de {value.max}
        </Typography>
      </Box>
      <LinearProgress
        variant="determinate"
        value={percentage}
        sx={{
          [theme.breakpoints.down(960)]: {
            width: '120px'
          },
          height: 4,
          width: '145px',
          borderRadius: 4,
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
          '& .MuiLinearProgress-bar': {
            backgroundColor: '#ffffff'
          }
        }}
      />
    </Box>
  )
}

export default ProgressBar
