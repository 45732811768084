import React, { useState } from 'react'

import { Icon, Icons } from '@clientbase/clientbase-library'
import { Divider, IconButton, Typography, useMediaQuery } from '@mui/material'
import Menu from '@mui/material/Menu'
import { useAuthContext } from 'context/AuthContext'
import Image from 'next/image'
import { useRouter } from 'next/router'

import CanLogical from 'components/CanLogical'

import * as S from './styles'

export type Setting = {
  action: () => void
  icon: Icons
  label: string
}

type MerchantAccountProps = {
  setIsOpenModalReport: React.Dispatch<React.SetStateAction<boolean>>
}

const MerchantAccount = ({ setIsOpenModalReport }: MerchantAccountProps) => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)
  const match = useMediaQuery('(max-width: 770px)', { noSsr: false })

  const routerGlobal = useRouter()
  const { signOut, user } = useAuthContext()

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (match) return

    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleOpenModalReport = () => {
    setIsOpenModalReport(true)
  }

  const settings: Setting[] = [
    {
      action: () => {
        routerGlobal.push('/settings')
        handleCloseUserMenu()
      },
      icon: 'settings',
      label: 'Configurações'
    },
    {
      action: () => {
        handleOpenModalReport()
        handleCloseUserMenu()
      },
      icon: 'assignment',
      label: 'Relatórios'
    }
  ]

  if (
    CanLogical({
      roles: ['guest'],
      status: ['trial', 'checking']
    })
  ) {
    const ConfigsSettingsIndex = settings.findIndex(
      ({ label }) => label === 'Configurações'
    )
    settings.splice(ConfigsSettingsIndex, 1) // delete 1 item by index of reports

    const balaceSettingsIndex = settings.findIndex(
      ({ label }) => label === 'Esconder Saldo'
    )
    settings.splice(balaceSettingsIndex, 1) // delete 1 item by index of reports

    const reportsSettingsIndex = settings.findIndex(
      ({ label }) => label === 'Relatórios'
    )
    settings.splice(reportsSettingsIndex, 1) // delete 1 item by index of reports
  }

  if (
    CanLogical({
      roles: ['operator', 'financial_operator'],
      status: ['trial', 'checking']
    })
  ) {
    const reportsSettingsIndex = settings.findIndex(
      ({ label }) => label === 'Relatórios'
    )
    settings.splice(reportsSettingsIndex, 1) // delete 1 item by index of reports
  }

  return (
    <div onMouseDown={handleOpenUserMenu}>
      {!match ? (
        <IconButton>
          <Icon icon="settings" />
        </IconButton>
      ) : (
        <S.MobileBox>
          <S.MobileLogo>
            <Image
              src={user?.logo || '/favicon-32x32.png'}
              width={32}
              height={32}
              objectFit="contain"
              style={{ aspectRatio: '1/1' }}
              alt="logo user"
            />
          </S.MobileLogo>
          <S.MobileName>{user?.merchant_user_name ?? user?.name}</S.MobileName>
        </S.MobileBox>
      )}

      <Menu
        anchorEl={anchorElUser}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top'
        }}
        keepMounted={false}
        onClose={handleCloseUserMenu}
        open={Boolean(anchorElUser)}
        sx={{ maxWidth: '950px', minWidth: '300px', mt: 5 }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top'
        }}
      >
        <S.MenuItem onClick={handleCloseUserMenu}>
          <S.LogoWrapper>
            <Image
              src={user?.logo || '/only-icon.png'}
              width={32}
              height={32}
              objectFit="contain"
              style={{ aspectRatio: '1/1' }}
              alt="icon"
            />
          </S.LogoWrapper>
          <Typography textAlign="center" variant="mdBold">
            {user?.name}
          </Typography>
        </S.MenuItem>

        <Divider />

        {settings.map((setting) => {
          return (
            <S.MenuItem key={setting.label} onClick={setting.action}>
              {!!setting.icon && <Icon icon={setting.icon} width="23px" />}
              <Typography>{setting.label}</Typography>
            </S.MenuItem>
          )
        })}

        <S.MenuItem
          onClick={async () => {
            await signOut()
            handleCloseUserMenu()
          }}
        >
          <Icon icon={'exitToApp'} width="23px" />
          <Typography>Sair</Typography>
        </S.MenuItem>
      </Menu>
    </div>
  )
}
export default MerchantAccount
