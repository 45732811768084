import { useState } from 'react'

import { Button, Icon } from '@clientbase/clientbase-library'
import {
  Box,
  FormControlLabel,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material'
import { Plan, useAuthContext } from 'context/AuthContext'
import { useRouter } from 'next/router'

import { settingsTabsParams } from 'utils/tabs'

import { theme } from 'styles/theme'

import * as S from './ModalPricing.styles'

type ModalPricingProps = {
  handleCloseModal: () => void
  isOpen: boolean
  plan: Plan
  resourceName: string
}

type PlanToShow = {
  name: string
  price: number
  description: string
  features: string[]
  monthlyLink: string
  yearlyLink: string
}

const ALL_PLANS_FEATURES = [
  'PIX e boleto por R$1,99',
  'Emissão de Nota Fiscal automatizada',
  'Portal de Clientes personalizado'
]

const PLANS: { [key in Exclude<Plan, 'free' | 'ultra'>]: PlanToShow } = {
  basic: {
    name: 'Plano Basic',
    price: 99,
    description: 'Recursos extras para empresas de alto crescimento.',
    monthlyLink:
      'https://portal.basedeclientes.com.br/checkout/88fcede5-6e9d-49ab-83ae-ebc98f0d0f2a',
    yearlyLink:
      'https://portal.basedeclientes.com.br/checkout/3426043b-4889-463d-9b29-79f7ee5f144d',
    features: [
      'Notificação via WhatsApp em todos os lembretes',
      'Campos Extras no Link de Cadastro de Clientes',
      'Modelos de Contrato',
      'Edição de Mensagens na Régua de Cobrança',
      'Emissão de Nota Fiscal para MEI',
      'Até 3 usuários, com níveis de acesso',
      'Adiantamento de recebíveis de cartão e planos D7 e D15',
      'Gestão de Contas a Pagar (em breve)'
    ]
  },
  pro: {
    name: 'Plano Pro',
    price: 299,
    description: 'Recursos exclusivos e descontos para empresas sofisticadas.',
    monthlyLink:
      'https://portal.basedeclientes.com.br/checkout/ac4242f5-2bf5-4fb2-8c01-22c0a241bb7c',
    yearlyLink:
      'https://portal.basedeclientes.com.br/checkout/a392c4bf-5a92-4848-aa6d-bf7485284333',
    features: [
      'Envio de Contratos em massa e desconto na assinatura: R$ 2,90',
      'Régua de cobrança agressiva e desconto nas notificações extras',
      'Até 10 usuários, com níveis de acesso expandidos',
      'Plano D1 e desconto no Cartão de Crédito: 3,49% à vista',
      'Desconto nas notas fiscais: R$ 0,29',
      'Desconto na Negativação: R$ 14,90',
      'API e integrações',
      ' Suporte dedicado'
    ]
  }
}

const ModalPricing = ({
  isOpen,
  plan = 'basic',
  handleCloseModal,
  resourceName
}: ModalPricingProps) => {
  const { push } = useRouter()
  const { setModalPricing } = useAuthContext()
  const match = useMediaQuery('(max-width: 600px)')
  const selectedPlan = plan as Exclude<Plan, 'free' | 'ultra'>
  const [planFrequency, setPlanFrequency] = useState<'yearly' | 'monthly'>(
    'monthly'
  )

  const CloseButton = () => (
    <IconButton
      sx={{
        alignSelf: 'end',
        position: !match ? 'relative' : 'absolute',
        top: !match ? '-12px' : '12px',
        right: !match ? '-12px' : '12px'
      }}
      onClick={handleCloseModal}
    >
      <Icon icon="close" />
    </IconButton>
  )

  return (
    <Modal aria-hidden={!isOpen} onClose={handleCloseModal} open={isOpen}>
      <S.Content size="extraLarge">
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '8fr 4fr',
            height: '100%',

            [theme.breakpoints.down('sm')]: {
              gridTemplateColumns: '1fr'
            }
          }}
        >
          <Stack
            sx={{
              padding: !match ? '36px' : '36px 22px 22px 22px',
              height: !match ? '95%' : 'auto',
              gap: '32px',
              justifyContent: 'space-between'
            }}
          >
            <Stack
              direction={!match ? 'row' : 'column'}
              alignItems={!match ? 'flex-start' : 'center'}
              gap="18px"
            >
              {match && <CloseButton />}
              <Box
                sx={{
                  borderRadius: '999px',
                  backgroundColor: theme.palette.info[200],
                  width: '40px',
                  height: '40px',
                  minWidth: '40px',
                  minHeight: '40px',
                  display: 'grid',
                  placeItems: 'center'
                }}
              >
                <Icon
                  icon="arrowUpward"
                  sx={{ color: '#fff', fontSize: '30px' }}
                />
              </Box>
              <Stack textAlign={!match ? 'start' : 'center'}>
                <Typography variant="lgBold">
                  Desbloqueie mais recursos
                </Typography>
                <Typography>
                  O recurso {resourceName} não está disponível no seu plano
                  atual. Venda mais, receba sem esforço e cuide melhor dos seus
                  clientes com os recursos adicionais do{' '}
                  {PLANS[selectedPlan].name}.
                </Typography>
              </Stack>
            </Stack>

            <Stack
              sx={{
                border: '1px solid',
                borderColor: theme.palette.neutral[700],
                padding: '22px',
                borderRadius: '12px',
                gap: '15px',
                position: 'relative'
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '-16px',
                  borderRadius: '999px',
                  backgroundColor: theme.palette.info[200],
                  padding: '5px 20px'
                }}
              >
                <Typography variant="mdBold" color="#fff">
                  Inclui {resourceName}
                </Typography>
              </Box>
              <Stack gap="20px">
                <Typography variant="lgBold">
                  {PLANS[selectedPlan].name}
                </Typography>
                <Typography variant="mdBold">Recursos em destaque</Typography>
              </Stack>
              <Stack gap="16px">
                {PLANS[selectedPlan].features.map((resource) => (
                  <Stack
                    key={resource}
                    direction="row"
                    gap="5px"
                    alignItems="center"
                  >
                    <Box
                      sx={{
                        borderRadius: '999px',
                        border: '1px solid',
                        borderColor: theme.palette.info[200],
                        width: '16px',
                        height: '16px',
                        display: 'grid',
                        placeItems: 'center'
                      }}
                    >
                      <Icon
                        icon="arrowUpward"
                        sx={{
                          color: theme.palette.info[200],
                          fontSize: '14px'
                        }}
                      />
                    </Box>
                    <Typography>{resource}</Typography>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          </Stack>

          <Stack
            sx={{
              padding: !match ? '36px' : '22px 22px 36px 22px',
              height: !match ? '95%' : 'auto',
              gap: !match ? '0' : '30px',
              backgroundColor: theme.palette.neutral[900],
              justifyContent: !match ? 'space-between' : 'flex-start'
            }}
          >
            <Stack gap="15px">
              {!match && <CloseButton />}

              <Stack gap="15px">
                <RadioGroup
                  value={planFrequency}
                  onChange={(e) => setPlanFrequency(e.target.value as any)}
                  row
                >
                  {' '}
                  <FormControlLabel
                    value="monthly"
                    control={
                      <Radio
                        sx={{
                          '&.Mui-checked': { color: theme.palette.info[200] }
                        }}
                      />
                    }
                    label={
                      <S.PlanFrequencyLabel
                        planFrequency={planFrequency}
                        value="monthly"
                      >
                        Mensal
                      </S.PlanFrequencyLabel>
                    }
                  />
                  <FormControlLabel
                    value="yearly"
                    control={
                      <Radio
                        sx={{
                          '&.Mui-checked': { color: theme.palette.info[200] }
                        }}
                      />
                    }
                    label={
                      <S.PlanFrequencyLabel
                        planFrequency={planFrequency}
                        value="yearly"
                        sx={{ position: 'relative', mr: '35px' }}
                      >
                        Anual <S.YearlyDiscountTag>-20%</S.YearlyDiscountTag>
                      </S.PlanFrequencyLabel>
                    }
                  />
                </RadioGroup>

                <Stack justifyContent="space-between">
                  <Typography variant="lgBold">
                    {PLANS[selectedPlan].name}
                  </Typography>
                  <Typography>{`R$${
                    planFrequency == 'monthly'
                      ? PLANS[selectedPlan].price
                      : (PLANS[selectedPlan].price * 0.8)
                          .toFixed(2)
                          .toString()
                          .replace('.', ',')
                  }/mês`}</Typography>
                </Stack>

                <Typography>{PLANS[selectedPlan].description}</Typography>

                <Button
                  sx={{
                    backgroundColor: theme.palette.info[200],
                    ':hover': { backgroundColor: theme.palette.info[200] }
                  }}
                  onClick={() => {
                    const url =
                      planFrequency === 'monthly'
                        ? PLANS[selectedPlan].monthlyLink
                        : PLANS[selectedPlan].yearlyLink

                    window.open(url, '_blank', 'noopener,noreferrer')
                  }}
                >
                  Alterar plano
                </Button>
              </Stack>
            </Stack>

            <Stack gap="22px">
              <Stack gap="10px">
                <Typography variant="mdBold">
                  Incluso em todos os planos
                </Typography>

                <Stack gap="8px">
                  {ALL_PLANS_FEATURES.map((resource) => (
                    <Stack
                      key={resource}
                      direction="row"
                      gap="5px"
                      alignItems="center"
                    >
                      <Box
                        sx={{
                          borderRadius: '999px',
                          border: '1px solid',
                          borderColor: theme.palette.info[200],
                          width: '16px',
                          height: '16px',
                          display: 'grid',
                          placeItems: 'center'
                        }}
                      >
                        <Icon
                          icon="arrowForward"
                          sx={{
                            color: theme.palette.info[200],
                            fontSize: '14px'
                          }}
                        />
                      </Box>
                      <Typography>{resource}</Typography>
                    </Stack>
                  ))}
                </Stack>
              </Stack>

              <Button
                sx={{
                  backgroundColor: 'transparent',
                  border: 'solid 2px',
                  borderColor: theme.palette.info[200],
                  color: `${theme.palette.info[200]} !important`,
                  ':hover': { backgroundColor: 'transparent' }
                }}
                onClick={() => {
                  push(`/settings?tab=${settingsTabsParams.MY_PLAN}`)
                  setModalPricing((prev) => ({ ...prev, isOpen: false }))
                }}
              >
                <Box color={`${theme.palette.info[200]} !important`}>
                  Ver todos os planos
                </Box>
              </Button>
            </Stack>
          </Stack>
        </Box>
      </S.Content>
    </Modal>
  )
}

export default ModalPricing
