import React from 'react'

export interface DropContainerProps {
  isDragActive: boolean
  isDragReject: boolean
  sucess?: boolean
}

export interface UploadMessageProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  > {
  children?: React.ReactNode | string
  type?: 'default' | 'error' | 'success'
}

export interface UploadProps {
  sentIcon?: boolean
  onUpload?: (files: any) => void
  maxFiles?: number
  title?: string | React.ReactNode
  subTitle?: string
  uploadedFilesLength: number
  disabled?: boolean
  accept?: any
  disabledText?: string
  variant?: 'small' | 'normal'
}
