import React from 'react'
import Dropzone from 'react-dropzone'

import { Icon } from '@clientbase/clientbase-library'
import { Box, Typography } from '@mui/material'

import { theme } from 'styles/theme'

import { DropContainer, UploadMessage, UploadProps } from '.'

const Upload: React.FC<UploadProps> = ({
  onUpload,
  maxFiles = 1,
  title,
  subTitle,
  uploadedFilesLength,
  sentIcon,
  disabled = false,
  accept,
  disabledText,
  variant = 'normal'
}) => {
  const renderDragMessage = (isDragActive: boolean, isDragReject: boolean) => {
    if (!isDragActive) {
      return (
        <Box display="flex" gap={variant === 'small' ? 0.5 : 1.5}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            ml={variant === 'small' ? 0 : 2}
          >
            <Icon
              colorSX={theme.palette.neutral[500]}
              icon="noteAdd"
              width={variant === 'small' ? '30px' : '48px'}
              height={variant === 'small' ? '34px' : '48px'}
            />
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Typography
              variant={
                variant === 'small'
                  ? disabled
                    ? 'smLight'
                    : 'smBold'
                  : disabled
                  ? 'mdLight'
                  : 'mdBold'
              }
              color={disabled ? '#A3A6A2' : '#231F20'}
            >
              {!disabledText ? title : disabled ? disabledText : title}
            </Typography>
            <Typography
              variant="smLight"
              color={theme.palette.neutral[500]}
              mt="8px"
              sx={{
                display: !disabledText ? 'block' : disabled ? 'none' : 'auto'
              }}
            >
              {subTitle}
            </Typography>
          </Box>
        </Box>
      )
    }

    if (isDragReject) {
      return <UploadMessage type="error">Arquivo não suportado</UploadMessage>
    }

    return <UploadMessage type="success">Solte os arquivos aqui</UploadMessage>
  }

  return (
    <Dropzone
      accept={
        accept
          ? accept
          : {
              'image/*': ['.png', '.jpg', '.jpeg'],
              'text/html': ['.pdf', '.doc', '.docx'],
              'text/csv': ['.csv']
            }
      }
      disabled={disabled}
      onDropAccepted={onUpload}
      maxFiles={maxFiles}
    >
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) =>
        !sentIcon ? (
          <DropContainer
            {...getRootProps()}
            isDragActive={isDragActive}
            isDragReject={isDragReject}
            sx={{
              cursor: disabled ? 'default' : 'pointer',
              ...(variant === 'small' && {
                padding: '10px'
              })
            }}
          >
            <input {...getInputProps()} />
            <Box />
            {renderDragMessage(isDragActive, isDragReject)}
          </DropContainer>
        ) : uploadedFilesLength !== maxFiles ? (
          <DropContainer
            {...getRootProps()}
            isDragActive={isDragActive}
            isDragReject={isDragReject}
            sx={{ cursor: disabled ? 'default' : 'pointer' }}
          >
            <input {...getInputProps()} />
            <Box />
            {renderDragMessage(isDragActive, isDragReject)}
          </DropContainer>
        ) : (
          <DropContainer
            isDragActive={isDragActive}
            isDragReject={isDragReject}
            sucess={true}
            sx={{ cursor: disabled ? 'default' : 'pointer' }}
          >
            <Box display="flex" justifyContent="space-between">
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Typography variant="mdBold">{title}</Typography>
                <Typography
                  variant="smLight"
                  color={theme.palette.neutral[500]}
                  mt="8px"
                >
                  {subTitle}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Icon
                  colorSX={theme.palette.success[300]}
                  icon="noteAdd"
                  width="40px"
                  height="40px"
                />
                <Typography
                  variant="smLight"
                  color={theme.palette.success[300]}
                  mt="8px"
                >
                  Enviado
                </Typography>
              </Box>
            </Box>
          </DropContainer>
        )
      }
    </Dropzone>
  )
}

export default Upload
