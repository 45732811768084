import React, { useEffect, useState } from 'react'

import { Icon } from '@clientbase/clientbase-library'
import { IconButton, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { DataService } from 'api/DataService'
import { Billing } from 'models/Overview/Transfer'
import { TransfersParams } from 'models/Transfers'
import { useRouter } from 'next/router'

import ModalChargeDetail from 'components/Modal/ModalChargeDetail'
import SearchModal from 'components/SearchModal'
import { ResultType } from 'components/SearchModal/SearchModal'

import { Debouncer, formatCpfCnpj, formatMoney } from 'utils'
import { CustomerData } from 'utils/mappers/customer'

import { theme } from 'styles/theme'

import { BigScreenButton } from './Search.styles'

export type RecordItem = {
  name: string
  status: string
}

export type Records = {
  billings?: Billing[]
  customers?: CustomerData[]
  transfers?: TransfersParams[]
}

const Search = () => {
  const { push } = useRouter()
  const [modalChargeProps, setModalChargeProps] = useState({
    isOpen: false,
    uuid: ''
  })
  const [inputValue, setInputValue] = useState('')
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [results, setResuts] = useState<ResultType[]>([] as ResultType[])
  const match = useMediaQuery('(max-width: 770px)', { noSsr: false })

  useEffect(() => {
    if (inputValue === '') {
      setResuts([] as ResultType[])
    }
  }, [inputValue])

  const handleCloseSearchModal = () => {
    setModalIsOpen(false)
    setResuts([] as ResultType[])
    setInputValue('')
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
    awaitForSubmit(event.target.value)
  }

  const handleClearInput = () => {
    setInputValue('')
    setResuts([] as ResultType[])
  }

  const handleOpenDetailInvoice = (uuid: string) => {
    setModalChargeProps((s) => ({ ...s, isOpen: true, uuid }))
    handleCloseSearchModal()
  }

  const handleCloseDetailInvoice = () => {
    setModalChargeProps((s) => ({ ...s, isOpen: false, uuid: '' }))
    handleCloseSearchModal()
  }

  const handleSubmit = async (text: string) => {
    if (text.trim() === '') {
      setResuts([] as ResultType[])
      setInputValue('')
      return
    }

    try {
      setLoading(true)
      const result = await DataService({
        data: { keywords: text },
        type: 'POST',
        url: '/v1/search'
      })

      const data: Records = result.data.records
      const { billings, customers } = data
      const results: ResultType[] = []

      customers?.forEach((customer) => {
        results.push({
          title: customer.name,
          nickname: customer.nickname,
          status: customer.status,
          subtitle: `${formatCpfCnpj(customer.document)} | ${customer.email}`,
          type: 'client',
          clickFunction: () => {
            push(`/clientes/${customer.uuid}`)
          },
          uuid: customer.uuid
        })
      })

      billings?.forEach((billing) => {
        results.push({
          title: billing.customer.name,
          status: billing.status,
          subtitle: `${formatMoney(
            Number(billing.amount_billed)
          )} | ${billing.due_date.split('-').reverse().join('/')}`,
          type: 'billing',
          clickFunction: () => {
            handleOpenDetailInvoice(billing.uuid)
          },
          dueDate: billing.due_date
        })
      })

      setResuts([...results])
      setLoading(false)
    } catch {
      setLoading(false)
    }
  }
  const awaitForSubmit = Debouncer(handleSubmit, 350)

  const handleKeyDown = (event: any) => {
    const isCtrlPressed = event.ctrlKey || event.metaKey
    const isSpacePressed = event.key === ' '
    if (isCtrlPressed && isSpacePressed) {
      setModalIsOpen((prevState) => !prevState)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false)
    return () => {
      document.removeEventListener('keydown', handleKeyDown, false)
    }
  }, [])

  return (
    <>
      <Tooltip enterTouchDelay={0} title="Buscar">
        {match ? (
          <IconButton onClick={() => setModalIsOpen(true)}>
            <Icon icon="search" style={{ color: '#777877' }} />
          </IconButton>
        ) : (
          <BigScreenButton onClick={() => setModalIsOpen(true)}>
            <Icon
              icon="search"
              style={{ color: theme.palette.neutral[400], fontSize: '24px' }}
              fontSize="large"
            />
            <Typography
              variant="smLight"
              fontSize={10}
              color={theme.palette.neutral[400]}
            >
              Buscar
            </Typography>
          </BigScreenButton>
        )}
      </Tooltip>

      <SearchModal
        isOpen={modalIsOpen}
        handleCloseFunction={handleCloseSearchModal}
        placeholder="Buscar na Base de Clientes"
        inputValue={inputValue}
        handleInputChangeFunction={handleInputChange}
        clearInputFunction={handleClearInput}
        results={results}
        loadingResults={loading}
      />

      <ModalChargeDetail
        disablePortal={false}
        handleCloseChargeDetail={handleCloseDetailInvoice}
        isOpenChargeDetail={modalChargeProps.isOpen}
        uuid={modalChargeProps.uuid}
      />
    </>
  )
}

export default Search
