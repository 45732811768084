import { styled } from '@mui/system'

export const Wrapper = styled('section')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: 575,
  padding: '0 12px',
  margin: '0 auto',
  gap: 36,
  [`& button`]: {
    background: `${theme.palette.primary.main} !important`
  }
}))

export const Header = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 2
}))

export const Heading = styled('h2')(() => ({
  margin: 0,
  textAlign: 'center',
  font: 'normal 600 16px/140% Inter, sans-serif',
  color: '#231F20'
}))

export const Subtitle = styled('span')(() => ({
  textAlign: 'center',
  font: 'normal 400 16px/140% Inter, sans-serif',
  color: '#757675'
}))

export const ImageWrapper = styled('div')(() => ({
  width: '100%',
  maxWidth: 277,
  position: 'relative',

  '> div': {
    position: 'unset !important'
  },

  'img, span': {
    objectFit: 'contain',
    width: '100% !important',
    position: 'relative !important',
    height: 'unset !important'
  },

  '&[data-fontIsThini="true"]': {
    maxWidth: 144
  }
}))

export const Description = styled('p')(() => ({
  maxWidth: '53ch',
  textAlign: 'center',
  font: 'normal 400 16px/140% Inter, sans-serif',
  color: '#757675',
  '&[data-fontIsThini="true"]': {
    fontSize: 12
  }
}))
