import React, { useContext } from 'react'

import { Icon } from '@clientbase/clientbase-library'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography
} from '@mui/material'
import { Negativation } from 'models/Invoice'
import moment from 'moment'

import { NegativateStatus } from 'utils/enums'

import { theme } from 'styles/theme'

import { ModalChargeDetailContext } from '../../context'
import { cardTittle } from '../../ModalChargeDetail.utils'
import { S } from './'
interface NegativationDetailProps {
  recurrenceNegativationData?: Negativation
}
const NegativationDetail = ({
  recurrenceNegativationData
}: NegativationDetailProps) => {
  const { invoice } = useContext(ModalChargeDetailContext)
  const data = {
    ...(!!invoice && {
      created_at: invoice?.negativation?.negativation?.created_at,
      status: invoice?.negativation?.negativation?.status,
      days_to_report: invoice?.negativation?.days_to_report
    }),
    ...(!!recurrenceNegativationData && {
      created_at: recurrenceNegativationData?.negativation?.created_at,
      status: recurrenceNegativationData?.negativation?.status,
      days_to_report: recurrenceNegativationData?.days_to_report
    })
  }
  if (!data.days_to_report && !data.created_at) {
    return null
  }
  return (
    <Accordion
      sx={{
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
        '&:before': {
          display: 'none'
        }
      }}
    >
      <AccordionSummary
        expandIcon={<Icon icon="keyboardArrowDown" />}
        aria-controls="payerDetails-content"
        id="payerDetails"
      >
        {cardTittle(
          'Detalhes da negativação',
          'thumbDownAlt',
          `${theme.palette.primary.main}`
        )}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          borderTop: '1px solid #E3E6E3'
        }}
      >
        <div>
          {data.created_at && (
            <Box
              display="flex"
              gap={1.25}
              alignItems="end"
              margin={'10px 0px 10px 0px'}
            >
              <Typography variant="mdLight" color="#777777">
                Data negativação:
              </Typography>
              <S.Separator sx={{ marginTop: '4px' }} />
              <Typography variant="mdBold" display="flex" alignItems="center">
                {moment(data?.created_at).format('DD/MM/yyyy')}
              </Typography>
            </Box>
          )}
          {data?.status && (
            <Box
              display="flex"
              gap={1.25}
              alignItems="end"
              margin={'10px 0px 10px 0px'}
            >
              <Typography variant="mdLight" color="#777777">
                Etapa do processo:
              </Typography>
              <S.Separator sx={{ marginTop: '4px' }} />
              <Typography variant="mdBold" display="flex" alignItems="center">
                {NegativateStatus[data?.status]}
              </Typography>
            </Box>
          )}
          {data?.days_to_report && (
            <Box
              display="flex"
              gap={1.25}
              alignItems="end"
              margin={'10px 0px 10px 0px'}
            >
              <Typography variant="mdLight" color="#777777">
                Negativar após:
              </Typography>
              <S.Separator sx={{ marginTop: '4px' }} />
              <Typography variant="mdBold" display="flex" alignItems="center">
                {data.days_to_report}
              </Typography>
            </Box>
          )}
          {/* TODO: Verificar termo aditivo */}
          {/* <Box
            display="flex"
            gap={1.25}
            alignItems="end"
            margin={'10px 0px 10px 0px'}
          >
            <Typography variant="mdLight" color="#777777">
              Termo aditivo:
            </Typography>
            <S.Separator sx={{ marginTop: '4px' }} />
            <Typography
              variant="mdBold"
              display="flex"
              alignItems="center"
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              Visualizar termo
            </Typography>
          </Box> */}
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default NegativationDetail
