import { styled } from '@mui/material'

export const Link = styled('a')(({ theme }) => ({
  margin: 0,
  padding: 0,
  [theme.breakpoints.down(800)]: {
    fontSize: '13px'
  },
  fontWeight: 400,
  cursor: 'pointer'
}))
