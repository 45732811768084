import { GetServerSideProps, NextPage } from 'next'
import { getSession } from 'next-auth/react'
import { parseCookies } from 'nookies'

import decodeBase64 from 'utils/decode-base64-to-string'
import { destroySession } from 'utils/DestroySession'
import { FeatureTabs } from 'utils/tabs'

import { ExtendedSession } from './api/auth/[...nextauth]'

export const REDIRECT_URL_BY_ROLE = {
  admin: '/visao-geral',
  operator: `/clientes?tab=${FeatureTabs.CUSTOMERS}`,
  financial_operator: `/clientes?tab=${FeatureTabs.CUSTOMERS}`,
  user: '/visao-geral',
  guest: `/clientes?tab=${FeatureTabs.CUSTOMERS}`
}

export const Home: NextPage = () => {
  return null
}

export default Home

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const session = (await getSession({ req: ctx.req })) as ExtendedSession
  const { ['nextauth.role']: role, ['nextauth.token']: token } =
    parseCookies(ctx)
  // eslint-disable-next-line react-hooks/rules-of-hooks

  if (!role && !token && session) {
    return {
      redirect: {
        destination: '/registrar',
        permanent: false
      }
    }
  }
  if (!role && !token) {
    return {
      redirect: {
        destination: '/login',
        permanent: false
      }
    }
  }

  const decryptRole = decodeBase64(role)
  const isValidRole = Object.keys(REDIRECT_URL_BY_ROLE).find(
    (k) => k === decryptRole
  )

  if (!isValidRole) {
    destroySession(ctx)
    return {
      redirect: {
        destination: '/login',
        permanent: false
      }
    }
  }

  if (!!token && !!decryptRole) {
    return {
      redirect: {
        destination: REDIRECT_URL_BY_ROLE[decryptRole] ?? '/login',
        permanent: false
      }
    }
  }

  return {
    redirect: {
      destination: '/login',
      permanent: false
    }
  }
}
