import React, { useContext } from 'react'

import { Button, Dialog } from '@clientbase/clientbase-library'
import { ChargeContext } from 'templates/Charge/context'

import { theme } from 'styles/theme'

import { NegativationContext } from '../../context'
import { FormNegativationContext } from '../context'

const Confirmation: React.FC = () => {
  const { confirmationDialog, updateConfirmationDialog } = useContext(
    FormNegativationContext
  )

  const { toggleModal } = useContext(ChargeContext)

  const { updateModalDetail } = useContext(NegativationContext)

  const closeConfirmationDialog = () => {
    updateConfirmationDialog({ open: false, uuid: '' })
  }

  const handleOpenDetail = () => {
    const uuid = confirmationDialog.uuid

    updateModalDetail({ open: true, uuid })
    toggleModal('modalNegativation')
    updateConfirmationDialog({ open: false, uuid: '' })
  }

  return (
    <Dialog
      icon="checkCircle"
      iconColor={theme.palette.primary.main}
      title="Negativação solicitada"
      description="Nossa equipe ja está analisando a sua solicitação. Seu cliente será notificado via e-mail após a aprovação da negativação"
      isOpenDialog={confirmationDialog.open}
      setIsOpenDialog={closeConfirmationDialog}
    >
      <Button onClick={handleOpenDetail}>DETALHES</Button>
    </Dialog>
  )
}

export default Confirmation
