import React, { useState } from 'react'
import { ToolbarProps, View, Navigate as navigate } from 'react-big-calendar'
import { toast } from 'react-toastify'

import { Button, Dialog, Icon } from '@clientbase/clientbase-library'
import { IconButton } from '@mui/material'
import { ReceivablesService } from 'services/ReceivablesService'

import { formatMoney } from 'utils'

import { EventsCalendar } from '../../CalendarReceivables.interface'
import ViewNamesGroup from '../ViewNamesGroup/ViewNamesGroup'
import * as S from './ToolbarCalendar.styles'

type ToolbarCalendarProps = {
  balanceScheduled: number | false
} & ToolbarProps<EventsCalendar, object>

const ToolbarCalendar: React.FC<ToolbarCalendarProps> = ({
  balanceScheduled,
  onNavigate,
  label,
  localizer: { messages },
  onView,
  view,
  views
}) => {
  const [dialog, setDialog] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  async function handleSubmitAlert() {
    try {
      setLoading(true)
      const response = await ReceivablesService.antecipate()
      toast.success(response.message)
    } catch (err) {
      //
    } finally {
      setLoading(false)
    }
    handleCloseDialog()
  }

  function handleCloseDialog() {
    setDialog(false)
  }

  function handleOpenDialog() {
    setDialog(true)
  }

  function getPreviousMonthByButton() {
    onNavigate(navigate.PREVIOUS)
  }

  function getNextMonthByButton() {
    onNavigate(navigate.NEXT)
  }

  return (
    <S.Container>
      <S.Wrapper>
        <IconButton
          sx={{ background: 'transparent !important' }}
          onClick={getPreviousMonthByButton}
        >
          <Icon icon="arrowBackIosNew" fontSize="small" />
        </IconButton>
        <S.MonthLabel>{label}</S.MonthLabel>
        <IconButton
          sx={{ background: 'transparent !important' }}
          onClick={getNextMonthByButton}
        >
          <Icon icon="arrowForwardIos" fontSize="small" />
        </IconButton>
      </S.Wrapper>

      <ViewNamesGroup
        view={view}
        views={views as Array<View>}
        messages={messages}
        onView={onView}
      />
      <S.ButtonAntecipate
        onClick={handleOpenDialog}
        disabled={balanceScheduled === 0 || balanceScheduled === false}
      >
        SOLICITAR ADIANTAMENTO DE RECEBÍVEIS
      </S.ButtonAntecipate>

      <Dialog
        icon="description"
        title={`Adiantamento de recebíveis no valor de ${formatMoney(
          balanceScheduled.toString()
        )}`}
        description={`Confirma solicitar a antecipação do valor total a liberar? Após a confirmação, o valor será liberado em até 3 dias úteis. Processo sujeito a análise.`}
        setIsOpenDialog={handleCloseDialog}
        isOpenDialog={dialog}
        cancelButton
        cancelButtonLabel="NÃO"
      >
        <Button
          autoFocus
          hasFocus
          onClick={handleSubmitAlert}
          loading={loading}
          disabled={loading}
          width="100%"
        >
          SIM
        </Button>
      </Dialog>
    </S.Container>
  )
}

export default ToolbarCalendar
