import React, { useEffect, useRef, useState } from 'react'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import { toast } from 'react-toastify'

import {
  Button,
  Chip,
  Dialog,
  Icon,
  Icons,
  Modal
} from '@clientbase/clientbase-library'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, Skeleton, Tooltip, Typography } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import { DataService } from 'api/DataService'
import copy from 'copy-to-clipboard'
import { BillingsParams } from 'models/Billings'
import historicChangesMapper, {
  Historic
} from 'models/Historic/HistoricChanges'
import {
  amountTypeEnum,
  issueWhenEnum,
  natureOperationEnum
} from 'models/Invoice'
import { constructorRecurrences, RecurrencesParams } from 'models/Recurrences'
import moment from 'moment'
import Link from 'next/link'
import { rgba } from 'polished'
import RenderEditChargeModal from 'templates/Charge/Modals/EditRecurrence/EditRecurrenceBodyModal'
import {
  FormRecurrenceType,
  schemaFormRecurrence
} from 'templates/Charge/schemaRecurrence'
import { createTextTable } from 'templates/Customers/Modals/BodyModalNewChargeOrRecurrence/BodyModalNewChargeOrRecurrence.utils'
import { formatPhone } from 'templates/Customers/Table/utils'

import { ButtonRounded } from 'components/button'
import Can from 'components/Can'
import ChargeFineAndInterest from 'components/ChargeFineAndInterest'
import ModalChargeDetail from 'components/Modal/ModalChargeDetail'
import { Table } from 'components/Table'
import { default as DialogV2 } from 'components/v2/Dialog'

import { formatCents, formatCpfCnpj, formatMoney, onlyNumber } from 'utils'
import { priceConverter } from 'utils/FormatMoney'

import { theme } from 'styles/theme'

import { Wrapper } from '../../../templates/Customers/Drawers/GroupsDrawer/components/NewGroupForm/styles'
import { compositionDetailMapper } from '../ModalChargeDetail/components/Composition/Composition'
import NegativateDetail from '../ModalChargeDetail/components/NegativateDetail'
import { enumNature } from '../ModalInvoice/ModalInvoice.utils'
import FormLinkPlan from './components/FormLinkPlan/FormLinkPlan'
import * as S from './ModalRecurrenceDetail.styles'

type ModalRecurrenceDetailProps = {
  uuid: string
  reloadTable?: React.Dispatch<React.SetStateAction<boolean>>
  handleCloseDetailRecurrence: (value: boolean) => void
  isOpenModalRecurrenceDetail: boolean
}

const ModalRecurrenceDetail = ({
  uuid,
  reloadTable,
  handleCloseDetailRecurrence,
  isOpenModalRecurrenceDetail
}: ModalRecurrenceDetailProps) => {
  const [loadingModal, setLoadingModal] = useState<boolean>(true)
  const [reload, setReload] = useState(false)
  const [recurrence, setRecurrence] = useState<RecurrencesParams>(
    {} as RecurrencesParams
  )
  const [paymentType, setPaymentType] = useState<any[]>([])
  const [modalDialog, setModalDialog] = useState({
    isOpenDialogCancel: false,
    isOpenDetailInvoice: false,
    isOpenLinkPlan: false,
    loading: false,
    uuid: ''
  })
  const [isOpenModalEditCharge, setIsOpenModalEditCharge] =
    useState<boolean>(false)

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    setFocus,
    register,
    formState: { errors },
    reset,
    clearErrors
  } = useForm<FormRecurrenceType>({
    defaultValues: {
      daysToReport: '30',
      discountPolicy: false,
      interestPolicy: false,
      totalCycles: 1,
      iss_retention: 'no',
      frequency: 1,
      max_installments: 12,
      max_installments_without_fee: 12
    } as FormRecurrenceType,
    resolver: yupResolver(schemaFormRecurrence)
  })

  const {
    fields: itemsFields,
    append,
    remove,
    replace
  } = useFieldArray({
    control,
    name: 'items'
  })

  const [firstRender, setFirstRender] = useState(true)

  const [totalValue, setTotalValue] = useState<number>(0)

  const handleCloseEditCharge = () => {
    setIsOpenModalEditCharge(false)
    setFormData(recurrence, frequencyEnumValues, setPaymentType)
  }

  const handleOpenEditCharge = () => {
    setIsOpenModalEditCharge(true)
  }

  const getChargeEnum = (status: string) => {
    switch (status) {
      case 'boleto':
        return 'Boleto'
      case 'cash':
        return 'Dinheiro'
      case 'credit_card':
        return 'Cartão de crédito'
      case 'other':
        return 'Outros'
      case 'pix':
        return 'Pix'
      case 'transfer':
        return 'Transferência (TED/DOC)'
      case 'credit_card_recurring':
        return 'Cartão recorrente'
      case 'credit_card_installments':
        return 'Cartão parcelado'
      case 'boleto_pix':
        return 'Pix'
      default:
        window.newrelic.noticeError(
          new Error(
            `Erro ao mapear status da cobrança : ${status} (getChargeEnum)`
          )
        )
        return 'Status desconhecido'
    }
  }

  const getHoverText = (status: string) => {
    switch (status) {
      case 'pending':
        return {
          text: 'A recorrência está sendo criada'
        }
      case 'active':
        return {
          text: 'A recorrência está ativa e vai gerar as cobranças programadas'
        }
      case 'finished':
        return {
          text: 'A recorrência chegou ao final dos ciclos programados'
        }
      case 'cancelled':
        return {
          text: 'A recorrência foi cancelada e não gera cobranças novas'
        }
      case 'expiring':
        return {
          text: 'A recorrência está próxima do último ciclo programado'
        }
      default:
        window.newrelic.noticeError(
          new Error(`Erro ao mapear o texto da tooltip recorrências: ${status}`)
        )
        return {
          text: 'Status desconhecido'
        }
    }
  }

  const getStatus = (status: string) => {
    switch (status) {
      case 'cancelled':
        return {
          color: rgba(theme.palette.error[300], 0.3),
          fontColor: theme.palette.error[300],
          label: 'Cancelada'
        }
      case 'active':
        return {
          fontColor: theme.palette.primary[200],
          color: theme.palette.primary[500],
          label: 'Ativa'
        }
      case 'finished':
        return {
          color: theme.palette.primary.main,
          label: 'Finalizada'
        }
      case 'pending':
        return {
          label: 'Pendente',
          color: theme.palette.neutral[700],
          fontColor: '#777877',
          disabled: true
        }
      default:
        window.newrelic.noticeError(
          new Error(
            `Erro ao mapear status da cobrança (como ela se encontra pending, finished...): ${status} (getStatus)`
          )
        )
        return {
          label: 'Status desconhecido',
          color: theme.palette.neutral[700],
          fontColor: '#777877',
          disabled: true
        }
    }
  }
  const getStatusBilling = (status: string) => {
    switch (status) {
      case 'pending':
        return 'Pendente'
      case 'cancelled':
        return 'Cancelada'
      case 'finished':
        return 'Finalizado'
      case 'active':
        return 'Ativo'
      case 'paid':
        return 'Pago'
      case 'created_payment':
        return 'Pendente'
      case 'open_payment':
        return 'Aberta'
      case 'no_payment':
        return 'Expirada'
      case 'cancelling':
        return 'Cancelada'
      case 'expired':
        return 'Expirada'
      case 'expiring':
        return 'Expirada'
      case 'suspended':
        return 'Suspensa'
      default:
        window.newrelic.noticeError(
          new Error(
            `Erro ao mapear status da cobrança (como ela se encontra pending, canceling): ${status} (getStatus)`
          )
        )
        return 'Status desconhecido'
    }
  }

  const frequencyEnumValues = {
    weekly: 0,
    monthly: 1,
    quarterly: 2,
    semester: 3,
    annual: 4
  }

  const getFrequency = (status: string) => {
    switch (status) {
      case 'weekly':
        return 'Semanal'
      case 'monthly':
        return 'Mensal'
      case 'quarterly':
        return 'Trimestral'
      case 'semester':
        return 'Semestral'
      case 'annual':
        return 'Anual'
      default:
        window.newrelic.noticeError(
          new Error(
            `Erro ao mapear status da frequencia: ${status} (getFrequency)`
          )
        )
        return 'Status desconhecido'
    }
  }

  function setFormData(
    Recurrence: RecurrencesParams,
    frequencyEnumValues: {
      weekly: number
      monthly: number
      quarterly: number
      semester: number
      annual: number
    },
    setPaymentType: React.Dispatch<React.SetStateAction<any[]>>
  ) {
    const typeArray =
      Recurrence.paymentType.split(';').filter((type) => type.length !== 0) ||
      []
    const types = typeArray.map((type) => {
      return { label: `${getChargeEnum(type)}`, value: type }
    })

    const parseAmount = onlyNumber(formatCents(Recurrence.amount))

    reset({
      iss_retention: 'yes',
      issue_when: 'on_billing_issued',
      nature_operation: '1',
      amount_type: 'amount_paid',
      nfse_issuer_uuid: '',
      service_list_code: '',
      max_installments: 12,
      max_installments_without_fee: 12,
      interestNegativation: false,
      daysToReport: '0'
    })
    setValue('amount', parseAmount as unknown as number)

    setValue(
      'hasItemsOnDescription',
      /Itens da (recorrência|cobrança)\n\n(.*\n)*Total: R\$ \d+\.\d{2} \n/.test(
        Recurrence.description || ''
      )
    )

    setValue(
      'description',
      Recurrence.description !== null ? Recurrence.description : ''
    )
    setValue('interestNegativation', !!Recurrence.negativation.days_to_report)
    setValue(
      'daysToReport',
      Recurrence.negativation.days_to_report?.toString() || '30'
    )
    setValue('discountPolicy', Recurrence.discountPolicy)
    setValue('dueDay', Recurrence.dueDay.toString())
    setValue('interestPolicy', Recurrence.interestPolicy)
    setValue('expirationField', Recurrence.expirationField.toString())
    setValue('interestFee', Recurrence?.interestFee || '1.0')
    setValue('interestFine', Recurrence?.interestFine || '2.0')
    setValue('payments', Recurrence.paymentType.split(';'))
    setValue(
      'totalCycles',
      Recurrence.totalCycles >= 13 ? 13 : Recurrence.totalCycles
    )
    setValue(
      'totalCyclesCustom',
      Recurrence.totalCycles >= 13 ? Recurrence.totalCycles.toString() : ''
    )
    setValue('frequency', frequencyEnumValues[Recurrence.frequency])
    if (Recurrence.recurrenceCards.length !== 0) {
      setValue(
        'credit_card_uuid.label',
        `${Recurrence.recurrenceCards[0].creditCard.holderName} - ${Recurrence.recurrenceCards[0].creditCard.first4Digits} **** **** ${Recurrence.recurrenceCards[0].creditCard.last4Digits}`
      )
      setValue(
        'credit_card_uuid.value',
        Recurrence.recurrenceCards[0].creditCard.uuid
      )
    }
    setValue('interestInvoice', Recurrence?.nfsesScheduled ? true : false)
    if (Recurrence?.nfsesScheduled) {
      const {
        amount,
        amount_type,
        description,
        iss_retention,
        nature_operation,
        nfse_issuer,
        nfse_issuer_service,
        copy_description
      } = Recurrence.nfsesScheduled
      setValue('iss_retention', iss_retention ? 'yes' : 'no')
      setValue('service_list_code', nfse_issuer_service?.uuid)
      setValue('amountCustom', amount ? amount.toString() : undefined)
      setValue('descriptionInvoice', description)
      setValue('nfse_issuer_uuid', nfse_issuer?.uuid)
      setValue('issue_when', Recurrence?.nfsePolicy)
      setValue('amount_type', amount_type)
      setValue('nature_operation', enumNature[nature_operation])
      setValue('copy_description', copy_description)
    }

    replace(
      Recurrence.items?.map((item) => ({
        uuid: item.uuid,
        description: item.description || undefined,
        price: item.amount_unit,
        product: item.product
          ? {
              label: item.product?.name,
              value: item.product?.uuid
            }
          : null,
        quantity: item.quantity,
        total: item.amount_billed,
        typedValue: ''
      }))
    )

    setPaymentType(types)
  }

  useEffect(() => {
    if (!uuid && !isOpenModalRecurrenceDetail) return
    setLoadingModal(true)
    ;(async () => await getRecurrence(uuid))()
  }, [uuid, reload])

  const getRecurrence = async (uuid: string) => {
    const response = await DataService({
      type: 'GET',
      url: `/v1/recurrences/${uuid}`
    })

    const { data, status } = await response
    if (status === 200) {
      const Recurrence = constructorRecurrences(data?.record)

      setFormData(Recurrence, frequencyEnumValues, setPaymentType)

      setRecurrence(Recurrence)
      setLoadingModal(false)
    }
  }

  const handleCloseDetailInvoice = () => {
    setModalDialog((prevState) => ({
      ...prevState,
      isOpenDetailInvoice: false,
      uuid: ''
    }))

    setReload(!reload)
  }

  const handleOpenDetailInvoice = (uuid: string) => {
    setModalDialog((prevState) => ({
      ...prevState,
      isOpenDetailInvoice: true,
      uuid
    }))
  }

  const copyUuid = () => {
    copy(recurrence.uuid)
    toast.success('Identificador copiado para a área de transferência')
  }

  const itemsWatch = watch(
    itemsFields
      .map((_, index) => [
        `items.${index}.price` as const,
        `items.${index}.quantity` as const,
        `items.${index}.description` as const,
        `items.${index}.product` as const
      ])
      .flat()
  )

  const clearedErrorsRef = useRef({})

  useEffect(() => {
    const total = itemsFields.reduce((sum, _, index) => {
      const price = priceConverter(watch(`items.${index}.price`) || 0)
      const quantity = Number(watch(`items.${index}.quantity`)) || 0
      const product = price * quantity

      const description = watch(`items.${index}.description`)
      const productValue = watch(`items.${index}.product.value`)

      if (
        (description || productValue) &&
        !clearedErrorsRef.current[`items.${index}`]
      ) {
        clearErrors(`items.${index}.description`)
        clearErrors(`items.${index}.product`)
        clearErrors(`items.${index}`)
        clearedErrorsRef.current[`items.${index}`] = true
      }

      const currentTotal = watch(`items.${index}.total`)
      if (currentTotal !== product) {
        setValue(`items.${index}.total`, product, { shouldValidate: false })
      }

      return sum + product
    }, 0)

    setTotalValue(total)
  }, [itemsWatch, itemsFields, watch, clearErrors, setValue, setTotalValue])

  const hasItemsOnDescription = useWatch({
    control,
    name: 'hasItemsOnDescription'
  })

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false)
      return
    }

    if (hasItemsOnDescription) {
      const items = watch('items') || []

      const formattedText = createTextTable(items as any, totalValue, true)
      const currentDescription = watch('description') || ''

      if (!formattedText) {
        return
      }

      const newDescription = currentDescription
        ? currentDescription.concat('\n').concat(formattedText)
        : formattedText

      if (newDescription !== currentDescription) {
        setValue('description', newDescription)
      }
    } else {
      const currentDescription = watch('description') || ''
      const tableTextPattern =
        /Itens da (recorrência|cobrança)\n\n(.*\n)*Total: R\$ \d+\.\d{2} \n/

      const newDescription = currentDescription
        .replace(tableTextPattern, '')
        .trim()
      setValue('description', newDescription)
    }
  }, [hasItemsOnDescription])

  return (
    <Modal
      handleClose={() => handleCloseDetailRecurrence(false)}
      open={isOpenModalRecurrenceDetail}
      maxWidth="1150px"
      width="100%"
      height="calc(100%-200px)"
      disablePortal={false}
      {...(recurrence?.status === 'pending' ||
      recurrence?.status === 'active' ||
      recurrence?.status === 'cancelled'
        ? {
            footer: (
              <S.FooterWrapper>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  mt="20px"
                  mb="20px"
                  width="100%"
                  flexDirection="column"
                  sx={{
                    [theme.breakpoints.up(450)]: {
                      flexDirection: 'row'
                    }
                  }}
                >
                  <Box>
                    <Grid container={true} spacing={2}>
                      <Can
                        roles={[
                          'admin',
                          'user',
                          'financial_operator',
                          'operator'
                        ]}
                      >
                        <>
                          {recurrence?.status !== 'cancelled' && (
                            <Grid item={true}>
                              <Button
                                onClick={() =>
                                  handleOpenDialogCancel(recurrence?.uuid)
                                }
                                style={{
                                  backgroundColor: theme.palette.neutral[400]
                                }}
                              >
                                Cancelar Recorrência
                              </Button>
                            </Grid>
                          )}
                        </>
                      </Can>
                      <Can
                        roles={[
                          'admin',
                          'user',
                          'financial_operator',
                          'operator'
                        ]}
                      >
                        <Grid item={true}>
                          <Button onClick={handleOpenEditCharge}>
                            Editar recorrência
                          </Button>
                        </Grid>
                      </Can>
                    </Grid>
                  </Box>
                </Box>
              </S.FooterWrapper>
            )
          }
        : {})}
    >
      <Box
        sx={{
          marginTop: '-45px',
          display: 'grid'
        }}
      >
        {!loadingModal ? (
          <>
            <Grid container={true} gap={'8px'}>
              <Grid item={true} xs={6}>
                <Box justifyContent="center" minWidth="100px">
                  <Tooltip arrow title={getHoverText(recurrence.status).text}>
                    <Box
                      sx={{
                        width: '160px',
                        [theme.breakpoints.down(508)]: {
                          width: '120px'
                        }
                      }}
                    >
                      <Chip
                        key={recurrence.status}
                        label={getStatus(recurrence.status)?.label}
                        sx={{
                          background: getStatus(recurrence.status)?.color,
                          color: getStatus(recurrence.status)?.fontColor,
                          height: '25px',
                          width: '160px',
                          [theme.breakpoints.down(508)]: {
                            width: '120px'
                          }
                        }}
                      />
                    </Box>
                  </Tooltip>
                </Box>
              </Grid>
              <Grid mt={'12px'} container={true} spacing={2}>
                <Grid item={true} xs={6} sx={{ cursor: 'pointer' }}>
                  <Tooltip
                    title={`Identificador: ${recurrence.uuid}`}
                    placement="top"
                    onClick={copyUuid}
                    arrow
                    PopperProps={{
                      onClick: copyUuid,
                      sx: { cursor: 'pointer' },
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [-40, 0]
                          }
                        }
                      ]
                    }}
                  >
                    <div>
                      {renderTitle(
                        'Detalhes da recorrência',
                        'event',
                        `${theme.palette.primary.main}`
                      )}
                    </div>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <S.RecurrenceContent>
              <Grid
                display="flex"
                flexWrap={'wrap'}
                gap={1.25}
                alignItems="end"
              >
                <Typography variant="mdLight" color="#777777">
                  Valor:
                </Typography>
                <S.Separator sx={{ marginTop: '4px' }} />
                <Typography variant="mdBold" display="flex" alignItems="center">
                  {formatMoney(Number(recurrence?.amount))}
                </Typography>
              </Grid>
              <Grid
                xs={12}
                display="flex"
                flexWrap={'wrap'}
                gap={1.25}
                alignItems="end"
                margin={'10px 0px 10px 0px'}
              >
                <Typography variant="mdLight" color="#777777">
                  Dia do vencimento:
                </Typography>
                <S.Separator sx={{ marginTop: '4px' }} />
                <Typography variant="mdBold" display="flex" alignItems="center">
                  {recurrence?.dueDay}
                </Typography>
              </Grid>
              {recurrence.expirationField && (
                <Grid
                  xs={12}
                  display="flex"
                  flexWrap={'wrap'}
                  gap={1.25}
                  alignItems="end"
                  margin={'10px 0px 10px 0px'}
                >
                  <Typography variant="mdLight" color="#777777">
                    Dias para expirar:
                  </Typography>
                  <S.Separator sx={{ marginTop: '4px' }} />
                  <Typography
                    variant="mdBold"
                    display="flex"
                    alignItems="center"
                  >
                    {recurrence?.expirationField}
                  </Typography>
                </Grid>
              )}

              {recurrence?.discountPolicy &&
              recurrence?.discounts.length > 0 ? (
                recurrence?.discounts.map((discount, index) => (
                  <Box
                    display="flex"
                    gap={1.25}
                    alignItems="end"
                    margin={'10px 0px 10px 0px'}
                    key={index}
                  >
                    <Typography variant="mdLight" color="#777777">
                      Desconto {index + 1}:
                    </Typography>
                    <S.Separator sx={{ marginTop: '4px' }} />
                    <Typography
                      variant="mdBold"
                      display="flex"
                      alignItems="center"
                    >
                      <Box key={index}>
                        {discount.policy == 'percentage'
                          ? `${Number(discount.amount)
                              .toFixed(2)
                              .replace('.', ',')}%`
                          : formatMoney(Number(discount.amount))}{' '}
                        pagando até{' '}
                        {discount.days === 0
                          ? 'o dia do vencimento.'
                          : `${discount.days} dia(s) antes do vencimento.`}
                      </Box>
                    </Typography>
                  </Box>
                ))
              ) : (
                <Box
                  display="flex"
                  gap={1.25}
                  alignItems="end"
                  margin={'10px 0px 10px 0px'}
                >
                  <Typography variant="mdLight" color="#777777">
                    Desconto:
                  </Typography>
                  <S.Separator sx={{ marginTop: '4px' }} />
                  <Typography variant="mdBold">Não</Typography>
                </Box>
              )}
              <ChargeFineAndInterest
                interestPolicy={recurrence.interestFine}
                interestFine={recurrence.interestFine}
                interestFee={recurrence.interestFee}
              />
              <Grid
                display="flex"
                flexWrap={'wrap'}
                gap={1.25}
                alignItems="end"
              >
                <Typography variant="mdLight" color="#777777">
                  Frequência:
                </Typography>
                <S.Separator sx={{ marginTop: '4px' }} />
                <Typography variant="mdBold" display="flex" alignItems="center">
                  {getFrequency(recurrence?.frequency as string)}
                </Typography>
              </Grid>
              <Box
                display="flex"
                gap={1.25}
                alignItems="end"
                margin={'10px 0px 10px 0px'}
              >
                <Typography variant="mdLight" color="#777777">
                  Ciclos:
                </Typography>
                <S.Separator sx={{ marginTop: '4px' }} />
                <Typography variant="mdBold">
                  {recurrence?.currentCycle}/
                  {recurrence?.totalCycles === 0
                    ? 'Infinito'
                    : recurrence?.totalCycles}
                </Typography>
              </Box>
              {recurrence?.recurrencePlan && (
                <Grid
                  xs={12}
                  display="flex"
                  flexWrap={'wrap'}
                  gap={1.25}
                  alignItems="end"
                  margin={'10px 0px 10px 0px'}
                >
                  <Typography variant="mdLight" color="#777777">
                    Plano:
                  </Typography>
                  <S.Separator sx={{ marginTop: '4px' }} />
                  <Typography
                    variant="mdBold"
                    display="flex"
                    alignItems="center"
                  >
                    {recurrence?.recurrencePlan?.name}
                  </Typography>
                </Grid>
              )}
              <Typography variant="mdLight" color="#777777">
                Formas de pagamento:
              </Typography>
              <Grid item={true} xs={6}>
                <Box display="flex" mb="8px" mt="8px">
                  {paymentType.map((item, index) => {
                    if (!item.value) {
                      return
                    } else if (item.value === 'credit_card_recurring') {
                      return (
                        <Box
                          display="flex"
                          alignItems="center"
                          ml={index !== 0 ? '8px' : 0}
                          key={index}
                        >
                          <Chip
                            label={item.label.toUpperCase()}
                            sx={{ backgroundColor: theme.palette.neutral[400] }}
                          />
                          <Box ml="8px">
                            <Typography variant="mdBold">
                              {recurrence?.recurrenceCards?.length > 0 && (
                                <Tooltip
                                  enterTouchDelay={0}
                                  arrow
                                  placement="top"
                                  title={
                                    <div>
                                      Data do cadastro:{' '}
                                      {moment(
                                        recurrence.recurrenceCards[0]
                                          ?.createdAt,
                                        'YYYY-MM-DDTHH:mm:ss'
                                      )
                                        .format('DD/MM/YYYY HH:mm:ss')
                                        .toString()}{' '}
                                      <br />
                                      Cartão de crédito:{' '}
                                      {
                                        recurrence?.recurrenceCards[0]
                                          ?.creditCard?.first4Digits
                                      }{' '}
                                      **** ****{' '}
                                      {
                                        recurrence?.recurrenceCards[0]
                                          ?.creditCard?.last4Digits
                                      }
                                    </div>
                                  }
                                >
                                  <Wrapper>
                                    <Icon
                                      icon="creditCardChecked"
                                      sx={{
                                        color: theme.palette.neutral[200]
                                      }}
                                    />
                                  </Wrapper>
                                </Tooltip>
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      )
                    } else if (item.value === 'boleto_pix') {
                      return
                    } else {
                      return (
                        <Box key={index} ml={index !== 0 ? '8px' : 0}>
                          <Chip
                            sx={{ bgcolor: theme.palette.neutral[400] }}
                            label={item.label.toUpperCase()}
                          />
                        </Box>
                      )
                    }
                  })}
                </Box>
              </Grid>
              {recurrence?.description && (
                <Typography variant="mdLight" color="#777777">
                  Descrição:
                </Typography>
              )}
              {recurrence?.description && (
                <Typography
                  color={theme.palette.neutral[200]}
                  sx={{ whiteSpace: 'pre-line' }}
                >
                  {recurrence?.description}
                </Typography>
              )}
            </S.RecurrenceContent>
            <S.AccordionWrapper>
              <div>
                <Accordion
                  defaultExpanded
                  sx={{
                    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                    '&:before': {
                      display: 'none'
                    }
                  }}
                >
                  <AccordionSummary
                    expandIcon={<Icon icon="keyboardArrowDown" />}
                    aria-controls="payerDetails-content"
                    id="payerDetails"
                  >
                    {cardTittle(
                      'Composição da recorrência',
                      'paid',
                      `${theme.palette.primary.main}`
                    )}
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                      borderTop: '1px solid #E3E6E3'
                    }}
                  >
                    <div>
                      <Box maxHeight="200px" overflow="auto">
                        <Table
                          header={[
                            { id: 'item', label: 'Plano/Item' },
                            { id: 'amountUnit', label: 'Valor' },
                            { id: 'quantity', label: 'Quantidade' },
                            { id: 'amountBilled', label: 'Total' }
                          ]}
                          hoverInRow
                          rows={compositionDetailMapper(recurrence.items)}
                          select={{ useSelect: false }}
                          hiddePagination
                        />
                      </Box>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div>
                <Accordion
                  defaultExpanded
                  sx={{
                    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                    '&:before': {
                      display: 'none'
                    }
                  }}
                >
                  <AccordionSummary
                    expandIcon={<Icon icon="keyboardArrowDown" />}
                    aria-controls="payerDetails-content"
                    id="payerDetails"
                  >
                    {cardTittle(
                      'Detalhes do pagador',
                      'supervisorAccount',
                      `${theme.palette.primary.main}`
                    )}
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                      borderTop: '1px solid #E3E6E3'
                    }}
                  >
                    <div>
                      <Box
                        display="flex"
                        gap={1.25}
                        alignItems="end"
                        margin={'10px 0px 10px 0px'}
                      >
                        <Typography variant="mdLight" color="#777777">
                          Nome:
                        </Typography>
                        <S.Separator sx={{ marginTop: '4px' }} />
                        <Typography
                          variant="mdBold"
                          display="flex"
                          alignItems="center"
                        >
                          <Link
                            href={`/clientes/${recurrence?.customerUuid}`}
                            legacyBehavior
                          >
                            <Box
                              display={'flex'}
                              flexWrap={'wrap'}
                              gap={'8px'}
                              alignItems="center"
                            >
                              {' '}
                              <S.Link>{recurrence?.customer.name}</S.Link>
                              <Icon
                                icon="open"
                                width="21px"
                                height="21px"
                                ml="6px"
                                sx={{ cursor: 'pointer', color: '#CBCBCB' }}
                              />
                            </Box>
                          </Link>
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        gap={1.25}
                        alignItems="end"
                        margin={'10px 0px 10px 0px'}
                      >
                        <Typography variant="mdLight" color="#777777">
                          CPF/CNPJ:
                        </Typography>
                        <S.Separator sx={{ marginTop: '4px' }} />
                        <Typography
                          variant="mdBold"
                          display="flex"
                          alignItems="center"
                        >
                          {formatCpfCnpj(recurrence?.customer?.document || '')}
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        gap={1.25}
                        alignItems="end"
                        margin={'10px 0px 10px 0px'}
                      >
                        <Typography variant="mdLight" color="#777777">
                          E-mail:
                        </Typography>
                        <S.Separator sx={{ marginTop: '4px' }} />
                        <Typography
                          variant="mdBold"
                          display="flex"
                          alignItems="center"
                        >
                          {recurrence?.customer?.email || ''}
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        gap={1.25}
                        alignItems="end"
                        margin={'10px 0px 10px 0px'}
                      >
                        <Typography variant="mdLight" color="#777777">
                          Telefone:
                        </Typography>
                        <S.Separator sx={{ marginTop: '4px' }} />
                        <Typography
                          variant="mdBold"
                          display="flex"
                          alignItems="center"
                        >
                          {formatPhone(recurrence?.customer?.phone || '')}
                        </Typography>
                      </Box>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div>
                <Accordion
                  defaultExpanded
                  sx={{
                    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                    '&:before': {
                      display: 'none'
                    }
                  }}
                >
                  <AccordionSummary
                    expandIcon={<Icon icon="keyboardArrowDown" />}
                    aria-controls="payerDetails-content"
                    id="payerDetails"
                  >
                    {cardTittle(
                      'Ciclos',
                      'cached',
                      `${theme.palette.primary.main}`
                    )}
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                      borderTop: '1px solid #E3E6E3'
                    }}
                  >
                    <Grid container={true}>
                      <Grid height="300px" overflow="auto" xs={12}>
                        <Table
                          handleChangePage={() => null}
                          handleChangeRowsPerPage={() => null}
                          header={[
                            { id: 'dueDate', label: 'Vencimento' },
                            { id: 'datePaid', label: 'Pagamento' },
                            { id: 'amountBilled', label: 'Valor' },
                            { id: 'amountPaid', label: 'Valor Pago' },
                            { id: 'status', label: 'Status' },
                            { id: 'actions', label: '' }
                          ]}
                          hiddePagination={true}
                          page={1}
                          rows={convertToRows(recurrence.billings || [])}
                          rowAction={handleOpenDetailInvoice}
                          rowsPerPage={Infinity}
                          totalCount={0}
                          select={{ useSelect: false }}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
              <NegativateDetail
                recurrenceNegativationData={recurrence.negativation}
              />
              <Accordion
                sx={{
                  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                  width: '100%',
                  '&:before': {
                    display: 'none'
                  }
                }}
              >
                <AccordionSummary
                  expandIcon={<Icon icon="keyboardArrowDown" />}
                  aria-controls="changesHistory-content"
                  id="historyChanges"
                >
                  {cardTittle(
                    'Histórico',
                    'article',
                    `${theme.palette.primary.main}`
                  )}
                </AccordionSummary>
                <AccordionDetails sx={{ borderTop: '1px solid #E3E6E3' }}>
                  <Box
                    display={'flex'}
                    flexWrap={'wrap'}
                    sx={{
                      gap: '8px',
                      [theme.breakpoints.down(450)]: {
                        width: '320px'
                      }
                    }}
                  >
                    <Table
                      header={[
                        { id: 'action', label: 'Ação' },
                        { id: 'description', label: 'Descrição' },
                        { id: 'date', label: 'Data' },
                        { id: 'user', label: 'Usuário' }
                      ]}
                      rows={historicChangesMapper(
                        recurrence.historic as Historic
                      )}
                      select={{ useSelect: false }}
                      hiddePagination
                    />
                  </Box>
                </AccordionDetails>
              </Accordion>
              {recurrence?.metadata && (
                <>
                  <div>
                    <Accordion
                      sx={{
                        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                        '&:before': {
                          display: 'none'
                        }
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<Icon icon="keyboardArrowDown" />}
                        aria-controls="payerDetails-content"
                        id="payerDetails"
                      >
                        {cardTittle(
                          'Identificador interno',
                          'bookmark',
                          `${theme.palette.primary.main}`
                        )}
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                          borderTop: '1px solid #E3E6E3'
                        }}
                      >
                        {Object.keys(recurrence?.metadata).map((field) => (
                          <>
                            <div>
                              <Box
                                display="flex"
                                gap={1.25}
                                alignItems="end"
                                margin={'10px 0px 10px 0px'}
                              >
                                <Typography variant="mdLight" color="#777777">
                                  Nome do campo:
                                </Typography>
                                <S.Separator sx={{ marginTop: '4px' }} />
                                <Typography variant="mdBold">
                                  {field}
                                </Typography>
                              </Box>
                            </div>
                            <div>
                              <Box
                                display="flex"
                                gap={1.25}
                                alignItems="end"
                                margin={'10px 0px 10px 0px'}
                              >
                                <Typography variant="mdLight" color="#777777">
                                  Informação:
                                </Typography>
                                <S.Separator sx={{ marginTop: '4px' }} />
                                <Typography variant="mdBold">
                                  {recurrence?.metadata[field]}
                                </Typography>
                              </Box>
                            </div>
                          </>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </>
              )}
              {recurrence?.nfsesScheduled && (
                <div>
                  <Accordion
                    sx={{
                      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                      '&:before': {
                        display: 'none'
                      }
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<Icon icon="keyboardArrowDown" />}
                      aria-controls="payerDetails-content"
                      id="payerDetails"
                    >
                      {cardTittle(
                        'Nota fiscal',
                        'assignment',
                        `${theme.palette.primary.main}`
                      )}
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                        borderTop: '1px solid #E3E6E3'
                      }}
                    >
                      <>
                        <Box
                          display="flex"
                          gap={1.25}
                          alignItems="end"
                          margin={'10px 0px 10px 0px'}
                        >
                          <Typography variant="mdLight" color="#777777">
                            Prestador de serviço:
                          </Typography>
                          <S.Separator sx={{ marginTop: '4px' }} />
                          <Typography variant="mdBold">
                            {recurrence?.nfsesScheduled?.nfse_issuer
                              ?.legal_name || ''}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          gap={1.25}
                          alignItems="end"
                          margin={'10px 0px 10px 0px'}
                        >
                          <Typography variant="mdLight" color="#777777">
                            ISS retido:
                          </Typography>
                          <S.Separator sx={{ marginTop: '4px' }} />
                          <Typography variant="mdBold">
                            {recurrence?.nfsesScheduled?.iss_retention === true
                              ? 'Sim'
                              : 'Não'}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          gap={1.25}
                          alignItems="end"
                          margin={'10px 0px 10px 0px'}
                        >
                          <Typography variant="mdLight" color="#777777">
                            Emissão:
                          </Typography>
                          <S.Separator sx={{ marginTop: '4px' }} />
                          <Typography variant="mdBold">
                            {issueWhenEnum[recurrence?.nfsePolicy as string]}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          gap={1.25}
                          alignItems="end"
                          margin={'10px 0px 10px 0px'}
                        >
                          <Typography variant="mdLight" color="#777777">
                            Item de seviço:
                          </Typography>
                          <S.Separator sx={{ marginTop: '4px' }} />
                          <Typography variant="mdBold">
                            {
                              recurrence?.nfsesScheduled?.nfse_issuer_service
                                ?.code
                            }{' '}
                            - {''}
                            {
                              recurrence?.nfsesScheduled?.nfse_issuer_service
                                ?.description
                            }
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          gap={1.25}
                          alignItems="end"
                          margin={'10px 0px 10px 0px'}
                        >
                          <Typography variant="mdLight" color="#777777">
                            Valor da nota:
                          </Typography>
                          <S.Separator sx={{ marginTop: '4px' }} />
                          <Typography variant="mdBold">
                            {
                              amountTypeEnum[
                                recurrence?.nfsesScheduled
                                  ?.amount_type as string
                              ]
                            }{' '}
                            {recurrence?.nfsesScheduled?.amount
                              ? `- ${formatMoney(
                                  Number(recurrence?.nfsesScheduled?.amount)
                                )}`
                              : ''}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          gap={1.25}
                          alignItems="end"
                          margin={'10px 0px 10px 0px'}
                        >
                          <Typography variant="mdLight" color="#777777">
                            Natureza de operação:
                          </Typography>
                          <S.Separator sx={{ marginTop: '4px' }} />
                          <Typography variant="mdBold">
                            {
                              natureOperationEnum[
                                recurrence?.nfsesScheduled
                                  ?.nature_operation as string
                              ]
                            }
                          </Typography>
                        </Box>
                        {recurrence?.nfsesScheduled?.copy_description ? (
                          <Box
                            display="flex"
                            gap={1.25}
                            alignItems="end"
                            margin={'10px 0px 10px 0px'}
                          >
                            <Typography variant="mdLight" color="#777777">
                              Copiar descrição da cobrança
                            </Typography>
                            <S.Separator />
                            <Typography variant="mdBold">
                              <Typography variant="mdBold">
                                {recurrence?.nfsesScheduled?.copy_description
                                  ? 'Sim'
                                  : 'Não'}
                              </Typography>
                            </Typography>
                          </Box>
                        ) : (
                          recurrence.nfsesScheduled?.description && (
                            <Box display="flex" flexDirection="column">
                              <Typography
                                variant="mdLight"
                                color="#777777"
                                mb="6px"
                              >
                                Descrição:
                              </Typography>
                              <Typography variant="mdBold">
                                {recurrence.nfsesScheduled?.description}
                              </Typography>
                            </Box>
                          )
                        )}
                      </>
                    </AccordionDetails>
                  </Accordion>
                </div>
              )}
            </S.AccordionWrapper>
          </>
        ) : (
          <Box display="flex" flexDirection="column">
            <Box alignItems="center" display="flex">
              <Skeleton height="48px" variant="circular" width="48px" />
              <Box ml="8px">
                <Skeleton height="60px" variant="text" width="250px" />
              </Box>
            </Box>
            <Box width="100%">
              <Skeleton height="100px" variant="rectangular" />
            </Box>
            <hr />
            <Box alignItems="center" display="flex">
              <Skeleton height="48px" variant="circular" width="48px" />
              <Box ml="8px">
                <Skeleton height="60px" variant="text" width="250px" />
              </Box>
            </Box>
            <Box width="100%">
              <Skeleton height="100px" variant="rectangular" />
            </Box>
            <hr />
            <Box width="100%">
              <Skeleton height="300px" variant="rectangular" />
            </Box>
          </Box>
        )}

        <Dialog
          icon="description"
          title="CANCELAR RECORRÊNCIA"
          description="Confirma o cancelamento desta recorrência? As faturas em aberto não serão canceladas."
          setIsOpenDialog={handleDialogCancelClose}
          isOpenDialog={modalDialog.isOpenDialogCancel}
          cancelButton
          cancelButtonLabel="Não"
        >
          <Button
            loading={modalDialog.loading}
            disabled={modalDialog.loading}
            onClick={handleDialogCancelConfirm}
          >
            Sim
          </Button>
        </Dialog>

        <ModalChargeDetail
          handleCloseChargeDetail={handleCloseDetailInvoice}
          isOpenChargeDetail={modalDialog.isOpenDetailInvoice}
          uuid={modalDialog.uuid}
          disablePortal={false}
        />

        <Modal
          disablePortal={false}
          handleClose={handleCloseEditCharge}
          open={isOpenModalEditCharge}
          title="Editar recorrência"
          width="100%"
          maxWidth="80vw"
          maxHeight="90vh"
          height="auto"
        >
          <RenderEditChargeModal
            setFocus={setFocus}
            control={control}
            handleSubmit={handleSubmit}
            onClose={handleCloseEditCharge}
            watch={watch}
            uuid={uuid}
            currentCycle={recurrence.currentCycle}
            paymentType={paymentType}
            discounts={recurrence?.discounts}
            setValue={setValue}
            register={register}
            errors={errors}
            setError={setError as any}
            recurrence={recurrence}
            firstDueDate={recurrence.firstDueDate}
            refetch={() => {
              setReload(!reload)

              !!reloadTable && (reloadTable(true) as unknown as () => void)
            }}
            appendItem={append as any}
            itemsFields={itemsFields as any}
            removeItem={remove as any}
            totalValue={totalValue}
            nameCustomer={recurrence.customer?.name || ''}
          />
        </Modal>
      </Box>

      <DialogV2
        icon="addCircle"
        title="Vincular Plano"
        description="O valor do plano e da recorrência devem ser os mesmos!"
        setIsOpenDialog={() =>
          setModalDialog((prevState) => ({
            ...prevState,
            isOpenLinkPlan: false,
            uuid: ''
          }))
        }
        isOpenDialog={modalDialog.isOpenLinkPlan}
        maxWidth="sm"
        fullWidth
      >
        <Box width="100%">
          <FormLinkPlan
            recurrence={recurrence}
            setIsOpenDialog={() =>
              setModalDialog((prevState) => ({
                ...prevState,
                isOpenLinkPlan: false,
                uuid: ''
              }))
            }
            handleRefreshPlan={() => setReload((prevState) => !prevState)}
          />
        </Box>
      </DialogV2>
    </Modal>
  )

  function handleOpenDialogCancel(uuid: string) {
    setModalDialog((prevState) => ({
      ...prevState,
      isOpenDialogCancel: true,
      uuid
    }))
  }

  function handleDialogCancelClose() {
    setModalDialog((prevState) => ({
      ...prevState,
      isOpenDialogCancel: false
    }))
  }

  async function handleDialogCancelConfirm() {
    setModalDialog((prevState) => ({ ...prevState, loading: true }))

    const id = toast.loading('Cancelando a recorrência...')

    const response = await DataService({
      type: 'DELETE',
      url: `/v1/recurrences/${modalDialog.uuid}`
    })

    const errorMessage =
      response.error && (response.detail_error || response.message)

    toast.update(id, {
      render: response.error ? errorMessage : response.data.message,
      type: response.error ? 'error' : 'success',
      isLoading: false,
      autoClose: 10000,
      closeButton: true
    })

    setReload(!reload)

    setModalDialog((prevState) => ({
      ...prevState,
      isOpenDialogCancel: false,
      loading: false
    }))

    !!reloadTable && reloadTable((s) => !s)
  }

  function convertToRows(billings: BillingsParams[]) {
    const rows = billings?.map((billing: BillingsParams) => {
      const dueDate = moment(billing.dueDate, 'YYYY-MM-DD')
        .format('DD/MM/YYYY')
        .toString()

      const paidDate = moment(billing.datePaid, 'YYYY-MM-DD')
        .format('DD/MM/YYYY')
        .toString()

      return {
        uuid: billing.uuid,
        dueDate,
        datePaid: billing.datePaid ? paidDate : '',
        amountBilled: formatMoney(billing.amountBilled),
        amountPaid: formatMoney(billing.amountPaid),
        status: getStatusBilling(billing.status || ''),
        action: (
          <Can roles={['admin', 'financial_operator', 'operator', 'user']}>
            <Tooltip
              enterTouchDelay={0}
              arrow={true}
              placement="top"
              title="Detalhes cobrança"
            >
              <Box>
                <ButtonRounded
                  onClick={() => handleOpenDetailInvoice(billing.uuid || '')}
                  variant="transparent"
                >
                  <Icon
                    icon="settings"
                    sx={{ color: theme.palette.neutral[200] }}
                  />
                </ButtonRounded>
              </Box>
            </Tooltip>
          </Can>
        )
      }
    })

    return rows
  }

  function cardTittle(label: string, icon: Icons, iconColor?: string) {
    return (
      <Box display="flex" alignItems={'center'}>
        <Box display={'flex'} alignItems={'center'} gap={'8px'}>
          <S.ContainerIcon>
            {' '}
            <Icon icon={icon} colorSX={iconColor} />
          </S.ContainerIcon>
          <Typography variant="mdLight">{label}</Typography>
        </Box>
      </Box>
    )
  }

  function renderTitle(label: string, icon: Icons, iconColor?: string) {
    return (
      <Box alignItems="center" display="flex" gap={'8px'}>
        <S.ContainerIcon>
          <Icon icon={icon} colorSX={iconColor} />
        </S.ContainerIcon>
        <Box>
          <Typography variant="mdLight">{label}</Typography>
        </Box>
        <hr />
      </Box>
    )
  }
}

export default ModalRecurrenceDetail
