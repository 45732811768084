import { useEffect, useState } from 'react'
import { Controller, FieldValues, Path, PathValue } from 'react-hook-form'

import {
  Autocomplete,
  Button,
  Icon,
  Icons,
  Loader,
  Select,
  Textfield
} from '@clientbase/clientbase-library'
import {
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  lighten,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material'
import { DataService } from 'api/DataService'
import { AutoCompleteBillingsParams } from 'models/Billings/AutoComplete'
import Image from 'next/image'
import { daysDueDate } from 'templates/Charge/Modals/EditRecurrence/templates/Discounts.utils'
import { expirationOptions } from 'templates/Charge/Modals/EditRecurrence/templates/ExpirationOptions.utils'

import AutocompleteCustomers from 'components/AutocompleteCustomers'
import { ProductAutocomplete } from 'components/AutocompleteProducts/AutocompleteProducts'
import { DatePicker } from 'components/DatePicker'
import { GradientOpenWithIcon } from 'components/GradientIcon/GradientIcon'

import { currency, formatMoney } from 'utils'

import { theme } from 'styles/theme'

import { createTextTable } from '../../BodyModalNewChargeOrRecurrence.utils'
import { InfosProps } from './Infos.interface'

type Record = {
  label: string
  value: string
  product: ProductAutocomplete
}

const Infos = <T extends FieldValues>({
  control,
  isCharge,
  nameCustomer,
  setCustomerUuid,
  edit,
  setRecieverType,
  recieverType,
  setGroupUuid,
  limit_days_for_payment,
  watch,
  appendItem,
  itemsFields,
  removeItem,
  totalValue,
  setValue,
  errors
}: InfosProps<T>) => {
  const isMobile = useMediaQuery('(max-width: 960px )')

  const [isFirstRender, setIsFirstRender] = useState(0)
  const [groups, setGroups] = useState<AutoCompleteBillingsParams[]>([])
  const [products, setProducts] = useState<Record[]>([])
  const [hasFetched, setHasFetched] = useState<boolean>(false)

  const getAutocompleteProducts = async () => {
    setHasFetched(false)
    const responseProductsAutocomplete = await DataService({
      type: 'GET',
      url: '/v1/products_autocomplete'
    })
    if (responseProductsAutocomplete.status === 200) {
      const productsList = responseProductsAutocomplete.data.records.map(
        (record: ProductAutocomplete) => ({
          label: `${record.name}|${record.amount}`,
          value: record.uuid,
          product: record
        })
      )

      setProducts([{ label: '', value: 'custom' }, ...productsList])
    }
    setHasFetched(true)
  }

  const getAutocompleteGroups = async () => {
    const responseGroupsAutocomplete = await DataService({
      url: '/v1/customer_groups/autocomplete',
      type: 'GET'
    })

    if (responseGroupsAutocomplete.status === 200) {
      const groupsList: AutoCompleteBillingsParams[] =
        responseGroupsAutocomplete.data.records.map(
          (record: { name: string; uuid: string }) => ({
            label: record.name,
            value: record.uuid
          })
        )

      setGroups(groupsList)
    }
  }

  useEffect(() => {
    if (watch('recieverType' as Path<T>) == 'group') {
      getAutocompleteGroups()
    }
  }, [watch('recieverType' as Path<T>)])

  useEffect(() => {
    getAutocompleteProducts()
  }, [])

  const updateDescriptionWithItems = () => {
    if (watch('hasItemsOnDescription' as Path<T>)) {
      const items = watch('items' as Path<T>) || []
      // Verifica se existem itens válidos (com preço e quantidade)
      const hasValidItems = items.some(
        (item: any) => item.price && item.quantity && item.total > 0
      )

      if (!hasValidItems) {
        // Se não houver itens válidos, apenas remove a tabela existente
        const currentDescription = watch('description' as Path<T>) || ''
        const tableTextPattern =
          /Itens da (recorrência|cobrança)\n\n(.*\n)*Total: R\$ \d+\.\d{2} \n/

        const descriptionWithoutTable = (currentDescription as string)
          .replace(tableTextPattern, '')
          .trim()
        setValue(
          'description' as Path<T>,
          descriptionWithoutTable as PathValue<T, Path<T>>
        )

        return
      }

      const currentTotal = items.reduce(
        (sum: number, item: any) => sum + (Number(item.total) || 0),
        0
      )
      const formattedText = createTextTable(
        items as any,
        currentTotal,
        !isCharge
      )

      if (formattedText) {
        const currentDescription = watch('description' as Path<T>) || ''
        const tableTextPattern =
          /Itens da (recorrência|cobrança)\n\n(.*\n)*Total: R\$ \d+\.\d{2} \n/
        const descriptionWithoutTable = (currentDescription as string)
          .replace(tableTextPattern, '')
          .trim()
        const newDescription = descriptionWithoutTable
          ? descriptionWithoutTable.concat('\n').concat(formattedText)
          : formattedText
        setValue(
          'description' as Path<T>,
          newDescription as PathValue<T, Path<T>>
        )
      }
    } else {
      const currentDescription = watch('description' as Path<T>) || ''
      const tableTextPattern =
        /Itens da (recorrência|cobrança)\n\n(.*\n)*Total: R\$ \d+\.\d{2} \n/
      const descriptionWithoutTable = (currentDescription as string)
        .replace(tableTextPattern, '')
        .trim()
      setValue(
        'description' as Path<T>,
        descriptionWithoutTable as PathValue<T, Path<T>>
      )
    }
  }

  // Watch for changes in items

  useEffect(() => {
    if (isFirstRender <= 2) {
      setIsFirstRender((prev) => prev + 1)
      return
    }

    if (watch('hasItemsOnDescription' as Path<T>)) {
      setTimeout(() => {
        updateDescriptionWithItems()
      }, 100)
    }
  }, [watch('hasItemsOnDescription' as Path<T>)])

  return (
    <>
      {edit && nameCustomer && (
        <Textfield placeholder="Buscar cliente" value={nameCustomer} disabled />
      )}

      {!edit && (
        <>
          <Controller
            name={'recieverType' as Path<T>}
            control={control}
            render={({ field }) => (
              <RadioGroup
                aria-labelledby="group-type"
                value={field.value}
                onChange={(event) => {
                  field.onChange(event)
                  !!setRecieverType &&
                    setRecieverType(
                      event.target.value as 'customer' | 'group' | undefined
                    )
                }}
                row
                sx={{ gap: 2 }}
              >
                <FormControlLabel
                  value="customer"
                  control={<Radio />}
                  label="Cliente"
                />
                <FormControlLabel
                  value="group"
                  control={<Radio />}
                  label="Grupo"
                />
              </RadioGroup>
            )}
          />

          {recieverType === 'customer' ? (
            <Controller
              control={control}
              name={'customer' as Path<T>}
              render={({
                field: { onChange, value, ref },
                fieldState: { error }
              }) => {
                const errorTyped = error as any
                return (
                  <AutocompleteCustomers
                    onChange={(_: React.SyntheticEvent, value: unknown) => {
                      const option = value as AutoCompleteBillingsParams
                      setCustomerUuid && setCustomerUuid(option?.value)
                      onChange(option ?? { value: '', label: '' })
                    }}
                    inputRef={ref}
                    value={value}
                    errorMessage={errorTyped?.label?.message}
                    placeholder="Buscar cliente"
                    nameCustomer={nameCustomer}
                  />
                )
              }}
            />
          ) : null}

          {recieverType === 'group' && (
            <Controller
              control={control}
              name={'group' as Path<T>}
              render={({
                field: { onChange, value },
                fieldState: { error }
              }) => {
                const errorTyped = error as any
                return (
                  <Autocomplete
                    value={value}
                    onChange={(_, value) => {
                      const option = value as AutoCompleteBillingsParams
                      setGroupUuid && setGroupUuid(option.value)
                      onChange(option ?? { value: '', label: '' })
                    }}
                    renderOption={(props, option) => {
                      const optionTyped = option as AutoCompleteBillingsParams
                      return (
                        <Box component="li" {...props} key={optionTyped.value}>
                          {optionTyped.label}
                        </Box>
                      )
                    }}
                    options={groups || []}
                    renderInput={(params) => (
                      <Textfield
                        helperText={errorTyped?.label?.message as any}
                        error={!!errorTyped?.label?.message as any}
                        placeholder="Buscar grupo"
                        name="group"
                        {...params}
                      />
                    )}
                  />
                )
              }}
            />
          )}
        </>
      )}

      <Stack gap="15px" my="10px">
        {!!itemsFields &&
          itemsFields.map((field, index) => (
            <Box
              key={field.uuid}
              sx={{
                display: 'grid',
                gap: '10px',
                gridTemplateColumns: !isMobile ? '6.5fr 5.5fr' : '1fr'
              }}
            >
              <Autocomplete
                id="product"
                onChange={(_: React.SyntheticEvent, value: unknown) => {
                  const typedValue = value as {
                    label: string
                    value: string
                    product: ProductAutocomplete
                  }
                  if (!typedValue) return

                  if (typedValue.value === 'custom') {
                    setValue(
                      `items.${index}.description` as Path<T>,
                      watch(
                        `items.${index}.typedValue` as Path<T>
                      ) as PathValue<T, Path<T>>
                    )
                    setValue(
                      `items.${index}.product` as Path<T>,
                      undefined as PathValue<T, Path<T>>
                    )
                  } else {
                    setValue(
                      `items.${index}.product` as Path<T>,
                      {
                        label: typedValue.product.name,
                        value: typedValue.product.uuid
                      } as PathValue<T, Path<T>>
                    )
                    setValue(
                      `items.${index}.price` as Path<T>,
                      typedValue.product.amount as PathValue<T, Path<T>>
                    )
                    setValue(
                      `items.${index}.description` as Path<T>,
                      '' as PathValue<T, Path<T>>
                    )
                  }

                  setTimeout(() => {
                    updateDescriptionWithItems()
                  }, 0)
                }}
                value={
                  watch(`items.${index}.product` as Path<T>) ||
                  (watch(`items.${index}.description` as Path<T>) && {
                    label: watch(`items.${index}.typedValue` as Path<T>), // Use typedValue for dynamic updates
                    value: 'custom'
                  }) ||
                  null
                }
                onBlur={() => {
                  if (
                    !watch(`items.${index}.typedValue` as Path<T>) ||
                    (!!watch(`items.${index}.product` as Path<T>)?.value &&
                      products.filter(
                        (product) =>
                          product?.product?.name ==
                          watch(`items.${index}.typedValue` as Path<T>)
                      ).length > 0)
                  )
                    return

                  setValue(
                    `items.${index}.description` as Path<T>,
                    watch(`items.${index}.typedValue` as Path<T>) as PathValue<
                      T,
                      Path<T>
                    >
                  )
                  setValue(
                    `items.${index}.product` as Path<T>,
                    undefined as PathValue<T, Path<T>>
                  )
                }}
                options={products || []}
                autoHighlight
                filterOptions={(options, { inputValue }) => {
                  if (!inputValue.trim()) {
                    return options
                  }
                  const matchingProducts = options.filter(
                    (option) =>
                      (option as Record).value !== 'custom' &&
                      (option as Record).label
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                  )
                  const customOption = {
                    label: `${inputValue}`,
                    value: 'custom',
                    product: { name: inputValue, uuid: 'custom' }
                  }
                  if (matchingProducts.length > 0) {
                    return [...matchingProducts, customOption] // Include the custom option alongside matching products
                  }
                  return [customOption]
                }}
                autoSelect={false}
                selectOnFocus={false}
                freeSolo={true}
                getOptionLabel={(option) => {
                  const optionTyped = option as Record

                  if (!optionTyped) return ''

                  // Se for um item avulso, usa a descrição
                  if (optionTyped.value === 'custom') {
                    return (
                      optionTyped.label ||
                      watch(`items.${index}.description` as Path<T>) ||
                      ''
                    )
                  }

                  // Se for um item novo (não tem `product`), retorna o label direto
                  if (!optionTyped.product) {
                    return optionTyped.label || ''
                  }

                  // Caso padrão: exibe o nome do produto
                  return optionTyped.product.name || ''
                }}
                isOptionEqualToValue={(option, value) =>
                  (option as Record).value === (value as Record).value
                }
                getOptionDisabled={(option) =>
                  itemsFields
                    .filter(
                      (item) => item.product !== null || !!item.product?.value
                    )
                    .map((item) => item?.product?.value)
                    .includes((option as Record).value)
                }
                noOptionsText={
                  hasFetched ? 'Não há produtos disponíveis.' : <Loader />
                }
                onInputChange={(_, newInputValue) => {
                  setValue(
                    `items.${index}.typedValue` as Path<T>,
                    newInputValue as PathValue<T, Path<T>>
                  )
                }}
                groupBy={(option) => {
                  return (option as Record).value === 'custom'
                    ? 'Custom'
                    : 'Products'
                }}
                renderGroup={(params) => {
                  return (
                    <div key={params.key}>
                      {params.group === 'Custom' && (
                        <div>{params.children}</div>
                      )}
                      {params.group === 'Products' && (
                        <div>{params.children}</div>
                      )}
                    </div>
                  )
                }}
                renderOption={(props, option) => {
                  const optionTyped = option as Record
                  const product = optionTyped?.product

                  if (optionTyped.value === 'custom') {
                    if (watch(`items.${index}.typedValue` as Path<T>) === '')
                      return null

                    return (
                      <MenuItem
                        key={product?.uuid}
                        value={product?.uuid}
                        {...props}
                        sx={{ display: 'flex', gap: 2 }}
                      >
                        <Box
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                          p="12px"
                          alignItems="center"
                        >
                          <Box display="flex" alignItems="center" gap="12px">
                            <Box
                              bgcolor="#F8F8F8"
                              p={1}
                              borderRadius={1}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                              }}
                            >
                              <GradientOpenWithIcon
                                icon={'add'}
                                width="32px"
                                height="32px"
                                colors={['#00EC89', '#00F4F5', '#00FEFF']}
                              />
                            </Box>
                            <Box>
                              <Typography variant="mdLight">
                                Adicionar{' '}
                                <Typography variant="mdBold">
                                  {watch(
                                    `items.${index}.typedValue` as Path<T>
                                  )}
                                </Typography>{' '}
                                como item avulso
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </MenuItem>
                    )
                  }

                  return (
                    <MenuItem
                      key={product?.uuid}
                      value={product?.uuid}
                      {...props}
                      sx={{ display: 'flex', gap: 2 }}
                    >
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                        p="12px"
                        alignItems="center"
                      >
                        <Box display="flex" alignItems="center" gap="12px">
                          {product?.document_file?.thumbnail_url ? (
                            <Box
                              borderRadius="5px"
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                              }}
                            >
                              <Image
                                style={{ borderRadius: '5px' }}
                                height={52}
                                width={52}
                                src={product?.document_file?.thumbnail_url}
                                alt="imagem do plano"
                              />
                            </Box>
                          ) : (
                            <Box
                              bgcolor="#F8F8F8"
                              p={1}
                              borderRadius={1}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                              }}
                            >
                              <GradientOpenWithIcon
                                icon={product?.icon as Icons}
                                width="32px"
                                height="32px"
                                colors={['#00EC89', '#00F4F5', '#00FEFF']}
                              />
                            </Box>
                          )}
                          <Box>
                            <Typography variant="smBold">
                              {product?.name}
                            </Typography>
                            <Box display="flex">
                              <Typography variant="smLight" color="#828282">
                                Valor:{' '}
                                <b>R$ {currency(product?.amount || '')}</b>
                              </Typography>
                              <Typography variant="smLight" color="#828282">
                                , Número de parcelas:{' '}
                                <strong>
                                  {product?.total_cycles
                                    ? `${product?.total_cycles}x`
                                    : 'infinito'}
                                </strong>
                              </Typography>
                              {!!product?.due_day && (
                                <Typography variant="smLight" color="#828282">
                                  , Dia do vencimento:{' '}
                                  <strong>{product?.due_day}</strong>
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </MenuItem>
                  )
                }}
                renderInput={(params) => (
                  <Textfield
                    label="Escolha um Plano ou escreva o que está sendo cobrado"
                    placeholder="Selecione ou digite aqui"
                    helperText={
                      (errors?.items?.[index]?.product ||
                        errors?.items?.[index]?.description ||
                        errors?.items?.[index]?.message) as string
                    }
                    error={
                      !!errors?.items?.[index]?.product ||
                      !!errors?.items?.[index]?.description ||
                      !!errors?.items?.[index]?.message
                    }
                    sx={{
                      '& .MuiFormHelperText-root': {
                        color: '#4C000C',
                        height: '0px'
                      }
                    }}
                    {...params}
                  />
                )}
              />

              <Box
                sx={{
                  display: 'grid',
                  gap: '10px',
                  gridTemplateColumns: '1.5fr 1fr 1fr 40px',
                  alignItems: 'center'
                }}
              >
                <Controller
                  control={control}
                  name={`items.${index}.price` as Path<T>}
                  render={({ field: { onChange, value, ref } }) => (
                    <Textfield
                      customStartAdornment={
                        <Typography mr="6px" variant="mdLight">
                          R$
                        </Typography>
                      }
                      inputRef={ref}
                      helperText={
                        errors?.items?.[index]?.price?.message as string
                      }
                      error={!!errors?.items?.[index]?.price}
                      label="Valor"
                      mask="money"
                      name="price"
                      placeholder="Digite o valor"
                      startAdornment={true}
                      onChange={(e) => {
                        onChange(e)

                        setTimeout(() => {
                          updateDescriptionWithItems()
                        }, 0)
                      }}
                      value={currency(value || '0')}
                      sx={{
                        '& .MuiFormHelperText-root': {
                          height: '0px'
                        }
                      }}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name={`items.${index}.quantity` as Path<T>}
                  render={({
                    field: { onChange, value },
                    fieldState: { error }
                  }) => (
                    <Textfield
                      label="Qtde."
                      type="number"
                      placeholder="Quantidade"
                      inputProps={{ min: 1 }}
                      helperText={error?.message}
                      error={!!error?.message}
                      onChange={(e) => {
                        onChange(e)

                        setTimeout(() => {
                          updateDescriptionWithItems()
                        }, 0)
                      }}
                      value={value}
                      sx={{
                        '& .MuiFormHelperText-root': {
                          height: '0px'
                        }
                      }}
                    />
                  )}
                />

                <Typography mt={3}>
                  {formatMoney(watch(`items.${index}.total` as Path<T>))}
                </Typography>

                <Box
                  sx={{
                    display: 'grid',
                    placeItems: 'center',
                    pt: 3
                  }}
                >
                  <IconButton
                    disabled={itemsFields.length == 1}
                    onClick={() => {
                      if (removeItem) {
                        removeItem(index)
                        setTimeout(() => {
                          updateDescriptionWithItems()
                        }, 0)
                      }
                    }}
                  >
                    <Icon
                      icon="delete"
                      colorSX={
                        itemsFields.length != 1
                          ? '#777877'
                          : lighten('#777877', 0.4)
                      }
                    />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          ))}

        <Button
          onClick={() =>
            !!appendItem &&
            appendItem({
              description: '',
              price: 0,
              total: 0,
              typedValue: '',
              quantity: 1,
              uuid: ''
            })
          }
          variantButton="text"
          sx={{ alignSelf: 'start', width: 'fit-content', mb: '-8px' }}
        >
          <Stack direction="row" gap="5px" alignItems="center">
            <Icon
              icon="add"
              sx={{ color: theme.palette.neutral[400], fontSize: '18px' }}
            />
            <Typography
              sx={{
                color: theme.palette.neutral[400],
                fontWeight: '600',
                fontSize: '14px'
              }}
            >
              Adicionar item
            </Typography>
          </Stack>
        </Button>

        <Box sx={{ height: '1px', bgcolor: theme.palette.neutral[700] }}></Box>

        <Stack direction="row" justifyContent="flex-end">
          <Stack direction="row" gap="50px">
            <Typography variant="mdBold" color={theme.palette.neutral[400]}>
              Total:
            </Typography>
            <Typography variant="mdBold" color={theme.palette.neutral[100]}>
              R$ {currency(totalValue || 0)}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Grid container={true} mt="0px" spacing={2}>
        <Grid item={true} xs={6}>
          {!(edit && !isCharge) ? (
            <Controller
              control={control}
              name={'dueDate' as Path<T>}
              render={({
                field: { onChange, value, ref },
                fieldState: { error }
              }) => {
                return (
                  <DatePicker
                    inputRef={ref}
                    helperText={error?.message}
                    error={!!error?.message}
                    label={
                      isCharge
                        ? 'Qual a data de vencimento?'
                        : 'Qual o vencimento da primeira fatura?'
                    }
                    minDate={new Date()}
                    maxDate={
                      new Date(
                        new Date().setFullYear(new Date().getFullYear() + 10)
                      )
                    }
                    onChange={(newValue) => {
                      onChange(newValue)
                      setValue(
                        'dueDate' as Path<T>,
                        newValue as PathValue<T, Path<T>>
                      )
                    }}
                    renderInput={(props: any) => (
                      <Textfield {...props} name="dueDate" />
                    )}
                    toolbarPlaceholder="Escolha a data de vencimento"
                    value={value || null}
                  />
                )
              }}
            />
          ) : (
            <Controller
              control={control}
              name={'dueDay' as Path<T>}
              render={({
                field: { onChange, value, ref },
                fieldState: { error }
              }) => {
                return (
                  <Select
                    inputRef={ref}
                    helperText={error?.message}
                    error={!!error?.message}
                    fullWidth={true}
                    label="Informe o dia de vencimento da recorrência:"
                    name="dueDay"
                    onChange={(e) => {
                      onChange(e)
                      setValue(
                        'dueDay' as Path<T>,
                        e.target.value as PathValue<T, Path<T>>
                      )
                    }}
                    value={value}
                  >
                    {daysDueDate.map(({ label, value }, index) => (
                      <MenuItem key={index} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                )
              }}
            />
          )}
        </Grid>
        <Grid item={true} xs={6}>
          <Controller
            control={control}
            name={'expirationField' as Path<T>}
            render={({
              fieldState: { error },
              field: { onChange, value, ref }
            }) => (
              <Select
                inputRef={ref}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 400
                    }
                  }
                }}
                label={`Expiração da cobrança`}
                helperText={error?.message}
                error={!!error?.message}
                fullWidth
                name="expirationField"
                defaultValue={limit_days_for_payment}
                value={parseInt(value) || 1}
                displayEmpty
                onChange={onChange}
              >
                {' '}
                {expirationOptions.map((item) => (
                  <MenuItem key={item.label} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default Infos
