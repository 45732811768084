import React from 'react'

import { Icon } from '@clientbase/clientbase-library'
import { Box, IconButton, Link } from '@mui/material'

import { theme } from 'styles/theme'

const Help = () => {
  return (
    <Link
      target="_blank"
      style={{ color: 'white' }}
      href={'https://ajuda.basedeclientes.com.br/pt-BR/'}
    >
      <IconButton>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="11px"
          width="11px"
          padding={'5px'}
          borderRadius="50%"
          bgcolor={theme.palette.neutral[400]}
        >
          <Icon colorSX="white" height={'13'} width={'13'} icon="faHelp" />
        </Box>
      </IconButton>
    </Link>
  )
}

export default Help
