import React, { useContext } from 'react'

import { Icon } from '@clientbase/clientbase-library'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box
} from '@mui/material'
import historicChangesMapper, {
  Historic as HistoricType
} from 'models/Historic/HistoricChanges'

import { Table } from 'components/Table'

import { theme } from 'styles/theme'

import { ModalChargeDetailContext } from '../../context'
import { cardTittle } from '../../ModalChargeDetail.utils'

const Historic: React.FC = () => {
  const { invoice } = useContext(ModalChargeDetailContext)
  return (
    <Accordion
      sx={{
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
        width: '100%',
        '&:before': {
          display: 'none'
        }
      }}
    >
      <AccordionSummary
        expandIcon={<Icon icon="keyboardArrowDown" />}
        aria-controls="changesHistory-content"
        id="historyChanges"
      >
        {cardTittle('Histórico', 'article', `${theme.palette.primary.main}`)}
      </AccordionSummary>
      <AccordionDetails sx={{ borderTop: '1px solid #E3E6E3' }}>
        <Box
          display="flex"
          flexWrap="wrap"
          sx={{
            gap: '8px',
            [theme.breakpoints.down(450)]: {
              maxWidth: '40vh',
              width: 'auto'
            }
          }}
        >
          <Table
            header={[
              { id: 'action', label: 'Ação' },
              { id: 'description', label: 'Descrição' },
              { id: 'date', label: 'Data' },
              { id: 'user', label: 'Usuário' }
            ]}
            rows={historicChangesMapper(invoice?.historic as HistoricType)}
            select={{ useSelect: false }}
            hiddePagination
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

export default Historic
