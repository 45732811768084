import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { Button, Textfield } from '@clientbase/clientbase-library'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from '@mui/material'
import { DataService } from 'api/DataService'
import { RecurrencesParams } from 'models/Recurrences'
import * as yup from 'yup'

import AutocompleteProducts, {
  ProductAutocomplete
} from 'components/AutocompleteProducts/AutocompleteProducts'
import Dialog from 'components/v2/Dialog'

import { priceConverter } from 'utils/FormatMoney'
import { toastProps } from 'utils/types/toast-props'

import { Wrapper } from './FormLinkPlan.styles'

export interface FormLinkPlanProps {
  recurrence?: RecurrencesParams
  handleRefreshPlan?: () => void
  setIsOpenDialog?: () => void
}

export interface DialogConfirm {
  open: boolean
  dataForm?: FormLinkPlansSchema
}

export const schemaFormLinkPlans = yup.object({
  uuid: yup.string().required('Produto é obrigatório.'),
  quantity: yup.number().required('Quantidade é obrigatória.').nullable()
})

export type FormLinkPlansSchema = yup.InferType<typeof schemaFormLinkPlans>

const FormLinkPlan: React.FC<FormLinkPlanProps> = ({
  recurrence,
  handleRefreshPlan,
  setIsOpenDialog
}) => {
  const [product, setProduct] = useState<ProductAutocomplete>(
    {} as ProductAutocomplete
  )
  const [loading, setLoading] = useState<boolean>(false)
  const [confirm, setConfirm] = useState<DialogConfirm>({
    open: false,
    dataForm: undefined
  })
  const { control, watch, handleSubmit, setValue } =
    useForm<FormLinkPlansSchema>({
      resolver: yupResolver(schemaFormLinkPlans)
    })

  const openDialog = (dataForm: FormLinkPlansSchema) => {
    if (
      dataForm.quantity *
        priceConverter(product?.amount as unknown as number) !==
      priceConverter(recurrence?.amount as unknown as number)
    ) {
      toast.error(
        'O valor do produto x quantidade não pode ser diferente do valor da Recorrência'
      )
      return
    }

    setConfirm({ open: true, dataForm: dataForm })
  }

  const onSubmit = async () => {
    setLoading(true)
    const id = toast.loading('Vinculando a recorrência ao plano...')
    const data = {
      product_uuid: confirm?.dataForm?.uuid,
      product_quantity: confirm?.dataForm?.quantity
    }

    const response = await DataService({
      url: `/v3/recurrences/${recurrence?.uuid}/link_product`,
      data,
      type: 'PATCH'
    })

    const errorMessage: string =
      response.error && (response.detail_error || response.message)

    toast.update(id, {
      render: response.error ? errorMessage : response.data.message,
      type: response.error ? 'error' : 'success',
      ...toastProps
    })

    if (response.status === 200) {
      !!handleRefreshPlan && handleRefreshPlan()
      !!setIsOpenDialog && setIsOpenDialog()
    }

    setLoading(false)
  }

  const handleCloseDialog = () => {
    !!setIsOpenDialog && setIsOpenDialog()
  }

  useEffect(() => {
    if (watch('uuid')) {
      setValue(
        'quantity',
        priceConverter(recurrence?.amount as unknown as number) /
          priceConverter(product.amount as unknown as number)
      )
    }
  }, [watch('uuid')])

  return (
    <Wrapper>
      <form className="form" onSubmit={handleSubmit(openDialog)}>
        <Box
          sx={{ display: 'grid', gridTemplateColumns: '3fr 1fr', gap: '8px' }}
        >
          <Controller
            control={control}
            name="uuid"
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <AutocompleteProducts
                  value={value}
                  onChange={(
                    _: React.SyntheticEvent,
                    value: {
                      label: string
                      value: string
                      product: ProductAutocomplete
                    }
                  ) => {
                    if (!value) return
                    setProduct(value.product)
                    onChange(value.value)
                  }}
                  errorMessage={error?.message}
                  placeholder="Selecione um produto para vincular"
                  filterByAmount={Number(recurrence?.amount)}
                />
              )
            }}
          />
          <Controller
            control={control}
            name="quantity"
            render={({ field, fieldState: { error } }) => {
              return (
                <Textfield
                  {...field}
                  placeholder="Quantidade"
                  helperText={error?.message}
                  error={!!error?.message}
                />
              )
            }}
          />
        </Box>
        <Box display="flex" gap="8px">
          <Button fullWidth onClick={handleCloseDialog}>
            Cancelar
          </Button>
          <Button fullWidth type="submit">
            Vincular
          </Button>
        </Box>
      </form>

      <Dialog
        icon="addCircle"
        title="Vincular Produto"
        description={`Confirma vincular a recorrência ao produto: ${product.name}`}
        setIsOpenDialog={() => setConfirm({ open: false, dataForm: undefined })}
        isOpenDialog={confirm.open}
        maxWidth="sm"
        fullWidth
      >
        <Box display="flex" gap="8px">
          <Button
            onClick={() => setConfirm({ open: false, dataForm: undefined })}
            fullWidth
            disabled={loading}
          >
            Não
          </Button>
          <Button
            onClick={onSubmit}
            fullWidth
            disabled={loading}
            loading={loading}
          >
            Sim
          </Button>
        </Box>
      </Dialog>
    </Wrapper>
  )
}

export default FormLinkPlan
