import { FieldValues, UseFormSetValue } from 'react-hook-form'

export const setValues = <T extends FieldValues>(
  values: T,
  setValue: UseFormSetValue<T>
) => {
  Object.keys(values).forEach((key) => {
    setValue(key as any, values[key])
  })
}
