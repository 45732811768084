import React, { useContext } from 'react'
import { toast } from 'react-toastify'

import { Chip, Icon } from '@clientbase/clientbase-library'
import { Box, Tooltip, Typography } from '@mui/material'
import copy from 'copy-to-clipboard'
import moment from 'moment'

import ChargeFineAndInterest from 'components/ChargeFineAndInterest'

import { formatMoney } from 'utils'
import { formatDate, stringToDate } from 'utils/FormatDate'

import { theme } from 'styles/theme'

import { ModalChargeDetailContext } from '../../context'
import { DiscountPolicyEnum, renderTitle } from '../../ModalChargeDetail.utils'
import { S } from './'

const BillingDetails: React.FC = () => {
  const { invoice, updateModal, paymentType } = useContext(
    ModalChargeDetailContext
  )

  const handleOpenDetailRecurrence = (uuid: string) => {
    updateModal((prevState) => ({
      ...prevState,
      uuidRecurrenceDetail: uuid,
      isOpenModalRecurrenceDetail: true
    }))
  }

  const copyUuid = () => {
    copy(invoice.uuid)
    toast.success('Identificador copiado para a área de transferência')
  }

  return (
    <S.Wrapper>
      <Box>
        <Box
          alignItems="center"
          display="flex"
          gap={2}
          sx={{ cursor: 'pointer' }}
        >
          <Tooltip
            title={`Identificador: ${invoice.uuid}`}
            placement="top"
            onClick={copyUuid}
            arrow
            PopperProps={{
              onClick: copyUuid,
              sx: { cursor: 'pointer' },
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [40, 0]
                  }
                }
              ]
            }}
          >
            <div>
              {renderTitle(
                'Detalhes da cobrança',
                'event',
                `${theme.palette.primary.main}`
              )}
            </div>
          </Tooltip>
        </Box>
        <S.ChargeSession>
          <Box
            display="flex"
            flexWrap="wrap"
            gap="1.25"
            alignItems="end"
            margin="10px 0px 10px 0px"
          >
            <Typography variant="mdLight" color="#777777">
              Valor:
            </Typography>
            <S.Separator sx={{ marginTop: '4px' }} />
            <Typography variant="mdBold" display="flex" alignItems="center">
              {formatMoney(invoice?.amountBilled)}
            </Typography>
          </Box>
          <Box
            display="flex"
            gap={1.25}
            alignItems="end"
            margin="10px 0px 10px 0px"
          >
            <Typography variant="mdLight" color="#777777">
              Vencimento:
            </Typography>
            <S.Separator sx={{ marginTop: '4px' }} />
            <Typography variant="mdBold" display="flex" alignItems="center">
              {formatDate(invoice?.dueDate as string)}
            </Typography>
          </Box>
          <Box
            display="flex"
            gap={1.25}
            alignItems="end"
            margin="10px 0px 10px 0px"
          >
            <Typography variant="mdLight" color="#777777">
              Data de expiração:
            </Typography>
            <S.Separator sx={{ marginTop: '4px' }} />
            <Typography variant="mdBold" display="flex" alignItems="center">
              {formatDate(invoice?.expirationField as string)}
            </Typography>
          </Box>
          {DiscountPolicyEnum[invoice?.discountPolicy] === 'Sim' &&
          invoice?.discounts.length > 0 ? (
            invoice?.discounts.map((discount, index) => (
              <Box
                display="flex"
                gap={1.25}
                alignItems="end"
                margin="10px 0px 10px 0px"
                key={index}
              >
                <Typography variant="mdLight" color="#777777">
                  Desconto {index + 1}:
                </Typography>
                <S.Separator sx={{ marginTop: '4px' }} />
                <Typography variant="mdBold" display="flex" alignItems="center">
                  <Box key={index}>
                    {discount.policy == 'percentage'
                      ? `${Number(discount.amount)
                          .toFixed(2)
                          .replace('.', ',')}%`
                      : formatMoney(Number(discount.amount))}{' '}
                    até{' '}
                    {moment(stringToDate(invoice?.dueDate as string))
                      .subtract(discount.days, 'days')
                      .format('DD/MM/YYYY')}
                  </Box>
                </Typography>
              </Box>
            ))
          ) : (
            <Box
              display="flex"
              gap={1.25}
              alignItems="end"
              margin="10px 0px 10px 0px"
            >
              <Typography variant="mdLight" color="#777777">
                Desconto:
              </Typography>
              <S.Separator sx={{ marginTop: '4px' }} />
              <Typography variant="mdBold">Não</Typography>
            </Box>
          )}
          <ChargeFineAndInterest
            interestFee={invoice?.interestFee}
            interestFine={invoice?.interestFine}
            interestPolicy={invoice?.interestPolicy}
          />
          {invoice?.recurrenceCycle > 0 && (
            <Box
              display="flex"
              gap={1.25}
              alignItems="end"
              margin="10px 0px 10px 0px"
            >
              <Typography variant="mdLight" color="#777777">
                Ciclos:
              </Typography>
              <S.Separator sx={{ marginTop: '4px' }} />
              <Typography variant="mdBold" display="flex" alignItems="center">
                <Box
                  display="flex"
                  flexWrap="wrap"
                  gap="8px"
                  alignItems="center"
                >
                  {invoice?.recurrenceCycle}
                  <S.ButtonRecurrenceView
                    type="button"
                    onClick={() =>
                      handleOpenDetailRecurrence(invoice?.recurrenceUuid)
                    }
                  >
                    <Icon
                      icon="open"
                      width="21px"
                      height="21px"
                      ml="6px"
                      sx={{ cursor: 'pointer', color: '#CBCBCB' }}
                    />
                  </S.ButtonRecurrenceView>
                </Box>
              </Typography>
            </Box>
          )}

          <Typography variant="mdLight" color="#777777">
            Formas de pagamento:
          </Typography>
          <Box
            display="flex"
            flexWrap="wrap"
            gap="8px"
            margin="10px 0px 10px 0px"
          >
            {paymentType.map(
              (item: any) =>
                item && (
                  <Chip
                    sx={(theme: any) => ({
                      background: theme.palette.neutral[400]
                    })}
                    key={String(item)}
                    label={String(item).toUpperCase()}
                  />
                )
            )}
          </Box>
          {invoice?.description && (
            <Box margin="10px 0px 10px 0px">
              <Typography variant="mdLight" color="#777777">
                Descrição:
              </Typography>
              <Typography
                color={(theme) => theme.palette.neutral[200]}
                sx={{ whiteSpace: 'pre-line' }}
              >
                {invoice?.description}
              </Typography>
            </Box>
          )}
        </S.ChargeSession>
      </Box>
    </S.Wrapper>
  )
}

export default BillingDetails
