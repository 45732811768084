import React from 'react'

import { FileParams } from 'components/UploadComponent/FileList'

export interface State {
  uploadedFiles: FileParams[]
  description: string
  submited: boolean
}

export interface Props {
  uuid?: string
  disabled?: boolean
  title: string | React.ReactNode
  variant?: 'small' | 'normal'
  subTitle: string
  maxFiles?: number
  submit?: boolean
  description?: boolean
  disabledText?: string
  accept?: any
  type:
    | 'customers'
    | 'billings'
    | 'onboarding'
    | 'contracts'
    | 'announcement_portals'
    | 'products'
    | 'nfse_issuers'
    | 'accounts_payables'
  noSuppressAuto?: boolean
  docType:
    | 'rg'
    | 'cpf'
    | 'cnpj'
    | 'cnh'
    | 'residency_proof'
    | 'activity_proof'
    | 'receipt'
    | 'other'
    | 'documet_whith_photo'
    | 'import'
    | 'contract'
    | 'documents'
    | 'announcement'
    | 'product_image'
    | 'account_payable'
    | 'nfse'
  sentIcon?: boolean
  resetAfterSubmit?: boolean
  refreshTable?: () => void
  disableList?: boolean
  setStateExternal?: React.Dispatch<React.SetStateAction<FileParams[]>>
  onUploadComplete?: () => void
  setError?: React.Dispatch<React.SetStateAction<boolean>>
  error?: boolean
  setLoadingExternal?: React.Dispatch<React.SetStateAction<boolean>>
}
