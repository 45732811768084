import FingerprintJS from '@fingerprintjs/fingerprintjs'
import jwtEncode from 'jwt-encode'

// Get private key from environment variables
const PRIVATE_KEY = process.env.NEXT_PUBLIC_FINGERPRINT_PRIVATE_KEY

if (!PRIVATE_KEY) {
  throw new Error(
    'NEXT_PUBLIC_FINGERPRINT_PRIVATE_KEY environment variable is required'
  )
}

let cachedFingerprint: string | null = null
let cachedToken: string | null = null
let tokenExpiration: number | null = null

export const getFingerprint = async (): Promise<string> => {
  if (cachedFingerprint) {
    return cachedFingerprint
  }

  try {
    const fp = await FingerprintJS.load()
    const result = await fp.get()
    cachedFingerprint = result.visitorId
    return cachedFingerprint
  } catch (error) {
    return ''
  }
}

export const generateFingerprintToken = async (): Promise<string> => {
  try {
    // Check if we have a valid cached token
    if (cachedToken && tokenExpiration && Date.now() < tokenExpiration) {
      return cachedToken
    }

    const fingerprint = await getFingerprint()
    if (!fingerprint) {
      return ''
    }

    // Create a JWT with the fingerprint
    const token = jwtEncode(
      {
        fingerprint,
        exp: Math.floor(Date.now() / 1000) + 24 * 60 * 60 // 24 hours from now
      },
      PRIVATE_KEY,
      'HS256'
    )

    if (!token) {
      return ''
    }

    // Cache the token and its expiration
    cachedToken = token
    tokenExpiration = Date.now() + 24 * 60 * 60 * 1000 // 24 hours from now

    return token
  } catch (error) {
    return ''
  }
}

export const verifyFingerprintToken = (token: string): boolean => {
  try {
    return !!token && token.split('.').length === 3
  } catch (error) {
    return false
  }
}
