/* eslint-disable dot-notation */
import {
  ListItemButton as MUIListItemButton,
  ListItemIcon as MUIListItemIcon,
  ListItemText as MUIListItemText,
  styled
} from '@mui/material'

export const Wrapper = styled('div')(() => ({
  marginBottom: 8
}))

type ButtonProps = {
  active: boolean
  isExpanded?: boolean
}

export const Button = styled('button')<ButtonProps>(
  ({ active, theme, isExpanded }) => ({
    ['&:focus']: {
      borderColor: theme.palette.neutral[100],
      outline: `1px solid ${theme.palette.neutral[100]}`
    },
    ['&:hover']: {
      backgroundColor: theme.palette.neutral['A100'],
      svg: {
        color: theme.palette.primary.main
      }
    },
    all: 'inherit',
    background: active ? theme.palette.neutral['A100'] : 'none',
    border: '1px solid transparent',
    borderRadius: 12,
    boxSizing: 'border-box',
    color: active ? theme.palette.neutral[100] : theme.palette.neutral[400],
    cursor: 'pointer',
    display: 'flex',
    fontWeight: active ? 600 : 400,
    gap: 11,
    lineHeight: 1.5,
    padding: 12,
    position: 'relative',
    svg: {
      color: active ? theme.palette.primary.main : theme.palette.neutral[400],
      transition: 'color 200ms'
    },
    transition: 'background-color 150ms',
    width: isExpanded ? '100%' : 'fit-content',
    ...(!isExpanded
      ? {
          margin: '0 auto'
        }
      : {
          minWidth: 195,
          overflow: 'hidden'
        })
  })
)

export const ListItemButton = styled(MUIListItemButton)<ButtonProps>(
  ({ theme, active, isExpanded }) => ({
    all: 'inherit',
    boxSizing: 'border-box',
    background: active && !isExpanded ? theme.palette.neutral['A100'] : 'none',
    borderRadius: '12px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: 11,
    lineHeight: 1.5,
    padding: '10px 12px',
    position: 'relative',
    svg: {
      color: active ? theme.palette.primary.main : theme.palette.neutral[400],
      transition: 'color 200ms'
    },
    path: {
      color: active ? theme.palette.primary.main : theme.palette.neutral[400],
      transition: 'color 200ms'
    },
    transition: 'background-color 150ms',
    width: isExpanded ? '100%' : 'fit-content',
    ...(!isExpanded
      ? {
          margin: '0 auto'
        }
      : {
          minWidth: 195,
          overflow: 'hidden'
        })
  })
)
export const ListItemIcon = styled(MUIListItemIcon)(() => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: '24px'
}))

export const ListItemText = styled(MUIListItemText)<{
  active: boolean
  highlight?: boolean
}>(({ theme, active, highlight }) => ({
  span: {
    color: highlight
      ? '#fff'
      : active
      ? theme.palette.neutral[100]
      : theme.palette.neutral[400],
    fontWeight: active ? 600 : 400
  }
}))

type ListItemSubHeader = {
  activeSubmenu?: boolean
  highlight?: boolean
}

export const ListItemSubHeader = styled(MUIListItemButton)<ListItemSubHeader>(
  ({ theme, activeSubmenu, highlight }) => ({
    paddingLeft: '48px',
    width: '100%',
    background: highlight
      ? theme.palette.success[300]
      : activeSubmenu
      ? '#fff'
      : 'none',
    borderRadius: '12px',
    color: highlight ? '#fff' : '#000',
    ':hover': {
      background: highlight ? theme.palette.success[300] : ''
    }
  })
)

type NewsProps = {
  activeSubMenu?: boolean
}

export const News = styled('div')<NewsProps>(({ theme, activeSubMenu }) => ({
  position: 'absolute',
  background: theme.palette.primary[400],
  top: activeSubMenu ? '-12px' : '12px',
  right: '2px',
  boxSizing: 'border-box',
  borderRadius: '12px',
  padding: '6px',
  display: 'flex',
  alignItems: 'center'
}))
