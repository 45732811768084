import React from 'react'
import { Controller, FieldValues, Path } from 'react-hook-form'

import {
  Box,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material'

import PaymentsType from 'components/PaymentsType'

import FrequencyControls from '../FrequencyControls'
import { BillingMethodProps } from './BillingMethod'

const BillingMethod = <T extends FieldValues>({
  control,
  payments,
  register,
  watch,
  customerUuid,
  errors,
  edit,
  storedCycleValue,
  setStoredCycleValue,
  isUnique,
  setIsUnique,
  setIsCharge
}: BillingMethodProps<T>) => {
  return (
    <>
      {!edit && (
        <Box
          sx={{
            boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.10)',
            borderRadius: '6px',
            my: 2
          }}
        >
          <Box p={2}>
            <Controller
              name={'totalCycles' as Path<T>}
              control={control}
              render={({ field }) => (
                <RadioGroup
                  aria-labelledby="group-type"
                  value={field.value}
                  onChange={(event) => {
                    field.onChange(event)
                    if ((event.target.value as unknown as number) === 1) {
                      !!setStoredCycleValue &&
                        setStoredCycleValue(
                          event.target.value as unknown as number
                        )
                    }

                    !!setIsUnique &&
                      setIsUnique(
                        (event.target.value as unknown as number) == 1
                          ? true
                          : false
                      )

                    !!setIsCharge &&
                      setIsCharge(
                        (event.target.value as unknown as number) == 1
                          ? true
                          : false
                      )
                  }}
                  row
                  sx={{ gap: 2 }}
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label="Única"
                  />
                  <FormControlLabel
                    value={storedCycleValue == 1 ? 0 : storedCycleValue}
                    control={<Radio />}
                    label="Repetir essa cobrança"
                  />
                </RadioGroup>
              )}
            />
          </Box>
          <Divider />
          {!isUnique && (
            <Box p={2}>
              <FrequencyControls
                watch={watch}
                control={control}
                setStoredCycleValue={setStoredCycleValue}
                storedCycleValue={storedCycleValue}
                isUnique={isUnique}
                setIsUnique={setIsUnique}
              />
            </Box>
          )}
        </Box>
      )}

      <Box mt="16px" mb="4px">
        <PaymentsType
          payments={payments}
          register={register}
          control={control}
          errors={errors}
          customerUuid={customerUuid}
          watch={watch}
        />
      </Box>
    </>
  )
}

export default BillingMethod
