import React, { useEffect, useRef, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import {
  Button,
  Icon,
  Icons,
  Select,
  Textarea,
  Textfield
} from '@clientbase/clientbase-library'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Divider,
  Grid,
  MenuItem,
  Tab,
  Tabs,
  Typography,
  useMediaQuery
} from '@mui/material'
import { DataService } from 'api/DataService'
import { useRouter } from 'next/router'
import { DiscountField } from 'templates/Charge/Modals/EditRecurrence/templates/Discounts.interface'
import {
  chargeFrequencyOptions,
  chargeTypeOptions
} from 'templates/Customers/Modals/BodyModalNewChargeOrRecurrence/Components/FrequencyControls'
import OptionsAdditionals from 'templates/Customers/Modals/BodyModalNewChargeOrRecurrence/Components/OptionsAdditionals'
import PopoverNewVariant from 'templates/Customers/Modals/components/NewVariant'
import { v4 as uuidv4 } from 'uuid'

import InputController from 'components/InputController'
import { enumNature } from 'components/Modal/ModalInvoice/ModalInvoice.utils'
import PaymentsType from 'components/PaymentsType'
import UploadComponent from 'components/UploadComponent'
import Dialog from 'components/v2/Dialog'

import { currency, formatCents } from 'utils'
import { formatMoneyToRequest, onlyNumber } from 'utils/FormatMoney'
import { toastProps } from 'utils/types/toast-props'

import { theme } from 'styles/theme'

import TypesCharges from './components/TypesCharges/TypesCharges'
import {
  DialogParams,
  DiscountsAttributesSchemaRequest,
  FormPlansProps,
  FormPlansSchemaRequest,
  PaymentsSchemaRequest
} from './FormProducts.interface'
import { FormProductParams, FormProductSchema } from './FormProducts.schema'
import * as S from './FormProducts.styles'
import { icons } from './FormProducts.utils'

const frequencyEnumValues = {
  weekly: 0,
  monthly: 1,
  quarterly: 2,
  semester: 3,
  annual: 4
}

const initialFormValues = {
  interestPolicy: false,
  discountPolicy: false,
  frequency: '1',
  payments: ['pix'],
  interestFee: '1.0',
  interestFine: '2.0',
  chargeType: 0,
  dueType: 'immediate_charge',
  proratedCharge: false,
  totalCycles: 0,
  max_installments: 12,

  max_installments_without_fee: 12
} as Partial<FormProductParams>

const FormProducts: React.FC<FormPlansProps> = ({
  handleRefreshPlans,
  setIsOpenDialog,
  product,
  setValueFromPaymentLinkCreation,
  onSuccessFunction
}) => {
  const { push } = useRouter()
  const match = useMediaQuery('(max-width: 900px)', { noSsr: false })
  const [confirmEdit, setConfirmEdit] = useState<DialogParams>({
    open: false,
    dataForm: {} as FormProductParams
  })
  const [confirmNew, setConfirmNew] = useState<DialogParams>({
    open: false,
    dataForm: {} as FormProductParams
  })

  const [dialogNewProduct, setDialogNewProduct] = useState<DialogParams>({
    open: false,
    dataForm: {} as FormProductParams
  })

  const [addFieldDiscount, setAddFieldDiscount] = useState<DiscountField[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const uploadRef = useRef<UploadComponent>({} as UploadComponent)

  const [createdProductUuid, setCreatedProductUuid] = useState<string>('')

  // Controller Form
  const {
    control,
    watch,
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<FormProductParams>({
    defaultValues: {
      ...initialFormValues,

      nfse_issuer_uuid: product?.nfseIssuerUuid || '',
      service_list_code:
        product?.nfseScheduled?.nfse_issuer_service?.uuid || '',
      iss_retention: 'yes',
      issue_when: product?.nfseScheduled?.issue_when || 'on_billing_issued',
      nature_operation:
        enumNature[product?.nfseScheduled?.nature_operation || '1'],
      amount_type: 'amount_issued'
    },
    resolver: yupResolver(FormProductSchema)
  })
  const { payments, billingDescription } = watch()
  const onSubmit = async (dataForm: FormProductParams) => {
    setLoading(true)
    const id = toast.loading(
      product ? 'Editando plano...' : 'Adicionando plano...'
    )
    const data: FormPlansSchemaRequest = {
      ...(uploadRef.current?.state.uploadedFiles &&
        uploadRef.current?.state.uploadedFiles.length > 0 && {
          document_uuid: uploadRef.current?.state.uploadedFiles[0].id
        }),
      name: dataForm?.namePlan as string,
      icon:
        uploadRef.current?.state.uploadedFiles.length > 0
          ? undefined
          : (dataForm?.icon as string),
      amount: formatMoneyToRequest(dataForm?.amount?.toString() || '0'),
      ...(dataForm.dueDay && {
        due_day: Number(dataForm?.dueDay)
      }),
      ...(dataForm?.frequency && {
        frequency: Number(dataForm?.frequency)
      }),
      charge_type: dataForm?.chargeType === 0 ? 'single' : 'recurrent',
      ...(dataForm?.dueType && {
        due_type: dataForm?.dueType
      }),
      ...(dataForm?.description && {
        description: dataForm?.description
      }),
      ...(dataForm?.billingDescription && {
        billing_description: dataForm?.billingDescription
      }),
      discount_policy: dataForm.discountPolicy ? 4 : 1,
      interest_policy: dataForm.interestPolicy ? 3 : 1,
      total_cycles: dataForm?.chargeType === 0 ? 1 : dataForm?.totalCycles,
      ...(dataForm.interestPolicy && {
        interest_fee: dataForm.interestFee,
        interest_fine: dataForm.interestFine
      }),
      prorated_charge: dataForm.proratedCharge,
      nfse_policy: !dataForm?.interestInvoice
        ? 'no_nfse'
        : dataForm?.issue_when,
      ...(dataForm?.interestInvoice && {
        nfse_scheduled_attributes: {
          amount_type: dataForm?.amount_type,
          ...(dataForm?.amount_type === 'amount_custom' &&
            dataForm?.amountCustom && {
              amount: currency(dataForm?.amountCustom)
                .replace('.', '')
                .replace(',', '.')
            }),
          description: dataForm?.descriptionInvoice,
          iss_retention: dataForm?.iss_retention === 'yes' ? true : false,
          nfse_issuer_uuid: dataForm?.nfse_issuer_uuid,
          nfse_issuer_service_uuid: dataForm?.service_list_code,
          nature_operation: Number(dataForm?.nature_operation),
          copy_description: dataForm?.copy_description
        }
      }),
      payments: [],
      discounts_attributes: []
    }

    if (!!dataForm?.payments && dataForm?.payments.length > 0) {
      const payments: PaymentsSchemaRequest[] = []
      dataForm.payments.forEach((payment) => {
        let attributes: any = {}
        if (payment === 'credit_card_installments') {
          attributes = {
            payment_type: payment,
            max_installments: dataForm.max_installments,
            max_installments_without_fee: dataForm.max_installments_without_fee
          }
        } else {
          attributes = {
            payment_type: payment
          }
        }

        payments.push(attributes)
      })
      data.payments = payments
    } else {
      delete data.payments
    }

    if (dataForm?.discountPolicy) {
      const discountsAttributes: DiscountsAttributesSchemaRequest[] = []
      addFieldDiscount.forEach(({ amount, days }) => {
        const attributes: DiscountsAttributesSchemaRequest = {
          amount: Number(
            currency((!!amount && amount) || '0')
              .replace('.', '')
              .replace(',', '.')
          ),
          days: days ? Number(days) : 0,
          policy: dataForm.discountPolicy ? 2 : 1
        }
        discountsAttributes.push(attributes)
      })
      data.discounts_attributes = discountsAttributes
    } else {
      delete data.discounts_attributes
    }

    const response = await DataService({
      url: `/v1/products${product ? `/${product.uuid}` : ''}`,
      type: product ? 'PATCH' : 'POST',
      data
    })

    const errorMessage: string =
      response.error && (response.detail_error || response.message)

    toast.update(id, {
      render: response.error ? errorMessage : response.data.message,
      type: response.error ? 'error' : 'success',
      ...toastProps
    })

    if (response?.status === 201 || response?.status === 200) {
      !!handleRefreshPlans && handleRefreshPlans()
      if (product) {
        !!setIsOpenDialog && setIsOpenDialog()
      } else {
        !!setDialogNewProduct && setDialogNewProduct({ open: true, dataForm })
      }
    }

    if (setValueFromPaymentLinkCreation) {
      setValueFromPaymentLinkCreation('productUuid', response.data.records.uuid)
    }

    setCreatedProductUuid(response.data.records.uuid)

    setConfirmEdit({ open: false })
    setConfirmNew({ open: false })
    onSuccessFunction && onSuccessFunction()
    setLoading(false)
  }

  useEffect(() => {
    if (product) {
      const discounts: DiscountField[] = (product?.discounts || [])?.map(
        (discount) => ({
          amount: discount?.amount,
          days: discount?.days.toString(),
          uuid: uuidv4()
        })
      )

      const parseAmount = onlyNumber(formatCents(product?.amount))

      reset({
        amount: parseAmount,
        description: product?.description ? product?.description : '',
        service_list_code:
          product?.nfseScheduled?.nfse_issuer_service?.uuid || '',
        discountPolicy:
          product?.discountPolicy === 'no_discount' ? false : true,
        frequency: frequencyEnumValues[product?.frequency?.toString()],
        icon: product?.icon || 'acUnit',
        issue_when: product?.nfseScheduled?.issue_when || 'on_billing_issued',
        interestPolicy:
          product?.interestPolicy === 'no_interest' ? false : true,
        namePlan: product?.name,

        copy_description: product.nfseScheduled?.copy_description,
        nature_operation: product.nfseScheduled?.nature_operation,
        amount_type: product.nfseScheduled?.amount_type,

        descriptionInvoice: product.nfseScheduled?.description,
        totalCycles: Number(product?.totalCycles) || 0,
        payments:
          product?.paymentType?.length > 0 ? product?.paymentType : ['pix'],
        interestFee: product?.interestFee || '1.0',
        interestFine: product?.interestFine || '2.0',
        chargeType: product?.chargeType === 'recurrent' ? 1 : 0,
        dueDay: product?.dueDay ? product?.dueDay : undefined,
        dueType: product?.dueType,
        proratedCharge: product?.proratedCharge,
        documentUuid: product?.documentFile?.uuid,
        billingDescription: product?.billingDescription,
        iss_retention: product?.nfseScheduled?.iss_retention ? 'yes' : 'no'
      })
      setValue('interestPolicy', product.interestPolicy !== 'no_interest')

      setValue('interestInvoice', product?.nfseScheduled ? true : false)
      setValue(
        'amountCustom',
        product?.nfseScheduled?.amount
          ? currency(product?.nfseScheduled.amount)
          : ''
      )
      setValue(
        'nature_operation',
        enumNature[product?.nfseScheduled?.nature_operation || '1']
      )
      setValue(
        'iss_retention',
        product.nfseScheduled?.iss_retention ? 'yes' : 'no'
      )
      setValue(
        'service_list_code',
        product?.nfseScheduled?.nfse_issuer_service?.uuid || ''
      ),
        setValue('nfse_issuer_uuid', product.nfseIssuerUuid)
      setValue(
        'max_installments',
        product?.productInstallment?.maxInstallments
          ? product?.productInstallment?.maxInstallments
          : 12
      )
      setValue(
        'max_installments_without_fee',
        product?.productInstallment?.maxInstallmentsWithoutFee
          ? product?.productInstallment?.maxInstallmentsWithoutFee
          : 12
      )
      setValue('issue_when', product.nfsePolicy)

      if (product?.documentFile) {
        const data = [
          {
            id: product?.documentFile?.uuid || '',
            name: 'thumbnail',
            readableSize: 0,
            preview: product?.documentFile?.thumbnail_url || '',
            uploaded: true,
            url: product?.documentFile?.thumbnail_url || '',
            error: false,
            progress: 100,
            file: new File([], 'thumbnail')
          }
        ]
        uploadRef.current.setState({ uploadedFiles: data })
      }

      setAddFieldDiscount(discounts)
    }
  }, [product])

  useEffect(() => {
    if (
      uploadRef.current?.state.uploadedFiles &&
      uploadRef.current?.state.uploadedFiles.length > 0
    ) {
      setValue('documentUuid', uploadRef.current?.state.uploadedFiles[0].id)
    } else {
      setValue('documentUuid', undefined)
    }
  }, [uploadRef])

  const handleSubmitForm: SubmitHandler<FormProductParams> = async (
    dataForm
  ) => {
    if (product) {
      setConfirmEdit({ open: true, dataForm })
    } else {
      setConfirmNew({ open: true, dataForm })
    }
  }

  return (
    <S.Wrapper>
      <Box
        mx="auto"
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap={2}
        mb={2}
      >
        <Box bgcolor="#F8F8F8" width="fit-content" p={1} borderRadius={3}>
          <Icon
            icon={product ? 'edit' : 'add'}
            sx={{ color: theme.palette.primary.main, fontSize: 45 }}
          />
        </Box>
        <Typography variant="lgBold">
          {product ? 'EDITAR' : 'NOVO'} PLANO
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12} sm={12} md={12}>
            <InputController name="namePlan" control={control}>
              <Textfield
                label="Qual o nome do plano?*"
                placeholder="Digite aqui o nome do plano"
              />
            </InputController>
          </Grid>
          <Grid item={true} xs={12} sm={12} md={12}>
            <InputController name="description" control={control}>
              <Textarea
                label="Descrição do plano?*"
                placeholder="Digite aqui a descrição do plano"
              />
            </InputController>
          </Grid>
          <Grid item={true} xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="icon"
              render={({
                field: { onChange, value },
                fieldState: { error }
              }) => {
                return (
                  <Select
                    helperText={error?.message}
                    error={!!error?.message}
                    fullWidth={true}
                    name="icon"
                    label="Selecione um ícone para representar o plano"
                    onChange={onChange}
                    value={product ? value || 'add' : value}
                    renderValue={(selected: any) => {
                      return (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px'
                          }}
                        >
                          <Icon icon={selected as Icons} />
                        </Box>
                      )
                    }}
                  >
                    {icons.map((icon) => (
                      <MenuItem
                        key={icon}
                        value={icon}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px'
                        }}
                      >
                        <Icon icon={icon as Icons} />
                      </MenuItem>
                    ))}
                  </Select>
                )
              }}
            />
          </Grid>
          <Grid
            item={true}
            xs={12}
            sm={12}
            md={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Box sx={{ borderTop: '1px solid #E3E6E3', width: '48%' }} />
            <Typography variant="smLight" color="#757675">
              ou
            </Typography>
            <Box sx={{ borderTop: '1px solid #E3E6E3', width: '48%' }} />
          </Grid>
          <Grid item={true} xs={12} sm={12} md={12}>
            <Typography variant="mdLight">
              Selecione uma imagem para representar o plano
            </Typography>
            <UploadComponent
              type="announcement_portals"
              maxFiles={1}
              subTitle="Arraste ou faça upload da sua imagem para anexá-los."
              docType="product_image"
              title="Envie sua imagem"
              ref={uploadRef}
              accept={{ 'image/*': ['.png', '.jpeg'] }}
            />
            <Typography variant="smLight" color={theme.palette.neutral[500]}>
              Formatos aceitos: JPEG, PNG.
            </Typography>
          </Grid>
          <Grid item={true} xs={12} sm={12} md={12}>
            <Box
              sx={{
                borderTop: '1px solid #E3E6E3',
                width: '100%',
                marginTop: '8px',
                marginBottom: '8px'
              }}
            />
          </Grid>
          <Grid item={true} xs={12} sm={12} md={12}>
            <InputController name="amount" control={control}>
              <Textfield
                customStartAdornment={
                  <Typography mr="6px" variant="mdLight">
                    R$
                  </Typography>
                }
                label="Qual o valor a ser cobrado?*"
                mask="money"
                startAdornment={true}
              />
            </InputController>
          </Grid>
          <Grid item={true} xs={12}>
            <Box
              display={'flex'}
              flexWrap={'wrap'}
              sx={{
                border: '1px solid #E3E5E3',
                borderRadius: '5px'
              }}
            >
              <Controller
                name="chargeType"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Tabs
                    value={Number(value)}
                    sx={{ width: '100%' }}
                    onChange={(_, s) => {
                      if (s === 0) {
                        setValue('totalCycles', 1)
                      } else {
                        setValue('totalCycles', 0)
                      }
                      onChange(s)
                    }}
                    textColor="inherit"
                    indicatorColor="primary"
                    aria-label="tabs"
                  >
                    <Tab
                      sx={{
                        minWidth: '50%',
                        whiteSpace: 'normal',
                        flex: 1
                      }}
                      value={0}
                      label={
                        <Box sx={{ textAlign: 'center' }}>
                          <Typography fontWeight={500} fontSize={'0.875rem'}>
                            Cobrança Única
                          </Typography>
                        </Box>
                      }
                    />
                    <Divider orientation="vertical" flexItem />
                    <Tab
                      sx={{
                        minWidth: '50%',
                        whiteSpace: 'normal',
                        flex: 1
                      }}
                      value={1}
                      label={
                        <Box sx={{ textAlign: 'center' }}>
                          <Typography fontWeight={500} fontSize={'0.875rem'}>
                            Cobrança Recorrente
                          </Typography>
                        </Box>
                      }
                    />
                  </Tabs>
                )}
              />
            </Box>

            {watch('chargeType') === 1 ? (
              <Grid>
                <Box p={2}>
                  <Typography variant="mdLight">Cobrar:</Typography>
                  <Grid
                    container={true}
                    rowSpacing={1}
                    columnSpacing={match ? 1 : 2}
                    alignItems="end"
                  >
                    <Grid item={true} xs={12} sm={6} lg={6}>
                      <Controller
                        control={control}
                        name="frequency"
                        render={({
                          field: { value, onChange },
                          fieldState: { error }
                        }) => (
                          <Select
                            helperText={error?.message}
                            error={!!error?.message}
                            fullWidth
                            value={value}
                            displayEmpty
                            onChange={onChange}
                          >
                            {chargeTypeOptions.map((option) => (
                              <MenuItem key={option.label} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </Grid>
                    <Grid
                      item={true}
                      xs={12}
                      sm={0.5}
                      lg={0.5}
                      alignSelf="center"
                      justifySelf="center"
                      textAlign={match ? 'left' : 'center'}
                    >
                      <Typography variant="mdLight">por</Typography>
                    </Grid>
                    <Grid item={true} xs={12} sm={5.5} lg={5.5}>
                      <Controller
                        control={control}
                        name="totalCycles"
                        render={({
                          fieldState: { error },
                          field: { value, onChange }
                        }) => (
                          <Select
                            helperText={error?.message}
                            error={!!error?.message}
                            fullWidth
                            value={value}
                            displayEmpty
                            onChange={onChange}
                            renderValue={(value) =>
                              value == 0 ? 'Tempo indeterminado' : value + 'x'
                            }
                          >
                            {chargeFrequencyOptions.map((option) => (
                              <MenuItem key={option.label} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ) : null}

            <TypesCharges
              tab={watch('chargeType')}
              control={control}
              watch={watch}
              setValue={setValue}
            />
          </Grid>
        </Grid>

        <Box width={'100%'}>
          <PaymentsType
            register={register}
            payments={payments}
            control={control}
            errors={errors}
            watch={watch}
            selectCreditCard={false}
            label="Escolha os meios de pagamento aceitos neste plano*"
          />
        </Box>

        <Box mt="16px">
          <InputController name="billingDescription" control={control}>
            <Textarea
              label="Descrição na cobrança:"
              fullWidth={true}
              maxLength={2000}
              placeholder="Descrição"
            />
          </InputController>
          <PopoverNewVariant
            setDescription={(message) =>
              setValue('billingDescription', message)
            }
            description={billingDescription || ''}
          />
        </Box>

        <Box mb="54px">
          <OptionsAdditionals
            addFieldDiscount={addFieldDiscount as any}
            control={control}
            setAddFieldDiscount={setAddFieldDiscount as any}
            watch={watch}
            setValue={setValue}
            recurrence
            isEditing={!product}
            hideNegativation
          />
        </Box>

        <Button
          variantButton="primaryGreen"
          type="submit"
          fullWidth
          disabled={loading}
          loading={loading}
        >
          {product ? 'ATUALIZAR PLANO' : 'GERAR NOVO PLANO'}
        </Button>
      </form>

      {/* Confirmar Edição */}
      <Dialog
        icon="event"
        title="Editar Plano"
        description="Confirma editar plano? Essa edição só valerá para novas recorrências associadas ao plano."
        setIsOpenDialog={() =>
          setConfirmEdit({ open: false, dataForm: undefined })
        }
        isOpenDialog={confirmEdit.open}
      >
        <Box display="flex" gap="8px">
          <Button
            fullWidth
            onClick={() => setConfirmEdit({ open: false, dataForm: undefined })}
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button
            fullWidth
            onClick={() =>
              onSubmit(confirmEdit?.dataForm || ({} as FormProductParams))
            }
            disabled={loading}
            loading={loading}
          >
            Confirmar
          </Button>
        </Box>
      </Dialog>

      {/* Confirmar Novo */}
      <Dialog
        icon="add"
        title="NOVO PLANO"
        description="Confirma criar novo plano?"
        setIsOpenDialog={() =>
          setConfirmNew({ open: false, dataForm: undefined })
        }
        isOpenDialog={confirmNew.open}
      >
        <Box display="flex" gap="8px">
          <Button
            fullWidth
            onClick={() => setConfirmNew({ open: false, dataForm: undefined })}
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button
            fullWidth
            onClick={() =>
              onSubmit(confirmNew.dataForm || ({} as FormProductParams))
            }
            disabled={loading}
            loading={loading}
          >
            Confirmar
          </Button>
        </Box>
      </Dialog>

      {/* Seu plano foi criado Edição */}
      <Dialog
        icon="checkCircle"
        iconColor={theme.palette.primary.main}
        title="Seu plano foi criado!"
        description="Você quer gerar um link de contratação para esse plano?"
        setIsOpenDialog={() =>
          setDialogNewProduct({ open: false, dataForm: undefined })
        }
        isOpenDialog={dialogNewProduct.open}
      >
        <Box display="flex" gap="8px">
          <Button
            fullWidth
            onClick={() => {
              setDialogNewProduct({ open: false, dataForm: undefined })
              !!setIsOpenDialog && setIsOpenDialog()
            }}
            disabled={loading}
            variantButton="gray"
          >
            AGORA NÃO
          </Button>
          <Button
            fullWidth
            onClick={() => {
              setDialogNewProduct({ open: false, dataForm: undefined })
              !!setIsOpenDialog && setIsOpenDialog()
              push(`/link-contratacao/criar?plano=${createdProductUuid}`)
            }}
            disabled={loading}
            loading={loading}
          >
            CRIAR LINK
          </Button>
        </Box>
      </Dialog>
    </S.Wrapper>
  )
}

export default FormProducts
