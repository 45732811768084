import React from 'react'

import { Icon } from '@clientbase/clientbase-library'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography } from '@mui/material'

import { theme } from 'styles/theme'

import { ButtonComponent } from './Button.interface'
import { Button as CButton } from './Button.styles'

export const Button: React.FC<ButtonComponent> = ({
  size = 'md',
  variant = 'primary',
  children,
  w,
  hasFocus = false,
  background,
  icon,
  iconColor,
  p,
  loading,
  disabled,
  variantTypography = 'mdBold',
  ...rest
}) => {
  const variants = {
    pallete: {
      green: {
        background: background ? background : theme.palette.primary.main,
        borderradius: '8px',
        padding: p ? `${p}` : '10.5px 0'
      },
      primary: {
        background: background ? background : theme.palette.neutral.main,
        borderradius: '8px',
        padding: p ? `${p}` : '12px 16px'
      },
      secondary: {
        background: background
          ? background['background']
          : theme.palette.neutral[900],
        border: `2px solid ${theme.palette.neutral[100]}`,
        borderradius: '8px',
        padding: p ? `${p}` : '12px 16px',
        textTransform: 'none'
      },
      transparent: {
        background: 'transparent',
        borderradius: '8px',
        padding: p ? `${p}` : '12px 16px'
      }
    },
    size: {
      lg: '42px',
      md: '36px'
    },
    typograph: {
      green: theme.palette.neutral[900],
      primary: theme.palette.neutral[800],
      secondary: theme.palette.neutral[100],
      transparent: theme.palette.neutral[100]
    }
  }

  function getVariantPallete(variant: string) {
    return variants.pallete[variant]
  }

  function getVariantColorTypograph(variant: string) {
    return variants.typograph[variant]
  }

  function getVariantSize(size: string) {
    return variants.size[size]
  }

  return (
    <CButton
      {...rest}
      {...getVariantPallete(variant)}
      hasFocus={hasFocus}
      size={getVariantSize(size)}
      w={w}
      disabled={disabled || loading}
    >
      {icon && <Icon icon={icon} width="20px" colorSX={iconColor} />}
      {loading && (
        <FontAwesomeIcon
          icon={faSpinner}
          className="spinner"
          style={{
            width: '16px',
            height: '16px',
            color: variants.typograph[variant],
            marginRight: '8px'
          }}
        />
      )}
      <Typography
        color={getVariantColorTypograph(variant)}
        variant={variantTypography}
      >
        {children}
      </Typography>
    </CButton>
  )
}

export const ButtonRounded: React.FC<ButtonComponent> = ({
  size = 'lg',
  variant = 'primary',
  children,
  w,
  ...rest
}) => {
  const variants = {
    pallete: {
      primary: {
        background: theme.palette.neutral.main,
        borderradius: '50%',
        padding: '12px 16px'
      },
      secondary: {
        background: theme.palette.neutral[900],
        border: `2px solid ${theme.palette.neutral[100]}`,
        borderradius: '50%',
        padding: '12px 16px'
      },
      transparent: {
        background: 'transparent',
        borderradius: '50%',
        padding: '12px 16px'
      }
    },
    size: {
      lg: '42px',
      md: '36px'
    },
    typograph: {
      primary: theme.palette.neutral[800],
      secondary: theme.palette.neutral[100],
      transparent: theme.palette.neutral[100]
    }
  }

  function getVariantPallete(variant: string) {
    return variants.pallete[variant]
  }

  function getVariantColorTypograph(variant: string) {
    return variants.typograph[variant]
  }

  function getVariantSize(size: string) {
    return variants.size[size]
  }

  return (
    <CButton
      {...getVariantPallete(variant)}
      {...getVariantSize(size)}
      {...rest}
      w={w}
    >
      <Typography color={getVariantColorTypograph(variant)} variant="mdBold">
        {children}
      </Typography>
    </CButton>
  )
}
