import { useEffect, useRef, useState } from 'react'
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
  useWatch
} from 'react-hook-form'
import { toast } from 'react-toastify'

import {
  Button,
  Dialog,
  Icon,
  Loader,
  Textarea
} from '@clientbase/clientbase-library'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  FormControlLabel,
  Stack,
  Switch,
  Tooltip,
  Typography
} from '@mui/material'
import { DataService } from 'api/DataService'
import { CustomerParams } from 'models/Customers'
import { DiscountParams } from 'models/Discounts'
import { InvoiceParams } from 'models/Invoice'
import moment from 'moment'
import { createTextTable } from 'templates/Customers/Modals/BodyModalNewChargeOrRecurrence/BodyModalNewChargeOrRecurrence.utils'
import { checkDescriptionVariant } from 'templates/Customers/Modals/BodyModalNewChargeOrRecurrence/BodyModalNewChargeOrRecurrence.utils'
import BillingMethod from 'templates/Customers/Modals/BodyModalNewChargeOrRecurrence/Components/BillingMethod'
import Infos from 'templates/Customers/Modals/BodyModalNewChargeOrRecurrence/Components/Infos'
import OptionsAdditionals from 'templates/Customers/Modals/BodyModalNewChargeOrRecurrence/Components/OptionsAdditionals'
import PopoverNewVariant from 'templates/Customers/Modals/components/NewVariant'
import { v4 as uuidv4 } from 'uuid'

import IdentifyInternal from 'components/IdentifyInternal/IdentifyInternal'
import { IdentifyInternal as IIdentifyInternal } from 'components/IdentifyInternal/IdentifyInternal.interface'

import { currency, formatCents, onlyNumber } from 'utils'
import { priceConverter } from 'utils/FormatMoney'
import { toastProps } from 'utils/types/toast-props'

import { theme } from 'styles/theme'

import { enumNature } from '../ModalInvoice/ModalInvoice.utils'
import { EditChargeSchema, schemaFormEditCharge } from './schema'
import { ErrorLabel } from './styles'
import { DiscountField } from './template/Discounts.interface'
export interface ChargeBodyModalClientProps {
  resetCharge: (dataForm: EditChargeSchema) => void
  onClose: () => void
  handleCloseModal: () => void
  revalidateTable?: () => void
  customer: CustomerParams
  uuid: string
  discounts: DiscountParams[]
  charge: InvoiceParams
}

const initialDialogSettings = {
  isOpen: false,
  description: '',
  title: '',
  formData: {} as EditChargeSchema
}

function EditCharge({
  resetCharge,
  onClose,
  revalidateTable,
  customer,
  uuid,
  discounts,
  charge
}: ChargeBodyModalClientProps) {
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    register,
    setFocus,
    formState: { errors },
    clearErrors,
    setError
  } = useForm<EditChargeSchema>({
    defaultValues: {
      daysToReport: '30',
      discountPolicy: false,
      interestPolicy: false,
      interestFee: '2.0',
      interestFine: '1.0',
      payments: ['pix'],
      max_installments: 12,
      max_installments_without_fee: 12,
      iss_retention: 'no',
      issue_when: 'on_billing_issued',
      nature_operation: '1',
      amount_type: 'amount_paid',
      nfse_issuer_uuid: '',
      service_list_code: '',
      frequency: 1,
      totalCycles: 1,
      totalCyclesCustom: '',
      interestNegativation: false
    },
    resolver: yupResolver(schemaFormEditCharge)
  })
  const { uuid: customerUuid, name } = customer
  const { payments, description } = watch()

  const [dialog, setDialog] = useState(initialDialogSettings)
  const [loading, setLoading] = useState(false)

  const [addFieldDiscount, setAddFieldDiscount] = useState<DiscountField[]>([])

  const [identifyInternal, setIdentifyInternal] = useState<IIdentifyInternal[]>(
    []
  )

  const {
    fields: itemsFields,
    append,
    remove
  } = useFieldArray({
    control,
    name: 'items'
  })

  const [totalValue, setTotalValue] = useState<number>(0)

  const handleOpenDialog = ({
    title,
    description,
    formData
  }: {
    title: string
    description: string
    formData: EditChargeSchema
  }) => {
    setDialog({ title, description, isOpen: true, formData })
  }

  const handleCloseDialog = () => {
    setDialog((s) => ({ ...s, isOpen: false }))
  }

  const handleSubmitForm: SubmitHandler<EditChargeSchema> = async (
    dataForm
  ) => {
    setLoading(true)

    const billing = {
      total_cycles:
        dataForm?.totalCycles === 13
          ? dataForm?.totalCyclesCustom
          : dataForm?.totalCycles,
      description: dataForm.description,
      due_date: moment(dataForm.dueDate).format('YYYY-MM-DD'),
      discount_policy: dataForm.discountPolicy ? 4 : 1,
      interest_policy: dataForm.interestPolicy ? 3 : 1,
      ...(dataForm.interestPolicy && {
        interest_fee: dataForm.interestFee,
        interest_fine: dataForm.interestFine
      }),
      discounts_attributes: [] as {
        amount: number
        days: number
        policy: number
      }[],
      payments: (dataForm.payments ?? []).map((payment) => {
        if (payment !== 'credit_card_installments') {
          return { payment_type: payment }
        }

        return {
          payment_type: payment,
          max_installments: dataForm.max_installments,
          max_installments_without_fee: dataForm.max_installments_without_fee
        }
      }),
      credit_card_uuid: dataForm?.credit_card_uuid?.value,
      nfse_policy: !dataForm?.interestInvoice
        ? 'no_nfse'
        : dataForm?.issue_when,
      ...(dataForm?.interestInvoice && {
        nfse_scheduled_attributes: {
          amount_type: dataForm?.amount_type,
          ...(dataForm?.amount_type === 'amount_custom' &&
            dataForm?.amountCustom && {
              amount: currency(dataForm?.amountCustom)
                .replace('.', '')
                .replace(',', '.')
            }),
          description: dataForm?.descriptionInvoice,
          iss_retention: dataForm?.iss_retention === 'yes' ? true : false,
          nfse_issuer_uuid: dataForm?.nfse_issuer_uuid,
          nfse_issuer_service_uuid: dataForm?.service_list_code,
          nature_operation: Number(dataForm?.nature_operation),
          copy_description: dataForm?.copy_description
        }
      }),
      ...(dataForm?.interestNegativation
        ? {
            negativation: {
              days_to_report: Number(dataForm?.daysToReport)
            }
          }
        : { negativation: null }),
      metadata: {},
      expiration_date: moment(dataForm.dueDate)
        .add(dataForm.expirationField, 'days')
        .format('YYYY-MM-DD'),
      billing_items_attributes: dataForm.items?.map((item) => {
        return {
          amount_billed: priceConverter(item.total),
          quantity: item.quantity,
          amount_unit: priceConverter(item.total / item.quantity),
          ...(item.description
            ? { description: item.description }
            : item.product
            ? { product_uuid: item.product.value }
            : {})
        }
      }),
      amount_billed: priceConverter(totalValue)
    }

    if (dataForm.discountPolicy) {
      addFieldDiscount.forEach(({ amount, date }, index) => {
        const discount = moment(date).format('YYYY-MM-DD')

        const attributes = {
          amount:
            addFieldDiscount[index].type === '0'
              ? Number(amount)
              : Number(
                  currency((!!amount && amount) || '0')
                    .replace('.', '')
                    .replace(',', '.')
                ),
          days: moment(dataForm.dueDate).diff(moment(discount), 'days'),
          policy: addFieldDiscount[index]?.type === '0' ? 3 : 2
        }
        billing.discounts_attributes.push(attributes)
      })
    }

    if (identifyInternal.length > 0) {
      identifyInternal.map(({ name, value }) => {
        billing.metadata = { ...billing.metadata, [name as string]: value }
      })
    }

    const id = toast.loading('Editando a fatura...')

    const response = await DataService({
      data: billing,
      type: 'PATCH',
      url: `/v3/billings/${uuid}`
    })

    const errorMessage =
      response.error && (response.detail_error || response.message)

    toast.update(id, {
      render: response.error ? errorMessage : response.data.message,
      type: response.error ? 'error' : 'success',
      ...toastProps
    })

    if (!response.error) {
      onClose()
      resetCharge(dataForm)
    }

    handleCloseDialog()
    !!revalidateTable && revalidateTable()
    setLoading(false)
  }

  const handleClickToSubmit: SubmitHandler<EditChargeSchema> = async (
    dataForm
  ) => {
    if (!dataForm.payments?.length) return
    const isCorrect = checkDescriptionVariant(description, setError)
    if (!isCorrect) return
    let isValid = true

    if (dataForm.discountPolicy) {
      addFieldDiscount.forEach((current) => {
        if (
          current.errors?.date?.isError ||
          current?.date === undefined ||
          current?.date === null
        ) {
          setFocus('discountLimitDate')
          isValid = false
        }
        if (
          current.errors?.amount?.isError ||
          current?.amount === undefined ||
          current?.amount === ''
        ) {
          setFocus('discountValueField')
          isValid = false
        }
      })

      setAddFieldDiscount((current) => {
        return current.map((obj) => {
          let messageDate = undefined
          let messageAmount = undefined
          if (!obj.errors?.date?.isError)
            if (obj.date === undefined || !moment(obj.date).isValid()) {
              isValid = false
              setFocus('discountLimitDate')
              messageDate = 'Campo obrigatório.'
            }
          if (!obj.errors?.amount?.isError)
            if (obj.amount === undefined || obj.amount === '') {
              isValid = false
              setFocus('discountValueField')
              messageAmount = 'Campo obrigatório.'
            }

          if (messageAmount || messageDate) {
            return {
              ...obj,
              errors: {
                amount: messageAmount
                  ? { message: messageAmount, isError: true }
                  : { ...obj?.errors?.amount },
                date: messageDate
                  ? { message: messageDate, isError: true }
                  : { ...obj?.errors?.date }
              }
            }
          }
          return { ...obj }
        })
      })
    }

    if (identifyInternal.length > 0) {
      identifyInternal.forEach((current, index) => {
        if (
          current.errors?.value?.isError ||
          current?.value === undefined ||
          current?.value === null
        ) {
          setFocus(`identify-value-${index}`)
          isValid = false
        }
        if (
          current.errors?.name?.isError ||
          current?.name === undefined ||
          current?.name === ''
        ) {
          setFocus(`identify-name-${index}`)
          isValid = false
        }
      })

      setIdentifyInternal((current) => {
        return current.map((obj, index) => {
          let messageName = undefined
          let messageValue = undefined
          if (!obj.errors?.value?.isError) {
            if (obj.value === undefined || obj.value === '') {
              setFocus(`identify-value-${index}`)
              messageValue = 'Campo obrigatório.'
            }
          }
          if (!obj.errors?.name?.isError) {
            if (obj.name === undefined || obj.name === '') {
              setFocus(`identify-name-${index}`)
              messageName = 'Campo obrigatório.'
            }
          }

          if (messageName || messageValue) {
            return {
              ...obj,
              errors: {
                name: messageName
                  ? { message: messageName, isError: true }
                  : { ...obj?.errors?.name },
                value: messageValue
                  ? { message: messageValue, isError: true }
                  : { ...obj?.errors?.value }
              }
            }
          }
          return { ...obj }
        })
      })
    }

    if (isValid) {
      handleOpenDialog({
        title: 'Editar Cobrança',
        description: 'Confirma editar a cobrança deste cliente?',
        formData: dataForm
      })
    }
  }

  useEffect(() => {
    if (discounts.length > 0) {
      const array: DiscountField[] = []
      discounts.forEach(({ amount, days, policy }) => {
        const dueDate = new Date(charge?.dueDate)

        dueDate.setDate(dueDate.getDate() + 1)

        dueDate.setDate(dueDate.getDate() - Number(days))

        let amountToSave

        if (policy == 'percentage') {
          amountToSave = amount
        } else {
          amountToSave = formatCents(amount) || 0
        }

        array.push({
          amount: amountToSave.toString(),
          date: dueDate,
          uuid: uuidv4(),
          type: policy == 'percentage' ? '0' : '1'
        })
      })
      setAddFieldDiscount(array)
    } else {
      setAddFieldDiscount([
        {
          date: undefined,
          amount: undefined,
          errors: undefined,
          uuid: uuidv4()
        }
      ])
    }

    if (charge?.metadata) {
      const metadata = [] as IIdentifyInternal[]

      Object.keys(charge?.metadata).map((key) => {
        metadata.push({
          name: key,
          value: charge?.metadata[key],
          uuid: uuidv4()
        })
      })
      setIdentifyInternal(metadata)
    }
  }, [])

  const getCharge = async () => {
    const parseAmount = onlyNumber(formatCents(charge.amountBilled)) || '0'
    const dueDate = moment(charge.dueDate) as unknown as string

    const parceledCreditCard = charge?.payments.find(
      (payment) => payment.paymentType === 'credit_card_installments'
    )

    if (parceledCreditCard) {
      setValue(
        'max_installments',
        parceledCreditCard?.paymentable.max_installments ?? 12
      )
      setValue(
        'max_installments_without_fee',
        parceledCreditCard?.paymentable.max_installments_without_fee ?? 12
      )
    }
    setValue(
      'expirationField',
      moment(charge.expirationField).diff(charge.dueDate, 'day').toString()
    )
    setValue('amountBilled', parseAmount)

    setValue(
      'hasItemsOnDescription',
      /Itens da (recorrência|cobrança)\n\n(.*\n)*Total: R\$ \d+\.\d{2} \n/.test(
        charge.description || ''
      )
    )

    setValue('description', charge.description ? charge.description : '')
    setValue('discountPolicy', charge?.discounts.length > 0)
    setValue('interestPolicy', charge.interestPolicy !== 'no_interest')
    setValue(
      'interestNegativation',
      charge?.negativation?.days_to_report !== null
    )
    setValue('dueDate', new Date(dueDate))
    setValue('payments', charge.paymentType.split(';').filter(Boolean))
    setValue('credit_card_uuid.label', '')
    setValue('credit_card_uuid.value', '')
    setValue('interestFee', charge.interestFee || '2.0')
    setValue('interestFine', charge.interestFine || '1.0')
    setValue(
      'daysToReport',
      charge?.negativation?.days_to_report?.toString() || '30'
    )
    setValue('interestInvoice', charge?.nfsesScheduled ? true : false)
    if (charge?.nfsesScheduled) {
      const {
        amount,
        amount_type,
        description,
        iss_retention,
        nature_operation,
        nfse_issuer,
        nfse_issuer_service,
        copy_description
      } = charge.nfsesScheduled
      setValue('iss_retention', iss_retention ? 'yes' : 'no')
      setValue('service_list_code', nfse_issuer_service?.uuid)
      setValue('amountCustom', amount ? amount.toString() : undefined)
      setValue('descriptionInvoice', description)
      setValue('nfse_issuer_uuid', nfse_issuer?.uuid)
      setValue('issue_when', charge?.nfsePolicy)
      setValue('amount_type', amount_type)
      setValue(
        'nature_operation',
        enumNature[nature_operation as keyof typeof enumNature]
      )
      setValue('copy_description', copy_description)
    }
    setValue(
      'items',
      charge.items.map((item) => ({
        uuid: item.uuid,
        description: item.description || undefined,
        price: item.amount_unit,
        product: item.product
          ? {
              label: item.product?.name,
              value: item.product?.uuid
            }
          : null,
        quantity: item.quantity,
        total: item.amount_billed,
        typedValue: ''
      }))
    )
  }

  useEffect(() => {
    getCharge()
  }, [uuid])

  const itemsWatch = watch(
    itemsFields
      .map((_, index) => [
        `items.${index}.price` as const,
        `items.${index}.quantity` as const,
        `items.${index}.description` as const,
        `items.${index}.product` as const
      ])
      .flat()
  )

  const clearedErrorsRef = useRef({})

  useEffect(() => {
    const total = itemsFields.reduce((sum, _, index) => {
      const price = priceConverter(watch(`items.${index}.price`) || 0)
      const quantity = Number(watch(`items.${index}.quantity`)) || 0
      const product = price * quantity

      const description = watch(`items.${index}.description`)
      const productValue = watch(`items.${index}.product.value`)

      if (
        (description || productValue) &&
        !clearedErrorsRef.current[`items.${index}`]
      ) {
        clearErrors(`items.${index}.description`)
        clearErrors(`items.${index}.product`)
        clearErrors(`items.${index}`)
        clearedErrorsRef.current[`items.${index}`] = true
      }

      const currentTotal = watch(`items.${index}.total`)
      if (currentTotal !== product) {
        setValue(`items.${index}.total`, product, { shouldValidate: false })
      }

      return sum + product
    }, 0)

    setTotalValue(total)
  }, [itemsWatch, itemsFields, watch, clearErrors, setValue, setTotalValue])

  const hasItemsOnDescription = useWatch({
    control,
    name: 'hasItemsOnDescription'
  })

  const [firstRender, setFirstRender] = useState(true)

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false)
      return
    }

    if (hasItemsOnDescription) {
      const items = watch('items') || []

      const formattedText = createTextTable(items as any, totalValue, false)
      const currentDescription = watch('description') || ''

      if (!formattedText) {
        return
      }

      const newDescription = currentDescription
        ? currentDescription.concat('\n').concat(formattedText)
        : formattedText

      if (newDescription !== currentDescription) {
        setValue('description', newDescription)
      }
    } else {
      const currentDescription = watch('description') || ''
      const tableTextPattern =
        /Itens da (recorrência|cobrança)\n\n(.*\n)*Total: R\$ \d+\.\d{2} \n/

      const newDescription = currentDescription
        .replace(tableTextPattern, '')
        .trim()
      setValue('description', newDescription)
    }
  }, [hasItemsOnDescription])

  if (loading) {
    return <Loader />
  }

  return (
    <Box>
      <Box display="flex" flexDirection="column" gap="18px">
        <Infos
          control={control}
          dueDate={charge.dueDate}
          expirationField={charge.expirationField}
          edit={true}
          nameCustomer={name as string}
          watch={watch}
          itemsFields={itemsFields as any}
          appendItem={append as any}
          removeItem={remove}
          totalValue={totalValue}
          setValue={setValue}
          isCharge
          errors={errors}
        />

        <BillingMethod
          control={control}
          payments={payments}
          register={register}
          watch={watch}
          customerUuid={customerUuid}
          errors={errors}
          edit={true}
        />

        <Box>
          <Controller
            control={control}
            name="description"
            render={({ field: { onChange, value }, fieldState }) => (
              <div>
                <Textarea
                  fullWidth={true}
                  onChange={onChange}
                  placeholder="Descrição"
                  value={value}
                  maxLength={2000}
                  name="description"
                  label={
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box display="flex" gap="5px">
                        Descreva sua cobrança:
                        <Tooltip
                          arrow
                          enterTouchDelay={0}
                          title="Este campo é destinado para adicionar informações complementares, exemplo: nome de outro responsável, nome do dependente, código de identificação, etc."
                        >
                          <Box position="relative">
                            <Icon icon="info" />
                          </Box>
                        </Tooltip>
                      </Box>

                      <Typography
                        variant="smLight"
                        color={theme.palette.neutral[400]}
                      >
                        Caracteres: {description?.length}/2000
                      </Typography>
                    </Stack>
                  }
                />
                {fieldState.error?.message && (
                  <ErrorLabel>{fieldState.error?.message}</ErrorLabel>
                )}
              </div>
            )}
          />

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Tooltip
              title={
                !itemsFields?.length || totalValue === 0
                  ? 'Adicione pelo menos um item com valor para incluir na descrição'
                  : ''
              }
              arrow
            >
              <Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={watch('hasItemsOnDescription')}
                      disabled={!itemsFields?.length || totalValue === 0}
                      onChange={(e) => {
                        const newValue = e.target.checked
                        setValue('hasItemsOnDescription', newValue)
                      }}
                    />
                  }
                  label={
                    <Typography
                      fontSize="15px"
                      color={theme.palette.neutral[400]}
                    >
                      Incluir os itens na descrição da fatura
                    </Typography>
                  }
                />
              </Box>
            </Tooltip>

            <PopoverNewVariant
              setDescription={(message) => setValue('description', message)}
              description={description}
              noChars
            />
          </Stack>
        </Box>

        <OptionsAdditionals
          addFieldDiscount={addFieldDiscount as any}
          control={control}
          setAddFieldDiscount={setAddFieldDiscount as any}
          watch={watch}
          setValue={setValue}
        />

        <Box mt="18px" width="100%">
          <IdentifyInternal
            control={control}
            indetifyInternal={identifyInternal}
            setIdentifyInternal={setIdentifyInternal}
          />
        </Box>

        <Button
          buttonSize="lg"
          type="submit"
          hasFocus
          onClick={handleSubmit(handleClickToSubmit)}
          bgColor={theme.palette.primary.main}
        >
          EDITAR
        </Button>
      </Box>

      <Dialog
        icon="description"
        title={dialog.title}
        description={dialog.description}
        setIsOpenDialog={handleCloseDialog}
        isOpenDialog={dialog.isOpen}
        cancelButton
        cancelButtonLabel="NÃO"
      >
        <Button
          autoFocus
          disabled={loading}
          hasFocus
          onClick={() => handleSubmitForm(dialog.formData)}
          width="100%"
        >
          SIM
        </Button>
      </Dialog>
    </Box>
  )
}

export default EditCharge
