import React, { useContext } from 'react'

import { Icon } from '@clientbase/clientbase-library'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import creditCardChargesMapper, {
  CreditCardCharge
} from 'models/Charge/CreditCardCharges'

import { Table } from 'components/Table'

import { theme } from 'styles/theme'

import { ModalChargeDetailContext } from '../../context'
import { cardTittle } from '../../ModalChargeDetail.utils'

const Attemps: React.FC = () => {
  const { invoice } = useContext(ModalChargeDetailContext)

  if (invoice?.creditCardCharges.length === 0) {
    return null
  }

  return (
    <Accordion
      sx={{
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
        '&:before': {
          display: 'none'
        }
      }}
    >
      <AccordionSummary
        expandIcon={<Icon icon="keyboardArrowDown" />}
        aria-controls="payerDetails-content"
        id="payerDetails"
      >
        {cardTittle(
          'Tentativas de cobrança',
          'storefront',
          `${theme.palette.primary.main}`
        )}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          borderTop: '1px solid #E3E6E3'
        }}
      >
        <Table
          header={[
            { id: 'chargedAt', label: 'Data' },
            { id: 'status', label: 'Status' },
            { id: 'description', label: 'Descrição' }
          ]}
          rows={creditCardChargesMapper(
            invoice?.creditCardCharges as CreditCardCharge
          )}
          select={{ useSelect: false }}
          hiddePagination
        />
      </AccordionDetails>
    </Accordion>
  )
}

export default Attemps
