import { GetServerSidePropsContext, PreviewData } from 'next'
import { destroyCookie } from 'nookies'
import { ParsedUrlQuery } from 'querystring'

export const destroySession = (
  ctx?: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>
) => {
  destroyCookie(ctx, 'nextauth.uuid')
  destroyCookie(ctx, 'nextauth.token')
  destroyCookie(ctx, 'nextauth.user')
  destroyCookie(ctx, 'nextauth.email')
  destroyCookie(ctx, 'nextauth.installment_billings')
  destroyCookie(ctx, 'nextauth.status')
  destroyCookie(ctx, 'nextauth.user_logo_url')
  destroyCookie(ctx, 'nextauth.automatic_transfer')
  destroyCookie(ctx, 'nextauth.merchant_user_name')
  destroyCookie(ctx, 'nextauth.pro')
  destroyCookie(ctx, 'nextauth.role')
  destroyCookie(ctx, 'nextauth.subdomain')
  destroyCookie(ctx, 'nextauth.limit_days_for_payment')
  destroyCookie(ctx, 'nextauth.show_nps')
  destroyCookie(ctx, 'nextauth.merchant_user_uuid')
  destroyCookie(ctx, 'nextauth.shortcuts')
  destroyCookie(ctx, 'nextauth.nfse')
  destroyCookie(ctx, 'nextauth.document')
  destroyCookie(ctx, 'nextauth.portal_personalized')
  destroyCookie(ctx, 'intercom-session-ug0eqsuy')
  destroyCookie(ctx, 'intercom-device-id-ug0eqsuy')
  destroyCookie(ctx, 'intercom-id-ug0eqsuy')
  destroyCookie(ctx, 'nextauth.stage')
  destroyCookie(ctx, 'nextauth.free_tier')
  destroyCookie(ctx, 'nextauth.current_plan')
  destroyCookie(ctx, '6fa69047bb57a1a2837f271d59d45914')
}
