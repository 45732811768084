import axios, { AxiosError } from 'axios'
import { GetServerSidePropsContext, PreviewData } from 'next'
import { parseCookies, setCookie } from 'nookies'
import { ParsedUrlQuery } from 'querystring'

import decodeBase64 from 'utils/decode-base64-to-string'
import { destroySession } from 'utils/DestroySession'
import encodeBase64 from 'utils/encode-to-base64'
import { generateFingerprintToken } from 'utils/fingerprint'

import { getAPIClient } from './axios'
import { requestBlocker } from './requestBlocker'

export type DataServiceProps = {
  ctx?: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>
  type?: 'POST' | 'GET' | 'DELETE' | 'PATCH'
  data?: any
  url: string
}

export type Response<T = any> = {
  data: T
  error: boolean | string
  detail_error: string
  message: string
  status: number
}

const getClientIP = async (): Promise<string> => {
  try {
    const response = await axios.get('https://api.ipify.org?format=json')
    return response.data.ip
  } catch (error) {
    return ''
  }
}

const getFingerprintToken = async (): Promise<string> => {
  if (typeof window !== 'undefined') {
    try {
      const token = await generateFingerprintToken()
      return token || ''
    } catch (error) {
      return ''
    }
  }
  return ''
}

export const DataService = async <T = any>({
  ctx = undefined,
  type = 'POST',
  data,
  url
}: DataServiceProps): Promise<Response<T> | any> => {
  if (['POST', 'PATCH'].includes(type)) {
    const requestKey = `${type}:${url}`

    if (requestBlocker.isBlocked(requestKey)) {
      return {
        data: { records: {}, statistics: {} },
        error: true,
        message: 'Por favor, aguarde um instante para tentar novamente.',
        status: 429,
        detail_error: 'Por favor, aguarde um instante para tentar novamente.'
      }
    }

    requestBlocker.block(requestKey, 1000)
  }

  const client = getAPIClient(ctx)
  let ip = ''
  const { ['nextauth.id']: value } = parseCookies(ctx)
  ip = value ? decodeBase64(value) : ''
  if (!ip) {
    ip = await getClientIP()
    const encryptIp = encodeBase64(ip)
    setCookie(undefined, 'nextauth.id', encryptIp, {
      maxAge: 60 * 60 * 24 * 6
    })
  }

  const headers: Record<string, string> = {
    'X-Forwarded-For': ip
  }

  // Generate fingerprint before making the API call
  if (typeof window !== 'undefined') {
    const fingerprintToken = await getFingerprintToken()
    if (fingerprintToken) {
      headers['X-Fingerprint-Token'] = fingerprintToken
    }
  }

  try {
    const response = await client({
      data,
      method: type,
      url,
      headers: headers
    })

    if (response.data?.token && url === '/v2/sign_in') {
      client.defaults.headers.common.Authorization = `Bearer ${response.data.token}`
    }

    return response
  } catch (err) {
    const errors = err as Error | AxiosError
    if (axios.isAxiosError(errors) && errors.response) {
      if (errors.response.status === 401) {
        destroySession(ctx)
        if (!ctx && url !== '/v2/sign_in') {
          window.location.replace('/')
        }
      }

      return {
        data: { records: {}, statistics: {} },
        ...(!!errors.response?.data?.detail_error && {
          detail_error:
            typeof errors.response?.data?.detail_error === 'string'
              ? errors.response?.data?.detail_error
              : errors.response?.data?.detail_error[0]
        }),
        error: errors.response.data?.error,
        message: errors.response.data?.message,
        status: errors.response.status
      }
    }

    return { error: true, message: errors.message }
  }
}
