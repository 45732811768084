import React, { useContext } from 'react'

import { Icon } from '@clientbase/clientbase-library'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography
} from '@mui/material'
import { InvoiceItem } from 'models/Invoice'

import { Table } from 'components/Table'

import { formatMoney } from 'utils'

import { theme } from 'styles/theme'

import { ModalChargeDetailContext } from '../../context'
import { cardTittle } from '../../ModalChargeDetail.utils'

export const compositionDetailMapper = (expenseComposition: InvoiceItem[]) => {
  return expenseComposition?.map((item) => ({
    item: item.product ? (
      <a
        target="_blank"
        href={`/planos/${item.product.uuid}`}
        style={{ all: 'unset' }}
      >
        <Stack direction="row" gap={1}>
          <Typography>{item.product.name}</Typography>
          <Icon icon="open" />
        </Stack>
      </a>
    ) : (
      item.description || '-'
    ),
    amountUnit: formatMoney(item?.amount_unit) || '',
    quantity: item?.quantity || '',
    amountBilled: formatMoney(item?.amount_billed) || ''
  }))
}

const Composition: React.FC = () => {
  const { invoice } = useContext(ModalChargeDetailContext)
  return (
    <Accordion
      defaultExpanded
      sx={{
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
        '&:before': {
          display: 'none'
        }
      }}
    >
      <AccordionSummary
        expandIcon={<Icon icon="keyboardArrowDown" />}
        aria-controls="composition-content"
        id="composition"
      >
        {cardTittle(
          'Composição da cobrança',
          'paid',
          `${theme.palette.primary.main}`
        )}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          borderTop: '1px solid #E3E6E3'
        }}
      >
        <div>
          <Box maxHeight="200px" overflow="auto">
            <Table
              header={[
                { id: 'item', label: 'Plano/Item' },
                { id: 'amountUnit', label: 'Valor' },
                { id: 'quantity', label: 'Quantidade' },
                { id: 'amountBilled', label: 'Total' }
              ]}
              hoverInRow
              rows={compositionDetailMapper(invoice.items)}
              select={{ useSelect: false }}
              hiddePagination
            />
          </Box>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default Composition
