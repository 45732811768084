import { styled } from '@mui/material'

export const Wrapper = styled('div')(() => ({
  borderRadius: 15,
  gap: '10px',

  display: 'grid',
  alignItems: 'center'
}))

export const Link = styled('a')(() => ({
  width: 'fit-content',
  textDecoration: 'none',
  color: '#757675',
  justifySelf: 'end'
}))

export const IconWrapper = styled('div')(() => ({
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%'
}))
