import React from 'react'

import moment from 'moment'

const Term: React.FC = () => {
  return (
    <>
      <b>
        TERMO ADITIVO AO CONTRATO DE PRESTAÇÃO DE SERVIÇOS E GESTÃO DE
        PAGAMENTOS SERVIÇO DE NEGATIVAÇÃO DE DEVEDORES
      </b>{' '}
      <br />
      <br />
      Por este instrumento particular, a empresa Clientbase LTDA, pessoa
      jurídica de direito privado, inscrita no CNPJ sob o nº 46.365.150/0001-08,
      com endereço na Rua Abel Scuissiato, 267, Guaira, Curitiba, PR, 80.630-020
      (CONTRATADA/CLIENTBASE) e a PESSOA JURÍDICA (o “CONTRATANTE”), têm, entre
      si, justo e acordado, o presente “Termo Aditivo ao Contrato de Prestação
      de Serviços e Gestão de Pagamentos”, objetivando alterar e incluir o
      presente acordo no instrumento já pactuado entre as partes e disponível no
      link: (http://www.basedeclientes.com.br/termos-de-servico) em relação ao
      Serviço de Negativação de Devedores. <br />
      <br />
      <ol>
        <li>
          DOS CONSIDERANDOS
          <ol>
            a) Considerando que as PARTES possuem entre si, justo e acertado, o
            Contrato de Prestação de Serviços de Gestão de Pagamentos, que se
            encontra disponibilizado no endereço eletrônico
            www.basedeclientes.com.br, documento devidamente aceito pelo
            CONTRATANTE no ato de seu cadastramento no serviço;
          </ol>
          <ol>
            b) Considerando que o CONTRATANTE deseja contratar um serviço
            adicional, denominado “Serviço de Negativação de Devedores”;
            Resolvem as PARTES estabelecer o presente Termo Aditivo objetivando
            instituir as cláusulas abaixo designadas:
          </ol>
        </li>
        <li>
          DA ACEITAÇÃO E DA VALIDADE DO CONTRATO
          <ol>
            2.1 O presente Termo Aditivo passa a ter validade entre as PARTES a
            partir do momento em que houver a primeira solicitação de
            negativação de devedores pelo CONTRATANTE, regendo, portanto, todas
            as solicitações que vierem a ser solicitadas posteriormente, sendo
            que todos os seus termos e condições devem ser observados para
            eficácia do serviço solicitado.
          </ol>
          <ol>
            2.2 O CONTRATANTE declara ter lido e aceito, sem reservas, todas as
            cláusulas e condições do presente Aditivo, bem como todas as normas
            de segurança referidas neste instrumento.
          </ol>
        </li>
        <li>
          DO OBJETO
          <ol>
            3.1 É objeto do presente contrato a prestação do serviço de
            negativação de devedores, por meio do qual a CONTRATADA
            possibilitará a inserção e posterior exclusão de dívidas vencidas e
            não pagas por devedores ao CONTRATANTE em “banco de dados”
            determinado.
          </ol>
        </li>
        <li>
          DA NEGATIVAÇÃO DE DEVEDORES
          <ol>
            4.1 O serviço ora contratado consiste na realização do procedimento
            de negativação do(s) cliente(s) do CONTRATANTE, de agora em diante
            denominado(s) como “DEVEDOR”, diante do inadimplemento de cobranças
            geradas pelo CONTRATANTE na plataforma da CLIENTBASE.
          </ol>
          <ol>
            4.2 As condições primordiais para utilização do presente serviço
            pelo CONTRATANTE é que o título original que originou a cobrança
            esteja vencido, que seja líquido, certo e exequível.
          </ol>
          <ol>
            4.3 O comando da solicitação de negativação deverá ser feito pelo
            CONTRATANTE via sistema da CLIENTBASE.
          </ol>
          <ol>
            4.4 Para a aprovação da solicitação da inscrição, faz-se necessário
            o preenchimento de alguns pré-requisitos, quais sejam:
            <ol>
              a) o CONTRATANTE deverá ser enquadrado como pessoa jurídica, com
              CNPJ ativo perante a Receita Federal;
            </ol>
            <ol>
              b) o CONTRATANTE deverá estar com o cadastro aprovado no sistema
              da CLIENTBASE e deve, ao menos, ter realizado um saque através da
              plataforma online;
            </ol>
            <ol>
              c) os dados do CONTRATANTE e do devedor (nome, CPF/CNPJ, endereço,
              correio eletrônico, dados da dívida e telefone) devem estar
              completamente preenchidos no sistema da CLIENTBASE;
            </ol>
            <ol>
              d) O plano ou serviço que originou a cobrança deve ter sido
              comprovadamente entregue/prestado.
            </ol>
          </ol>
          <ol>
            4.5 A CLIENTBASE, a seu critério, poderá dispensar a entrega de
            documentos, ou, ainda, solicitar demais documentos e informações que
            entenda cabíveis para a correta prestação dos serviços ora
            contratados, sob pena de não aprovação.
          </ol>
          <ol>
            4.6 A solicitação de negativação de devedores passará por análise da
            CLIENTBASE que sob seus critérios, aprovará, ou não, a operação.
          </ol>
          <ol>
            4.7 Caso a solicitação do CONTRATANTE seja aceita, a empresa
            CONTRATADA encaminhará os dados necessários para a realização do
            procedimento de negativação via empresa parceira o que, desde já, o
            CONTRATANTE concorda.
          </ol>
          <ol>
            4.8 O CONTRATANTE declara ciência e manifesta concordância que o
            serviço de negativação ora contratado, utiliza o “banco de dados” da
            Boa Vista, o que possibilita a inserção e a exclusão de títulos
            inadimplidos no banco de dados de referida empresa.
          </ol>
        </li>
        <li>
          DO LEGÍTIMO MANDATÁRIO
          <ol>
            5.1 O CONTRATANTE, neste ato, constitui e nomeia a CLIENTBASE como
            seu legítimo mandatário, conferindo-lhe os poderes necessários para
            cumprir as obrigações objeto deste contrato, especialmente para que
            o represente junto a empresa fornecedora do banco de dados, bem como
            para que proceda à remessa das informações referentes ao presente
            Contrato.
          </ol>
          <ol>
            5.2 A CONTRATADA, na qualidade de simples mandatária, limitar-se-á a
            encaminhar os dados do cliente declarado inadimplente pelo
            CONTRATANTE aos órgãos de proteção ao crédito, agindo por conta e
            ordem deste, que responderá em qualquer hipótese ou circunstância,
            pelas omissões ou erros, declarações, prazos, valores ou outros
            dados informados.
          </ol>
          <ol>
            5.3 Os eventuais casos de discordância com relação a valores, a
            vencimento de títulos, ou a quaisquer outros dados impressos nas
            cobranças, deverão ser resolvidos entre o CONTRATANTE e o devedor.
          </ol>
        </li>
        <li>
          DAS OBRIGAÇÕES/RESPONSABILIDADES DO CONTRATANTE
          <ol>
            6.1 O CONTRATANTE autoriza a divulgação para terceiro e ora se
            responsabiliza civil e criminalmente pela veracidade e exatidão das
            informações restritivas declaradas à CONTRATADA e que serão enviadas
            e inseridas no rol de inadimplentes, declarando, sob as penas da
            Lei, ter em seu poder a documentação original que comprove a efetiva
            compra/venda/entrega das mercadorias e/ou prestação de serviços que
            originaram a inscrição.
          </ol>
          <ol>
            6.2 O CONTRATANTE se obriga a manter, pelo prazo mínimo de 06 (seis)
            anos, a contar da aprovação de negativação, todos os documentos
            comprobatórios dos títulos ou das dívidas vencidas e não pagas, cuja
            inclusão no rol de inadimplentes tenha solicitado por meio do
            sistema da CLIENTBASE.
          </ol>
          <ol>
            6.3 O CONTRATANTE se obriga a fornecer os documentos adicionais
            comprobatórios das dívidas, sempre que solicitado pela CLIENTBASE,
            no prazo de 24 (vinte e quatro horas), sob pena de não o fazendo,
            ser excluída a inscrição, além da adoção das medidas de segurança e
            penalidades cabíveis. Por outro lado, em sendo solicitada
            informações diretamente pelo banco de dados ao CONTRATANTE, o mesmo
            terá o prazo de 48 (quarenta e oito) horas para atender à
            solicitação, também sob pena de exclusão do banco de dados e demais
            penalidades cabíveis.
            <ol>
              6.3 O CONTRATANTE se obriga a fornecer os documentos adicionais
              comprobatórios das dívidas, sempre que solicitado pela CLIENTBASE,
              no prazo de 24 (vinte e quatro horas), sob pena de não o fazendo,
              ser excluída a inscrição, além da adoção das medidas de segurança
              e penalidades cabíveis. Por outro lado, em sendo solicitada
              informações diretamente pelo banco de dados ao CONTRATANTE, o
              mesmo terá o prazo de 48 (quarenta e oito) horas para atender à
              solicitação, também sob pena de exclusão do banco de dados e
              demais penalidades cabíveis.
            </ol>
          </ol>
          <ol>
            6.4 O CONTRATANTE se obriga a tomar as cautelas necessárias para a
            correta informação de todos os dados dos títulos a serem insertos
            nas empresas de proteção ao crédito, isentando a CLIENTBASE de toda
            e qualquer responsabilidade relativa a eventuais reclamações,
            prejuízos, perdas e danos, lucros cessantes e/ou danos emergentes,
            inclusive perante terceiros, decorrentes de irregularidades,
            omissões dos dados dos títulos ou instruções relativas aos mesmos.
            <ol>
              6.4.1 O CONTRATANTE também se responsabiliza expressamente pela
              existência e veracidade das dívidas informadas à CONTRATADA para a
              inserção no &quot;banco de dados&quot; correspondente e responderá
              de forma integral, cível e criminalmente, por eventuais perdas e
              danos suportados pela CONTRATADA em ações movidas pelos DEVEDORES
              e/ou por quaisquer terceiros, decorrentes da remessa de dados
              errôneos, inexatos ou desatualizados, inclusive quanto ao endereço
              para a remessa da comunicação a que alude o parágrafo 2º do artigo
              43 do Código de Defesa do Consumidor e/ou demora na solicitação de
              exclusão.
            </ol>
            <ol>
              6.4.2 Sem prejuízo da responsabilidade, seja por culpa ou dolo do
              CONTRATANTE, caso a CONTRATADA seja condenada a pagar indenização
              e/ou penalidade administrativa e/ou judicial de forma solidária
              e/ou subsidiária em razão do descumprimento das obrigações
              estabelecidas no contrato e neste anexo, ficará o CONTRATANTE
              obrigado a ressarcir a CONTRATADA regressivamente no próprio
              processo que originar o débito e/ou processo autônomo, no montante
              da condenação efetivamente paga, mais custas, despesas processuais
              e honorários advocatícios entre outros valores despendidos,
              acrescido de correção monetária e juros de 1% (um por cento) ao
              mês pro rata die, mais multa de 20% (vinte por cento), atualizada
              pela variação do IGP-M da FGV contados desde a data do desembolso
              até a data do efetivo pagamento.
            </ol>
            <ol>
              6.4.3 Caso a CONTRATADA tenha fortes indícios quanto ao
              descumprimento do presente Termo Aditivo, bem como quando ocorrer
              suspeita de fraude, má-fé, prejuízo a terceiro e demais
              possibilidades que acarretem riscos quanto à utilização dos
              serviços, a CONTRATADA poderá, independente de comunicação prévia,
              proceder à interrupção dos serviços de negativação.
            </ol>
          </ol>
          <ol>
            6.5 O CONTRATANTE se compromete em solicitar à CLIENTBASE, de
            imediato, a exclusão dos registros cujos titulares, por qualquer
            motivo, não devam figurar na base de dados de inadimplentes, sob
            pena de responsabilizar-se pela demora.
            <ol>
              6.5.1 Caso o CONTRATANTE receba diretamente do devedor o pagamento
              dos títulos constantes nas empresas de proteção ao crédito, este
              deverá proceder à baixa dos referidos títulos no portal da
              CLIENTBASE imediatamente, sob pena de responder criminalmente pela
              ausência de informações, nos termos do art. 73, do Código de
              Defesa do Consumidor.
            </ol>
            <ol>
              6.5.2 A exclusão dos registros promovidos decorrentes do presente
              contrato, nos termos da cláusula “6.5”, é gratuita e obrigatória
              por parte do CONTRATANTE.
            </ol>
            <ol>
              6.5.3 As exclusões das negativações ocorrerão em até 5 (cinco)
              dias úteis após a solicitação do CONTRATANTE.
            </ol>
          </ol>
          <ol>
            6.6 O CONTRATANTE deverá manter compromisso de confidencialidade em
            relação a todas as informações decorrentes da inscrição de devedores
            nos cadastros de inadimplentes, obrigando-se, ainda, a zelar pela
            manutenção do sigilo de todas as informações, as quais são de uso
            confidencial e exclusivo das PARTES.
          </ol>
        </li>
        <li>
          DAS OBRIGAÇÕES/RESPONSABILIDADE DA CONTRATADA (CLIENTBASE)
          <ol>
            7.1 Após a aprovação da solicitação de negativação realizada pelo
            CONTRATANTE, a CLIENTBASE procederá à intermediação do serviço de
            negativação de devedores junto à base de dados de proteção ao
            crédito.
          </ol>
          <ol>
            7.2 A empresa responsável pela negativação enviará,
            obrigatoriamente, correspondência aos devedores cadastrados pelo
            CONTRATANTE, cientificando-os sobre a solicitação de inclusão de
            pendência financeira realizada por conta e ordem do CONTRATANTE. A
            partir da data do envio da correspondência, em não ocorrendo o
            pagamento voluntário da dívida ou exclusão da mesma, a
            disponibilização da inserção no banco de dados da Boa Vista ocorrerá
            em até 20 (vinte) dias úteis.
          </ol>
          <ol>
            7.3 A CLIENTBASE comunicará ao CONTRATANTE, via sistema, quanto à
            efetiva inscrição do devedor na lista de inadimplentes.
          </ol>
          <ol>
            7.4 O CONTRATANTE possui ciência que a CLIENTBASE não se
            responsabiliza pelo adimplemento da cobrança pelo devedor,
            tratando-se de contratação, apenas, de serviço de solicitação de
            negativação.
          </ol>
          <ol>
            7.5 O CONTRATANTE possui ciência de que, em existindo cadastro
            prévio ou relação jurídica anterior entre o CONTRATANTE e o BANCO DE
            DADOS, a solicitação de negativação será automaticamente reprovada
            pela empresa CLIENTBASE, além de que há atividades econômicas que
            não são passíveis de solicitação/concretização de negativação, o que
            ensejará a reprovação da solicitação por parte da empresa
            CLIENTBASE.
          </ol>
        </li>
        <li>
          DO PREÇO
          <ol>
            8.1 Por cada solicitação de negativação aprovada pela CONTRATADA, o
            CONTRATANTE pagará uma tarifa.
          </ol>
          <ol>
            8.2 A tarifa será debitada da conta do CONTRATANTE tão logo seja
            solicitado o serviço, sendo que o CONTRATANTE autoriza expressamente
            a CONTRATADA a debitar a taxa decorrente do referido serviço.
          </ol>
          <ol>
            8.3 O CONTRATANTE possui ciência de que a inserção incorreta de
            dados não é passível de correção, sendo necessária a exclusão e nova
            inserção de solicitação de negativação para corrigir eventuais
            erros, com nova cobrança de nova tarifa.
          </ol>
        </li>
        <li>
          DAS DISPOSIÇÕES GERAIS
          <ol>
            9.1 Permanecem ratificadas e inalteradas as demais cláusulas e
            condições do “Contrato de Prestação de Serviços de Gestão de
            Pagamentos”, indicados no preâmbulo deste instrumento, alterado por
            força do presente Termo Aditivo, desde que com este não conflita.
          </ol>
        </li>
      </ol>
      <br />
      Curitiba, {moment().format('DD MMMM YYYY')}. <br />
      <b>Clientbase LTDA</b> <br /> CNPJ: 46.365.150/0001-08
      <br /> Rua Abel Scuissiato, 267, Guaira, Curitiba, PR, 80.630-020
      <br />
      Site:{' '}
      <a
        href="www.basedeclientes.com.br"
        target="_blank"
        rel="noopener noreferrer"
      >
        www.basedeclientes.com.br
      </a>
    </>
  )
}

export default Term
