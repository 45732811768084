import React, { useContext } from 'react'

import { Icon } from '@clientbase/clientbase-library'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography
} from '@mui/material'
import Link from 'next/link'

import { formatCpfCnpj, formatPhone } from 'utils'

import { theme } from 'styles/theme'

import { ModalChargeDetailContext } from '../../context'
import { cardTittle } from '../../ModalChargeDetail.utils'
import { S } from './'

const PayerDetails: React.FC = () => {
  const { invoice } = useContext(ModalChargeDetailContext)
  return (
    <Accordion
      defaultExpanded
      sx={{
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
        '&:before': {
          display: 'none'
        }
      }}
    >
      <AccordionSummary
        expandIcon={<Icon icon="keyboardArrowDown" />}
        aria-controls="payerDetails-content"
        id="payerDetails"
      >
        {cardTittle(
          'Detalhes do pagador',
          'supervisorAccount',
          `${theme.palette.primary.main}`
        )}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          borderTop: '1px solid #E3E6E3'
        }}
      >
        <div>
          <Box
            display="flex"
            gap={1.25}
            alignItems="end"
            margin="10px 0px 10px 0px"
          >
            <Typography variant="mdLight" color="#777777">
              Nome:
            </Typography>
            <S.Separator sx={{ marginTop: '4px' }} />
            <Typography variant="mdBold" display="flex" alignItems="center">
              <Link href={`/clientes/${invoice?.customerUuid}`} legacyBehavior>
                <Box
                  display="flex"
                  flexWrap="wrap"
                  gap="8px"
                  alignItems="center"
                >
                  <S.Link>{invoice?.customer?.name}</S.Link>
                  <Icon
                    icon="open"
                    width="21px"
                    height="21px"
                    ml="6px"
                    sx={{ cursor: 'pointer', color: '#CBCBCB' }}
                  />
                </Box>
              </Link>
            </Typography>
          </Box>
          <Box
            display="flex"
            gap={1.25}
            alignItems="end"
            margin={'10px 0px 10px 0px'}
          >
            <Typography variant="mdLight" color="#777777">
              CPF/CNPJ:
            </Typography>
            <S.Separator sx={{ marginTop: '4px' }} />
            <Typography variant="mdBold" display="flex" alignItems="center">
              {formatCpfCnpj(invoice?.customer?.document || '')}
            </Typography>
          </Box>
          <Box
            display="flex"
            gap={1.25}
            alignItems="end"
            margin={'10px 0px 10px 0px'}
          >
            <Typography variant="mdLight" color="#777777">
              E-mail:
            </Typography>
            <S.Separator sx={{ marginTop: '4px' }} />
            <Typography variant="mdBold" display="flex" alignItems="center">
              {invoice?.customer?.email}
            </Typography>
          </Box>
          <Box
            display="flex"
            gap={1.25}
            alignItems="end"
            margin={'10px 0px 10px 0px'}
          >
            <Typography variant="mdLight" color="#777777">
              Telefone:
            </Typography>
            <S.Separator sx={{ marginTop: '4px' }} />
            <Typography variant="mdBold" display="flex" alignItems="center">
              {formatPhone(invoice?.customer?.phone || '')}
            </Typography>
          </Box>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default PayerDetails
