import { Dispatch, SetStateAction } from 'react'

import { Dialog } from '@clientbase/clientbase-library'

import ButtonV2 from 'components/v2/Button'

import { theme } from 'styles/theme'
type DialogReportProps = {
  setDialogRedirectReport: Dispatch<SetStateAction<boolean>>
  isOpenDialog: boolean
  loading: boolean
  handleOpenReportCloseDialog: () => void
}
const DialogReport = ({
  setDialogRedirectReport,
  isOpenDialog,
  loading,
  handleOpenReportCloseDialog
}: DialogReportProps) => {
  return (
    <Dialog
      iconColor={theme.palette.primary.main}
      icon="event"
      title={'Processando Relatório'}
      description={
        'Em instantes, seu relatório estará disponível na aba ”Relatórios", no menu superior da sua Base de Clientes.'
      }
      setIsOpenDialog={setDialogRedirectReport}
      isOpenDialog={isOpenDialog}
    >
      <ButtonV2
        variant="green"
        autoFocus
        disabled={loading}
        fullWidth
        onClick={handleOpenReportCloseDialog}
      >
        IR PARA RELATÓRIOS
      </ButtonV2>
    </Dialog>
  )
}

export default DialogReport
