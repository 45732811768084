import { Button, ButtonProps, Icon } from '@clientbase/clientbase-library'
import { Box, Stack, Typography } from '@mui/material'

import { theme } from 'styles/theme'

type UpgradeButtonProps = ButtonProps

const UpgradeButton: React.FC<UpgradeButtonProps> = (props) => {
  return (
    <Button
      {...props}
      sx={{
        backgroundColor: 'transparent',
        border: 'solid 2px',
        borderRadius: '8px',
        borderColor: theme.palette.info[200],
        color: `${theme.palette.info[200]} !important`,
        padding: '20px 14px',
        ':hover': { backgroundColor: 'transparent', padding: '20px 14px' },
        ...props.sx
      }}
    >
      <Stack direction="row" alignItems="center" gap="5px">
        <Box
          sx={{
            borderRadius: '999px',
            backgroundColor: theme.palette.info[200],
            width: '16px',
            height: '16px',
            minWidth: '16px',
            minHeight: '16px',
            display: 'grid',
            placeItems: 'center'
          }}
        >
          <Icon sx={{ color: '#fff', fontSize: '14px' }} icon="arrowUpward" />
        </Box>
        <Typography color={theme.palette.info[200]} fontWeight={600}>
          Upgrade
        </Typography>
      </Stack>
    </Button>
  )
}

export default UpgradeButton
