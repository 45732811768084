import { styled } from '@mui/material'

export const Wrapper = styled('main')(({ theme }) => ({
  background: theme.palette.neutral['A100'],
  display: 'flex',
  height: '100%',
  width: '100%'
}))

type ContentProps = {
  isExpandedInput?: boolean
  appbarIsNotHidded?: boolean
  showActivateAccountButton?: boolean
}

export const Content = styled('div')<ContentProps>(
  ({
    isExpandedInput,
    appbarIsNotHidded,
    theme,
    showActivateAccountButton
  }) => ({
    ...(appbarIsNotHidded && {
      height: 'calc(100vh - 80px)',
      marginTop: 80
    }),
    overflowY: isExpandedInput ? 'hidden' : 'auto',
    width: '100%',
    position: 'relative',
    [theme.breakpoints.down(770)]: {
      ...(appbarIsNotHidded && {
        height: showActivateAccountButton
          ? `calc(100vh - 120px)`
          : `calc(100vh - 80px)`,
        marginTop: showActivateAccountButton ? 110 : 80
      })
    }
  })
)

export const BackgroundBlur = styled('div')(() => ({
  backdropFilter: 'blur(5.5px)',
  backgroundColor: 'rgba(30, 30, 30, 0.27)',
  position: 'fixed',
  zIndex: 999,
  right: '0',
  bottom: '0',
  top: '0',
  overflow: 'hidden',
  height: '100%',
  width: '100%'
}))
