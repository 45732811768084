import React from 'react'

import { theme } from 'styles/theme'
interface SVGProps {
  color?: string
}
const InviteAndEarnClipBoard = ({
  color = theme.palette.primary.main
}: SVGProps) => {
  return (
    <svg
      width="15"
      height="17"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.9974 14.7431C5.71906 14.7431 5.45212 14.6326 5.2553 14.4358C5.05849 14.2389 4.94792 13.972 4.94792 13.6937V4.948H1.27474C1.13557 4.948 1.0021 5.00328 0.903693 5.10169C0.805285 5.2001 0.75 5.33357 0.75 5.47274V16.4923C0.75 16.7706 0.86057 17.0375 1.05739 17.2344C1.2542 17.4312 1.52114 17.5417 1.79948 17.5417H10.1953C10.4737 17.5417 10.7406 17.4312 10.9374 17.2344C11.1342 17.0375 11.2448 16.7706 11.2448 16.4923V14.7431H5.9974Z"
        fill={color}
      />
      <path d="M13.6934 3.89844H16.8418L13.6934 0.75V3.89844Z" fill={color} />
      <path
        d="M12.6445 4.94792V0.75H6.8724C6.73323 0.75 6.59976 0.805285 6.50135 0.903693C6.40294 1.0021 6.34766 1.13557 6.34766 1.27474V12.2943C6.34766 12.5726 6.45823 12.8395 6.65504 13.0364C6.85186 13.2332 7.1188 13.3438 7.39714 13.3438H15.793C16.0713 13.3438 16.3382 13.2332 16.5351 13.0364C16.7319 12.8395 16.8424 12.5726 16.8424 12.2943V4.94792H12.6445Z"
        fill={color}
      />
    </svg>
  )
}

export default InviteAndEarnClipBoard
