import { memo, useCallback, useEffect, useState } from 'react'
//import Carousel from 'react-material-ui-carousel'
import { useIntercom } from 'react-use-intercom'

import { Icons, Loader } from '@clientbase/clientbase-library'
import { faRocket } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, useMediaQuery } from '@mui/material'
import { useAuthContext } from 'context/AuthContext'
import jwt_decode from 'jwt-decode'
import mixpanel from 'mixpanel-browser'
import { Banner } from 'models/Banner'
import { useRouter } from 'next/router'
import { parseCookies } from 'nookies'
import { MerchantProps } from 'pages/_app'

import AppBar from 'components/AppBar'
import { Drawer, Item } from 'components/Drawer'
import ModalPricing from 'components/Modal/ModalPricing'
import ModalSurveyNPS from 'components/Modal/ModalSurveys/ModalSurveyNPS'
import NewsBanner from 'components/NewsBanner'
import TopBarGlobalInfo, { QuotaParams } from 'components/TopBarGlobalInfo'

import decodeBase64 from 'utils/decode-base64-to-string'
import getCurrentMonth from 'utils/get-current-month'
import { initMixpanel } from 'utils/mixpanel'
import { FeatureTabs } from 'utils/tabs'

import { theme } from 'styles/theme'

import { BackgroundBlur, Content, Wrapper } from './Layout.styles'

type TabCategory =
  | 'ASSISTANT'
  | 'COMERCIAL'
  | 'CLIENTS'
  | 'CHARGES'
  | 'OPERATION'

type Feature = keyof typeof FeatureTabs

interface SubheaderItem {
  label: string
  query: Record<string, any>
  tab: string | number
  news?: boolean
}

interface MenuEntryBase {
  icon: Icons | React.ReactNode
  label: string
  router: string
  option?: string
}

interface MenuEntryWithSubheaders extends MenuEntryBase {
  subheaderItems: Partial<Record<Feature, SubheaderItem>>
}

interface MenuItem extends MenuEntryBase {
  subheader?: SubheaderItem[]
}

interface MenuItemConfig {
  entry: MenuEntryWithSubheaders
  subheaders: Feature[]
}

interface RoleMenuConfig {
  menuEntries: (MenuEntryWithSubheaders | MenuItemConfig)[]
}

const MenuEntries: Record<TabCategory, MenuEntryWithSubheaders> = {
  ASSISTANT: {
    icon: 'autoAwesome',
    label: 'Assistente',
    router: '/visao-geral',
    subheaderItems: {}
  },
  COMERCIAL: {
    icon: <FontAwesomeIcon icon={faRocket} />,
    label: 'Comercial',
    router: '/comercial',
    option: 'comercial',
    subheaderItems: {
      PRODUCTS: {
        label: 'Planos',
        query: { tab: FeatureTabs.PRODUCTS },
        tab: FeatureTabs.PRODUCTS
      },
      PAYMENT_LINK: {
        label: 'Link de Contratação',
        query: { tab: FeatureTabs.PAYMENT_LINK },
        tab: FeatureTabs.PAYMENT_LINK
      },
      REGISTRATION_LINK: {
        label: 'Link de Cadastro',
        query: { tab: FeatureTabs.REGISTRATION_LINK },
        tab: FeatureTabs.REGISTRATION_LINK
      },
      CONTRACTS: {
        label: 'Contratos',
        query: { tab: FeatureTabs.CONTRACTS },
        tab: FeatureTabs.CONTRACTS
      }
    }
  },
  CLIENTS: {
    icon: 'faShieldHeart',
    label: 'Relacionamento',
    router: '/clientes',
    option: 'clients',
    subheaderItems: {
      CUSTOMERS: {
        label: 'Clientes',
        query: { tab: FeatureTabs.CUSTOMERS },
        tab: FeatureTabs.CUSTOMERS
      },
      PORTAL: {
        label: 'Portal de Clientes',
        query: { tab: FeatureTabs.PORTAL },
        tab: FeatureTabs.PORTAL
      },
      COMMUNICATION: {
        label: 'Comunicação',
        tab: FeatureTabs.COMMUNICATION,
        query: {
          tab: FeatureTabs.COMMUNICATION
        }
      }
    }
  },
  CHARGES: {
    icon: 'faCreditCard',
    label: 'Faturamento',
    router: '/cobrancas',
    option: 'receives',
    subheaderItems: {
      CHARGES: {
        label: 'Cobranças',
        tab: FeatureTabs.CHARGES,
        query: {
          tab: FeatureTabs.CHARGES,
          due_date_between: getCurrentMonth().join('|')
        }
      },
      RECURRENCES: {
        label: 'Recorrências',
        query: { tab: FeatureTabs.RECURRENCES },
        tab: FeatureTabs.RECURRENCES
      },
      NFS: {
        label: 'Notas Fiscais',
        query: {
          tab: FeatureTabs.NFS,
          created_at_between: getCurrentMonth().join('|')
        },
        tab: FeatureTabs.NFS
      },
      NEGATIVATION: {
        label: 'Negativação',
        query: { tab: FeatureTabs.NEGATIVATION },
        tab: FeatureTabs.NEGATIVATION
      },
      TRANSFERS: {
        label: 'Transferências',
        query: {
          tab: FeatureTabs.TRANSFERS,
          transfer_date_between: getCurrentMonth().join('|')
        },
        tab: FeatureTabs.TRANSFERS
      }
    }
  },
  OPERATION: {
    icon: 'faChartLine',
    label: 'Operação',
    router: '/operacao',
    option: 'portal',
    subheaderItems: {
      ACCOUNTS_PAYABLE: {
        label: 'Contas a pagar',
        query: {
          tab: FeatureTabs.ACCOUNTS_PAYABLE,
          due_date_between: getCurrentMonth().join('|')
        },
        tab: FeatureTabs.ACCOUNTS_PAYABLE,
        news: true
      },
      NOTIFICATIONS: {
        label: 'Notificações',
        query: { tab: FeatureTabs.NOTIFICATIONS },
        tab: FeatureTabs.NOTIFICATIONS
      },
      REPORTS: {
        label: 'Relatórios',
        query: { tab: FeatureTabs.REPORTS },
        tab: FeatureTabs.REPORTS
      }
    }
  }
}

function createMenuItem(
  menuEntry: MenuEntryWithSubheaders,
  subheaderKeys: Feature[] = []
): MenuItem {
  const { subheaderItems, ...menuItemBase } = menuEntry

  if (!subheaderKeys.length) {
    return menuItemBase
  }

  const validSubheaderItems = subheaderKeys
    .filter((key) => subheaderItems[key as Feature] !== undefined)
    .map((key) => subheaderItems[key as Feature] as SubheaderItem)

  return {
    ...menuItemBase,
    subheader: validSubheaderItems
  }
}
export const roleMenuConfigs: Record<string, RoleMenuConfig> = {
  admin: {
    menuEntries: [
      MenuEntries.ASSISTANT,
      {
        entry: MenuEntries.COMERCIAL,
        subheaders: [
          'PRODUCTS',
          'PAYMENT_LINK',
          'REGISTRATION_LINK',
          'CONTRACTS'
        ]
      },
      {
        entry: MenuEntries.CLIENTS,
        subheaders: ['CUSTOMERS', 'PORTAL', 'COMMUNICATION']
      },
      {
        entry: MenuEntries.CHARGES,
        subheaders: [
          'CHARGES',
          'RECURRENCES',
          'TRANSFERS',
          'NFS',
          'NEGATIVATION'
        ]
      },
      {
        entry: MenuEntries.OPERATION,
        subheaders: ['ACCOUNTS_PAYABLE', 'NOTIFICATIONS', 'REPORTS']
      }
    ]
  },
  user: {
    menuEntries: [
      MenuEntries.ASSISTANT,
      {
        entry: MenuEntries.COMERCIAL,
        subheaders: [
          'PRODUCTS',
          'PAYMENT_LINK',
          'REGISTRATION_LINK',
          'CONTRACTS'
        ]
      },
      {
        entry: MenuEntries.CLIENTS,
        subheaders: ['CUSTOMERS', 'PORTAL', 'COMMUNICATION']
      },
      {
        entry: MenuEntries.CHARGES,
        subheaders: [
          'CHARGES',
          'RECURRENCES',
          'TRANSFERS',
          'NFS',
          'NEGATIVATION'
        ]
      },
      {
        entry: MenuEntries.OPERATION,
        subheaders: ['ACCOUNTS_PAYABLE', 'NOTIFICATIONS', 'REPORTS']
      }
    ]
  },
  operator: {
    menuEntries: [
      {
        entry: MenuEntries.COMERCIAL,
        subheaders: ['PAYMENT_LINK', 'REGISTRATION_LINK', 'CONTRACTS']
      },
      {
        entry: MenuEntries.CLIENTS,
        subheaders: ['CUSTOMERS', 'PORTAL', 'COMMUNICATION']
      },
      {
        entry: MenuEntries.CHARGES,
        subheaders: ['NFS']
      },
      {
        entry: MenuEntries.OPERATION,
        subheaders: ['NOTIFICATIONS', 'REPORTS']
      }
    ]
  },
  financial_operator: {
    menuEntries: [
      {
        entry: MenuEntries.COMERCIAL,
        subheaders: [
          'PRODUCTS',
          'PAYMENT_LINK',
          'REGISTRATION_LINK',
          'CONTRACTS'
        ]
      },
      {
        entry: MenuEntries.CLIENTS,
        subheaders: ['CUSTOMERS', 'PORTAL', 'COMMUNICATION']
      },
      {
        entry: MenuEntries.CHARGES,
        subheaders: ['CHARGES', 'RECURRENCES', 'NFS']
      },
      {
        entry: MenuEntries.OPERATION,
        subheaders: ['ACCOUNTS_PAYABLE', 'NOTIFICATIONS', 'REPORTS']
      }
    ]
  },
  guest: {
    menuEntries: [
      {
        entry: MenuEntries.COMERCIAL,
        subheaders: [
          'PRODUCTS',
          'PAYMENT_LINK',
          'REGISTRATION_LINK',
          'CONTRACTS'
        ]
      },
      {
        entry: MenuEntries.CLIENTS,
        subheaders: ['CUSTOMERS', 'PORTAL', 'COMMUNICATION']
      },
      {
        entry: MenuEntries.CHARGES,
        subheaders: ['CHARGES', 'RECURRENCES', 'NFS']
      },
      {
        entry: MenuEntries.OPERATION,
        subheaders: ['ACCOUNTS_PAYABLE']
      }
    ]
  }
}

function generateMenuItems(): Record<string, MenuItem[]> {
  const menuItems: Record<string, MenuItem[]> = {}

  for (const [role, config] of Object.entries(roleMenuConfigs)) {
    menuItems[role] = config.menuEntries.map((item) => {
      if ('entry' in item) {
        return createMenuItem(item.entry, item.subheaders)
      }
      return createMenuItem(item as MenuEntryWithSubheaders)
    })
  }

  menuItems.user = menuItems.admin

  return menuItems
}

export const menuItems = generateMenuItems()

function generateTabs(): Record<
  Exclude<TabCategory, 'ASSISTANT'>,
  { label: string; value: string | number }[]
> {
  const tabs: Record<
    Exclude<TabCategory, 'ASSISTANT'>,
    { label: string; value: string | number }[]
  > = {
    COMERCIAL: [],
    CLIENTS: [],
    CHARGES: [],
    OPERATION: []
  }

  const { ['nextauth.role']: role } = parseCookies()
  const userRole = decodeBase64(role || '')

  const config = roleMenuConfigs[userRole || 'guest']
  if (!config) return tabs

  for (const item of config.menuEntries) {
    if ('entry' in item) {
      let category: Exclude<TabCategory, 'ASSISTANT'> | undefined
      for (const key in MenuEntries) {
        if (MenuEntries[key as TabCategory] === item.entry) {
          category = key as Exclude<TabCategory, 'ASSISTANT'>
          break
        }
      }

      if (
        category &&
        Object.prototype.hasOwnProperty.call(MenuEntries, category)
      ) {
        const menuCategory = MenuEntries[
          category
        ] as (typeof MenuEntries)[keyof typeof MenuEntries]

        tabs[category] = item.subheaders
          .filter(
            (subheader) =>
              menuCategory.subheaderItems[subheader as Feature] !== undefined
          )
          .map((subheader) => {
            const subheaderItem =
              menuCategory.subheaderItems[subheader as Feature]
            return {
              label: subheaderItem?.label || '',
              value: subheaderItem?.tab || ''
            }
          })
      }
    }
  }

  return tabs
}

export const roleTabs = generateTabs()

function generateTabRoutes(): Record<Feature, string> {
  const routes: Record<string, string> = {}
  function queryParamsToString(params: Record<string, any>): string {
    const queryParams = Object.entries(params)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&')
    return queryParams ? `?${queryParams}` : ''
  }
  for (const categoryKey in MenuEntries) {
    const category = MenuEntries[categoryKey as TabCategory]
    const baseRouter = category.router
    for (const [featureKey, subheaderItem] of Object.entries(
      category.subheaderItems
    )) {
      if (subheaderItem.query) {
        const queryParams = { ...subheaderItem.query }
        const queryString = queryParamsToString(queryParams)
        routes[featureKey] = `${baseRouter}${queryString}`
      } else {
        routes[featureKey] = baseRouter
      }
    }
  }
  return routes
}
export const tabRoutes = generateTabRoutes()

export type Components = 'drawer' | 'appbar' | 'footer'

type LayoutProps = {
  children: JSX.Element
}

function Layout({ children }: LayoutProps) {
  const { user, modalPricing, setModalPricing } = useAuthContext()
  const { pathname } = useRouter()

  const { update } = useIntercom()

  const {
    ['nextauth.token']: token,
    ['nextauth.user_logo_url']: imageUrl,
    ['nextauth.user']: name,
    ['nextauth.status']: status,
    ['nextauth.show_nps']: show_nps
  } = parseCookies()
  const [quota, setQuota] = useState<QuotaParams>()
  const [drawerIsOpen, setDrawerIsOpen] = useState(false)
  const [loading, setLoading] = useState(true)

  const [banner, setBanner] = useState<Banner | null>(null)
  const [isBannerOpen, setIsBannerOpen] = useState<boolean>(true)

  const [isExpanded, setIsExpanded] = useState(true)
  const [isExpandedInput, setIsExpandedInput] = useState(false)

  const match = useMediaQuery('(max-width: 770px)', { noSsr: false })

  const handleToggleIsExpanded = () => {
    setIsExpanded(!isExpanded)
    localStorage.setItem('expanded-drawer', String(!isExpanded))
  }

  const expandDrawer = () => {
    setIsExpanded(true)
    localStorage.setItem('expanded-drawer', String(true))
  }

  useEffect(() => {
    if (!match) {
      setIsExpandedInput(false)
    }
  }, [match])
  useEffect(() => {
    initMixpanel()
    const mixpanelTyped = mixpanel as unknown as { __loaded: string }
    const isLoaded = mixpanelTyped.__loaded
    if (user?.uuid && isLoaded) {
      mixpanel?.identify(user?.uuid)
      mixpanel.people.set({
        $email: user.email,
        $name: user.name
      })
    }
  }, [user?.uuid])

  useEffect(() => {
    const isExpanded = localStorage.getItem('expanded-drawer') === 'true'
    setIsExpanded(isExpanded)

    const bannerString = localStorage.getItem('banner')
    const banners = bannerString
      ? (JSON.parse(bannerString) as Banner[] | Banner)
      : null
    setBanner(Array.isArray(banners) ? banners[0] : banners)
  }, [children])

  const getMerchant = useCallback((token: string) => {
    setLoading(true)

    try {
      const merchant = jwt_decode(token) as MerchantProps
      if (!merchant) return

      update({
        name,
        userId: merchant.merchant_user_uuid,
        company: {
          companyId: merchant.merchant_uuid,
          name
        },
        avatar: {
          type: 'avatar',
          imageUrl
        }
      })
    } catch (err) {
      throw new Error('Merchant is not identified')
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    token ? getMerchant(token) : setLoading(false)
  }, [token])

  if (loading) {
    return <Loader />
  }

  const routesHiddenDrawer = [
    '/cadastro/[uuid]',
    '/cadastro/sucesso',
    '/transfers/[uuid]/report',
    '/forgot-password/[id]',
    '/forgot-password',
    '/login',
    '/login/ativar-conta',
    '/registrar'
  ]

  const routesHiddenAppBar = [
    '/cadastro/[uuid]',
    '/cadastro/sucesso',
    '/transfers/[uuid]/report',
    '/forgot-password/[id]',
    '/forgot-password',
    '/login',
    '/login/ativar-conta',
    '/registrar'
  ]

  const MenuItems = (): Item[] => {
    if (!['operator', 'financial_operator'].includes(user?.role || 'guest')) {
      return menuItems[user?.role || 'guest'].map((item) => {
        return item
      })
    }
    return menuItems[user?.role || 'guest']
  }

  /*
  const showCarousel =
    banners != null &&
    ((pathname == '/visao-geral' && banners.length) ||
      (pathname != '/visao-geral' &&
        banners.filter((b) => b.showOnAllPages).length > 0))
  */

  const showBanner =
    banner &&
    isBannerOpen &&
    (pathname === '/visao-geral' || banner.showOnAllPages)

  const showTopBarGlobalInfo =
    quota?.free_tier &&
    pathname !== '/login' &&
    pathname !== '/registrar' &&
    pathname !== '/cadastro' &&
    pathname !== '/transfers' &&
    pathname !== '/registrar'
  return (
    <Wrapper>
      {show_nps == 'true' && pathname === '/visao-geral' && <ModalSurveyNPS />}
      {showTopBarGlobalInfo && <TopBarGlobalInfo quota={quota} />}

      {!routesHiddenDrawer.includes(pathname) && (
        <Drawer
          showTopGlobalInfo={showTopBarGlobalInfo}
          drawerIsOpen={drawerIsOpen}
          menuItems={MenuItems()}
          setDrawerIsOpen={setDrawerIsOpen}
          isExpanded={isExpanded}
          handleToggleIsExpanded={handleToggleIsExpanded}
          expandDrawer={expandDrawer}
        />
      )}
      {!routesHiddenAppBar.includes(pathname) && (
        <AppBar
          setQuota={setQuota}
          freeTier={!!quota?.free_tier}
          showTopGlobalInfo={showTopBarGlobalInfo}
          setDrawerIsOpen={setDrawerIsOpen}
          isExpandedDrawer={isExpanded}
          setIsExpandedMobileMenu={setIsExpandedInput}
          isExpandedMobileMenu={isExpandedInput}
        />
      )}
      <Content
        sx={{
          ...(showTopBarGlobalInfo && {
            pt: '50px',
            [theme.breakpoints.down(770)]: {
              pt: '120px'
            }
          }),
          borderLeft: `1px solid ${theme.palette.neutral['700']}`
        }}
        isExpandedInput={isExpandedInput}
        appbarIsNotHidded={!routesHiddenAppBar.includes(pathname)}
        showActivateAccountButton={status === 'trial' && !quota?.free_tier}
      >
        {/*banners && (
          <Box padding="20px 20px 0 20px">
            {showCarousel && (
              <Carousel sx={{ overflow: 'visible' }} interval={4000}>
                {banners
                  .filter(
                    (b) => pathname === '/visao-geral' || b.showOnAllPages
                  )
                  .map((banner) => (
                    <NewsBanner banner={banner} key={banner.link} />
                  ))}
              </Carousel>
            )}
          </Box>
        )*/}
        {showBanner && !routesHiddenAppBar.includes(pathname) && (
          <Box
            padding="20px 20px 0px 20px"
            bgcolor={pathname === '/visao-geral' ? '#f4f8f7' : '#fff'}
          >
            <NewsBanner banner={banner} setIsBannerOpen={setIsBannerOpen} />
          </Box>
        )}
        {isExpandedInput && <BackgroundBlur role="presentation" />}
        {children}
      </Content>

      {modalPricing?.isOpen && (
        <ModalPricing
          {...modalPricing}
          handleCloseModal={() =>
            setModalPricing((prev) => ({ ...prev, isOpen: false }))
          }
        />
      )}
    </Wrapper>
  )
}

export default memo(Layout)
