import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import {
  AutoCompleteState,
  StateParams,
  Textarea,
  Textfield
} from '@clientbase/clientbase-library'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, Typography } from '@mui/material'
import axios from 'axios'
import { CustomerService } from 'services/CustomerService'

import InputController from 'components/InputController'
import { PhoneInput } from 'components/PhoneInput'

import { Debouncer, onlyNumber, UFOptions } from 'utils'
import { toastProps } from 'utils/types/toast-props'

import { FormNegativationContext } from '../../context'
import { NegativationSteps, StepsEnum } from '../../utils'
import { S } from './'
import { RevisionSchema, RevisionSchemaType } from './Revision.schema'

const Revision: React.FC = () => {
  const {
    step,
    billingSelected,
    fromRef,
    infosCustomer,
    updateBillingSelected,
    updateInfosCustomer,
    updateStep
  } = useContext(FormNegativationContext)

  const [stateError, setStateError] = useState<string | undefined>()
  const [stateValue, setStateValue] = useState<StateParams | null>(null)
  const [firstRender, setFirstRender] = useState(true)

  const {
    control,
    setValue,
    handleSubmit,
    watch,
    formState: { dirtyFields }
  } = useForm<RevisionSchemaType>({
    defaultValues: {
      cep: ''
    },
    resolver: yupResolver(RevisionSchema)
  })

  const { cep } = watch()

  useEffect(() => {
    const addressIndex = infosCustomer?.addresses?.length - 1
    setValue('name', infosCustomer?.name)
    setValue('nickname', infosCustomer?.nickname)
    setValue('email', infosCustomer?.email)
    setValue('document', infosCustomer?.document)
    setValue('phone', infosCustomer?.phone)
    setValue('cep', infosCustomer?.addresses?.[addressIndex]?.postal_code || '')
    setValue('street', infosCustomer?.addresses?.[addressIndex]?.street)
    setValue('number', infosCustomer?.addresses?.[addressIndex]?.number)
    setValue('complement', infosCustomer?.addresses?.[addressIndex]?.complement)
    setValue(
      'neighborhood',
      infosCustomer?.addresses?.[addressIndex]?.neighborhood
    )
    setValue('city', infosCustomer?.addresses?.[addressIndex]?.city)
    setValue('description', billingSelected?.description)
    setStateValue(
      UFOptions.find(
        (cep) => cep.value === infosCustomer?.addresses?.[addressIndex]?.state
      ) || null
    )
  }, [infosCustomer, billingSelected])

  const onSubmit = async (dataForm: RevisionSchemaType) => {
    const addressIndex = infosCustomer?.addresses?.length - 1
    const address = infosCustomer?.addresses?.[addressIndex]
    let validate = false
    updateBillingSelected((prevState: any) => ({
      ...prevState,
      customer: {
        ...prevState?.customer,
        name: dataForm?.name,
        email: dataForm?.email,
        nickname: dataForm?.nickname,
        document: dataForm?.document,
        phone: dataForm?.phone
      },
      description: dataForm?.description
    }))

    updateInfosCustomer((prevState: any) => {
      const addressesIndex = prevState?.addresses?.length - 1
      const addressesLocal = prevState.addresses
      const address = {
        street: dataForm?.street,
        number: dataForm?.number,
        complement: dataForm?.complement,
        neighborhood: dataForm?.neighborhood,
        city: dataForm?.city,
        state: stateValue?.value,
        postal_code: dataForm?.cep
      }
      addressesLocal[addressesIndex] = {
        ...prevState?.addresses?.[addressesIndex],
        ...address
      }

      return {
        ...prevState,
        addresses: addressesLocal,
        name: dataForm?.name,
        email: dataForm?.email,
        nickname: dataForm?.nickname,
        document: dataForm?.document,
        phone: dataForm?.phone
      }
    })

    if (
      Object.keys(dirtyFields).length > 0 ||
      stateValue?.value !== address?.state
    ) {
      const id = toast.loading('Atualizando cliente...')
      const response = await CustomerService.updateCustomer(
        infosCustomer?.uuid,
        {
          name: dataForm?.name,
          email: dataForm?.email,
          nickname: dataForm?.nickname,
          phone: dataForm?.phone,
          document: dataForm?.document,
          addresses_attributes: [
            {
              city: dataForm?.city,
              complement: dataForm?.complement,
              neighborhood: dataForm?.neighborhood,
              number: dataForm?.number,
              postal_code: dataForm?.cep,
              state: stateValue?.value,
              street: dataForm?.street
            }
          ]
        }
      )

      if (!response?.error) {
        validate = true
        toast.update(id, {
          render: response?.message,
          type: 'success',
          ...toastProps
        })
      } else {
        toast.update(id, {
          render: 'Ocorreu um erro ao atualizar o cliente.',
          type: 'error',
          ...toastProps
        })
      }
    } else {
      validate = true
    }

    if (validate) {
      updateStep((prevState) => prevState + 1)
    }
  }

  const getCEP = async (cep: string) => {
    try {
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
      const { localidade, logradouro, bairro, uf } = response.data

      !!localidade && setValue('city', localidade)
      !!bairro && setValue('neighborhood', bairro)
      !!logradouro && setValue('street', logradouro)
      setStateValue(UFOptions.filter((cep) => cep.value === uf)[0])
    } catch (err) {
      return
    }
  }
  const awaitForSubmitCEP = Debouncer(getCEP, 1000)

  useEffect(() => {
    if (onlyNumber(cep).length === 8) {
      awaitForSubmitCEP(onlyNumber(cep))
    }
  }, [cep])

  const getCustomerDetail = async () => {
    updateInfosCustomer(
      await CustomerService.getCustomerDetail(billingSelected?.customer?.uuid)
    )
  }

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false)
      return
    }
    getCustomerDetail()
  }, [billingSelected])

  if (step !== StepsEnum[NegativationSteps.REVISION]) {
    return null
  }

  return (
    <S.Section>
      <S.Header>Confirmação de dados do cliente</S.Header>
      <S.Content>
        <Typography fontSize="12px" color="#757675" textAlign="center">
          Confirme ou preencha as informações do seu cliente. Caso você preencha
          algum dado que estava incompleto, ele será adicionado ao cadastro do
          cliente.
        </Typography>

        <form ref={fromRef as any} onSubmit={handleSubmit(onSubmit)}>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12} sm={12} md={4}>
              <InputController control={control} name="name">
                <Textfield label="Nome" placeholder="Digite o nome aqui" />
              </InputController>
            </Grid>
            <Grid item={true} xs={12} sm={12} md={4}>
              <InputController control={control} name="nickname">
                <Textfield
                  label="Identificador interno"
                  placeholder="Digite o identificador interno aqui"
                />
              </InputController>
            </Grid>
            <Grid item={true} xs={12} sm={12} md={4}>
              <InputController control={control} name="document">
                <Textfield
                  label="CPF/CNPJ"
                  placeholder="Digite o CPF/CNPJ aqui"
                  mask="cpfCnpj"
                />
              </InputController>
            </Grid>
            <Grid item={true} xs={12} sm={12} md={4}>
              <InputController control={control} name="email">
                <Textfield label="E-mail" placeholder="Digite o e-mail aqui" />
              </InputController>
            </Grid>
            <Grid item={true} xs={12} sm={12} md={4}>
              <InputController control={control} name="phone">
                <PhoneInput label="Telefone" />
              </InputController>
            </Grid>
            <Grid item={true} xs={12} sm={12} md={4}>
              <InputController control={control} name="cep">
                <Textfield
                  label="CEP"
                  placeholder="Digite o CEP aqui"
                  mask="cep"
                />
              </InputController>
            </Grid>
            <Grid item={true} xs={12} sm={12} md={4}>
              <InputController control={control} name="street">
                <Textfield
                  label="Endereço"
                  placeholder="Digite o endereço aqui"
                />
              </InputController>
            </Grid>
            <Grid item={true} xs={12} sm={12} md={4}>
              <InputController control={control} name="number">
                <Textfield label="Número" placeholder="Digite o número aqui" />
              </InputController>
            </Grid>
            <Grid item={true} xs={12} sm={12} md={4}>
              <InputController control={control} name="complement">
                <Textfield
                  label="Complemento"
                  placeholder="Digite o complemento aqui"
                />
              </InputController>
            </Grid>
            <Grid item={true} xs={12} sm={12} md={4}>
              <InputController control={control} name="neighborhood">
                <Textfield label="Bairro" placeholder="Digite o bairro aqui" />
              </InputController>
            </Grid>
            <Grid item={true} xs={12} sm={12} md={4}>
              <InputController control={control} name="city">
                <Textfield label="Cidade" placeholder="Digite a cidade aqui" />
              </InputController>
            </Grid>
            <Grid item={true} xs={12} sm={12} md={4}>
              <AutoCompleteState
                error={stateError}
                setStateError={setStateError}
                setStateValue={setStateValue}
                stateValue={stateValue}
              />
            </Grid>
            <Grid item={true} xs={12}>
              <InputController control={control} name="description">
                <Textarea
                  fullWidth
                  label="Descrição"
                  placeholder="Detalhe o plano ou serviço prestado"
                />
              </InputController>
            </Grid>
          </Grid>
        </form>
      </S.Content>
    </S.Section>
  )
}

export default Revision
